<script setup lang="ts">
import { useTemplateRef, ref, computed, onMounted, onUnmounted, type ShallowRef } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter, type RouteLocationNormalizedLoaded } from 'vue-router';

import { Permission } from '@/auth';
import NavbarSubmenu from '@/components/layout/NavbarSubmenu.vue';
import { Feature } from '@/features';
import { GroupRoute } from '@/libs/routing';
import type { Group } from '@/@types/group';

const store = useStore();
const router = useRouter();
const { t } = useI18n({});

const SUBMENU_LEFT_OFFSET = '188px';
const SUBMENU_SCHRINKED_LEFT_OFFSET = '49px';

export interface FormattedSubmenuItem {
  route: Partial<RouteLocationNormalizedLoaded>;
  translation: string;
}

interface SubmenuItem {
  localeKey: string;
  route: GroupRoute;
}

interface GTFSDateRange {
  endDate: string;
  startDate: string;
}

const SubMenusCategories: Map<GroupRoute, SubmenuItem[]> = new Map([
  [
    GroupRoute.PASSENGERS_APP,
    [
      { localeKey: 'submenus.passengerApp', route: GroupRoute.PASSENGERS_APP },
      { localeKey: 'submenus.gtfsRT', route: GroupRoute.GTFS_RT },
      { localeKey: 'submenus.passengersScreen', route: GroupRoute.PASSENGERS_SCREEN },
    ],
  ],
  [
    GroupRoute.MESSAGE_LIST,
    [
      { localeKey: 'submenus.inbox', route: GroupRoute.MESSAGE_INBOX },
      { localeKey: 'submenus.sent', route: GroupRoute.MESSAGE_SENT },
    ],
  ],
  [
    GroupRoute.REPORTS,
    [
      { localeKey: 'metrics.trip-km', route: GroupRoute.REPORTING_TRIP_KM },
      { localeKey: 'metrics.punctuality', route: GroupRoute.REPORTING_PUNCTUALITY },
      { localeKey: 'metrics.travel-time', route: GroupRoute.REPORTING_TRAVEL_TIME },
      { localeKey: 'metrics.trip-tracking', route: GroupRoute.REPORTING_TRIP_TRACKING },
      { localeKey: 'metrics.passenger-counts', route: GroupRoute.REPORTING_PASSENGER_COUNTS },
      { localeKey: 'metrics.connected-devices', route: GroupRoute.REPORTING_CONNECTED_DEVICES },
      { localeKey: 'metrics.passengers-app-sessions', route: GroupRoute.REPORTING_PASSENGERS_APP },
    ],
  ],
  [
    GroupRoute.RESOURCES,
    [
      { localeKey: 'submenus.transportPlan', route: GroupRoute.TRANSPORT_PLAN_LIST },
      { localeKey: 'submenus.users', route: GroupRoute.USER_LIST },
      { localeKey: 'submenus.devices', route: GroupRoute.DEVICE_LIST },
      { localeKey: 'submenus.drivers', route: GroupRoute.DRIVER_LIST },
      { localeKey: 'submenus.vehicles', route: GroupRoute.VEHICLE_LIST },
    ],
  ],
]);

const displayedSubmenu = ref<GroupRoute | null>(null);
const shrinkedNav = ref<boolean>(false);

const activeGroupRoute = computed<String | null>(() => {
  const categoriesList = Array.from(SubMenusCategories.keys());

  let groupCategory: string | null = null;
  categoriesList.some((category: GroupRoute) => {
    if (
      router.currentRoute.value.name &&
      SubMenusCategories.get(category)
        ?.map(el => el.route)
        .includes(router.currentRoute.value.name as GroupRoute)
    ) {
      groupCategory = category;
      return true;
    }
    return false;
  });

  return groupCategory;
});

const imgSrc = computed<string>(() => {
  const imgLink = shrinkedNav.value ? 'pysae-64.png' : 'pysae-logo.svg';
  return new URL(`/src/assets/img/${imgLink}`, import.meta.url).href;
});

const nbUnreadMessages = computed<number>(() => store.getters['messages/nbUnreadMessages']);
const nbActiveUrgencies = computed<number>(
  () =>
    store.getters['urgencies/getAcknowledgedUrgencies'].length +
    store.getters['urgencies/getNewUrgencies'].length,
);
const reportingDateRange = computed<GTFSDateRange>(() => store.state.reportingDateRange);
const group = computed<Group>(() => store.getters.group);

/**
 * Returns an array of formatted items for each submenu category
 */
const submenuItemsByCategory = computed<{ [key: string]: FormattedSubmenuItem[] }>(() => {
  const categoriesList = Array.from(SubMenusCategories.keys());
  return categoriesList.reduce((acc: { [key: string]: FormattedSubmenuItem[] }, category: string) => {
    const items: FormattedSubmenuItem[] = [];
    if (category === GroupRoute.PASSENGERS_APP || category === GroupRoute.RESOURCES) {
      SubMenusCategories.get(category)?.forEach(item => {
        if (
          (item.route !== GroupRoute.GTFS_RT || store.getters.hasPermission(Permission.VIEW_GTFS_RT)) &&
          (item.route !== GroupRoute.PASSENGERS_APP ||
            store.getters.hasPermission(Permission.VIEW_PASSENGERS_APP))
        ) {
          items.push({
            route: getGroupRoute(item.route),
            translation: t(item.localeKey),
          });
        }
      });
    } else if (category === GroupRoute.MESSAGE_LIST) {
      SubMenusCategories.get(category)?.forEach(item => {
        items.push({
          route: getGroupRoute(item.route),
          translation: t(item.localeKey),
        });
      });
    } else if (category === GroupRoute.REPORTS) {
      SubMenusCategories.get(category)?.forEach(item => {
        const baseQuery = {
          startDate: reportingDateRange.value.startDate,
          endDate: reportingDateRange.value.endDate,
        };

        items.push({
          route: getGroupRoute(item.route, { query: baseQuery }),
          translation: t(item.localeKey),
        });
      });
    }
    acc[category] = items;
    return acc;
  }, {});
});

onMounted(() => {
  shrinkedNav.value = localStorage.getItem('settings.op.navLeftClosed') === 'true';
});

onUnmounted(() => {
  if (timeout.value) clearTimeout(timeout.value);
});

function toggleShrink() {
  shrinkedNav.value = !shrinkedNav.value;
  // @ts-ignore
  localStorage.setItem('settings.op.navLeftClosed', shrinkedNav.value);
  store.state.leftNavBarClosed = shrinkedNav.value;
}

function getGroupRoute(name: string, args = {}) {
  return { name, params: { groupId: group.value._id }, ...args };
}

function triggerLinkClick(event: Event) {
  let element = event.currentTarget as HTMLElement;
  const routerLink = element?.querySelector('a');
  if (routerLink) routerLink.click();
}

// #region Submenu
const messageList: Readonly<ShallowRef<HTMLDivElement | null>> = useTemplateRef('message-list');
const reports: Readonly<ShallowRef<HTMLDivElement | null>> = useTemplateRef('reporting');
const passengersApp: Readonly<ShallowRef<HTMLDivElement | null>> = useTemplateRef('passengers-app');
const resources: Readonly<ShallowRef<HTMLDivElement | null>> = useTemplateRef('resources');

const subMenuTopOffset = ref<number>();

function toggleSubmenu(category: GroupRoute | null) {
  displayedSubmenu.value = category || null;
  let element = null;
  switch (category) {
    case GroupRoute.MESSAGE_LIST:
      element = messageList.value;
      break;
    case GroupRoute.REPORTS:
      element = reports.value;
      break;
    case GroupRoute.PASSENGERS_APP:
      element = passengersApp.value;
      break;
    case GroupRoute.RESOURCES:
      element = resources.value;
      break;
    default:
      element = null;
  }
  subMenuTopOffset.value = element?.getBoundingClientRect().top;
}
// #endregion

// #region scroll bar
const isScrolling = ref(false);
const timeout = ref<ReturnType<typeof setTimeout> | null>(null);

function onScroll() {
  toggleSubmenu(null);
  isScrolling.value = true;
  if (timeout.value) clearTimeout(timeout.value);
  timeout.value = setTimeout(() => {
    isScrolling.value = false;
  }, 500);
}
// #endregion
</script>

<template>
  <div class="left-navbar" :class="{ 'left-navbar__shrinked': shrinkedNav }">
    <div>
      <div class="left-navbar__brand">
        <router-link
          v-if="group._id"
          :to="
            store.getters.hasPermission(Permission.VIEW_DASHBOARD)
              ? getGroupRoute(GroupRoute.DASHBOARD)
              : getGroupRoute(GroupRoute.TRIP_LIST)
          "
        >
          <img alt="Brand" :src="imgSrc" height="36" />
        </router-link>
      </div>
      <div class="left-navbar__top-gradient" />
      <div v-if="group._id" class="left-navbar__menu" :class="{ scrolling: isScrolling }" @scroll="onScroll">
        <div v-if="store.getters.hasPermission(Permission.VIEW_DASHBOARD)" class="left-navbar__section">
          <div class="left-navbar__item" @click="triggerLinkClick">
            <router-link
              class="left-navbar__link"
              :to="getGroupRoute(GroupRoute.DASHBOARD)"
              :title="$t('dashboard')"
            >
              <font-awesome-icon icon="fa-chart-pie" class="left-navbar__icon" />
              <span class="left-navbar__label">{{ $t('dashboard') }}</span>
            </router-link>
          </div>
        </div>
        <div class="left-navbar__section">
          <div class="left-navbar__section-title">{{ t('operations') }}</div>
          <div class="left-navbar__item" @click="triggerLinkClick">
            <router-link
              class="left-navbar__link"
              :to="getGroupRoute(GroupRoute.LIVE_MAP)"
              :title="$t('map')"
            >
              <font-awesome-icon icon="fa-map" class="left-navbar__icon" />
              <span class="left-navbar__label">{{ $t('map') }}</span>
            </router-link>
          </div>

          <div class="left-navbar__item" @click="triggerLinkClick">
            <router-link
              class="left-navbar__link"
              :to="getGroupRoute(GroupRoute.TRIP_LIST)"
              :title="$t('trips')"
            >
              <font-awesome-icon icon="fa-calendar-days" class="left-navbar__icon" />
              <span class="left-navbar__label">{{ $t('trips') }}</span>
            </router-link>
          </div>

          <div
            v-if="store.getters.hasPermission(Permission.VIEW_DUTIES)"
            class="left-navbar__item"
            @click="triggerLinkClick"
          >
            <router-link
              class="left-navbar__link duties"
              :to="getGroupRoute(GroupRoute.DUTY_LIST)"
              :title="$t('duties')"
            >
              <i class="icon-steering-wheel left-navbar__icon" />
              <span class="left-navbar__label">{{ $t('duties') }}</span>
            </router-link>
          </div>

          <div
            v-if="store.getters.hasPermission(Permission.VIEW_PLANNING)"
            class="left-navbar__item"
            @click="triggerLinkClick"
          >
            <router-link
              class="left-navbar__link"
              :to="getGroupRoute(GroupRoute.PLANNING)"
              :title="$t('planning')"
            >
              <font-awesome-icon icon="fa-id-card" class="left-navbar__icon" />
              <span class="left-navbar__label">{{ $t('planning') }}</span>
            </router-link>
          </div>

          <div
            v-if="store.getters.hasPermission(Permission.VIEW_GROUP_FEATURES)"
            class="left-navbar__item"
            @click="triggerLinkClick"
          >
            <router-link
              class="left-navbar__link"
              :to="getGroupRoute(GroupRoute.STOP_LIST)"
              :title="$t('stops')"
            >
              <font-awesome-icon icon="fa-sign-hanging" class="left-navbar__icon" />
              <span class="left-navbar__label">{{ $t('stops') }}</span>
            </router-link>
          </div>

          <div
            v-if="store.getters.hasPermission(Permission.VIEW_DEVIATION_LIST)"
            class="left-navbar__item"
            @click="triggerLinkClick"
          >
            <router-link
              class="left-navbar__link"
              :to="getGroupRoute(GroupRoute.DEVIATION_LIST)"
              :title="$t('deviations')"
            >
              <font-awesome-icon icon="fa-solid fa-arrows-split-up-and-left" class="left-navbar__icon" />
              <span class="left-navbar__label">{{ $t('deviations') }}</span>
            </router-link>
          </div>

          <div
            v-if="store.getters.hasPermission(Permission.VIEW_MESSAGES)"
            ref="message-list"
            class="left-navbar__item"
            @mouseover="toggleSubmenu(GroupRoute.MESSAGE_LIST)"
            @mouseleave="toggleSubmenu(null)"
            @click="triggerLinkClick"
          >
            <router-link
              class="left-navbar__link"
              :to="getGroupRoute(GroupRoute.MESSAGE_INBOX)"
              :class="{
                'left-navbar__item--active': GroupRoute.MESSAGE_LIST === activeGroupRoute,
              }"
              :title="$t('messages')"
            >
              <div>
                <font-awesome-icon icon="fa-envelope" class="left-navbar__icon" />
                <span v-if="nbUnreadMessages > 0" class="left-navbar__counter">
                  {{ nbUnreadMessages }}
                </span>
                <span class="left-navbar__label">{{ $t('messages') }}</span>
              </div>
            </router-link>
            <NavbarSubmenu
              v-if="displayedSubmenu === GroupRoute.MESSAGE_LIST"
              :height-offset="subMenuTopOffset"
              :left-offset="shrinkedNav ? SUBMENU_SCHRINKED_LEFT_OFFSET : SUBMENU_LEFT_OFFSET"
              :items="submenuItemsByCategory[GroupRoute.MESSAGE_LIST]"
            />
          </div>

          <div
            v-if="store.getters.hasPermission(Permission.VIEW_URGENCIES)"
            class="left-navbar__item"
            @click="triggerLinkClick"
          >
            <router-link
              class="left-navbar__link"
              :to="getGroupRoute(GroupRoute.URGENCIES)"
              :class="{ 'left-navbar__item--active': GroupRoute.URGENCIES === activeGroupRoute }"
              :title="$t('urgencies')"
            >
              <div>
                <font-awesome-icon icon="fa-exclamation-circle" class="left-navbar__icon" />
                <span
                  v-if="nbActiveUrgencies > 0 && store.getters.hasFeature(Feature.URGENCIES)"
                  class="left-navbar__counter"
                >
                  {{ nbActiveUrgencies }}
                </span>
                <span class="left-navbar__label">{{ $t('urgencies') }}</span>
              </div>
            </router-link>
          </div>

          <div
            v-if="store.getters.hasPermission(Permission.VIEW_REPORT)"
            ref="reporting"
            class="left-navbar__item"
            @mouseover="toggleSubmenu(GroupRoute.REPORTS)"
            @mouseleave="toggleSubmenu(null)"
            @click="triggerLinkClick"
          >
            <router-link
              class="left-navbar__link"
              :to="
                getGroupRoute(GroupRoute.REPORTING_TRIP_KM, {
                  query: {
                    startDate: reportingDateRange.startDate,
                    endDate: reportingDateRange.endDate,
                  },
                })
              "
              :class="{
                'submenu-active': displayedSubmenu === GroupRoute.REPORTS,
                'left-navbar__item--active': activeGroupRoute === GroupRoute.REPORTS,
              }"
              :title="$t('report')"
            >
              <font-awesome-icon icon="fa-chart-line" class="left-navbar__icon" />
              <span class="left-navbar__label">{{ $t('report') }}</span>
            </router-link>

            <NavbarSubmenu
              v-if="displayedSubmenu === GroupRoute.REPORTS"
              :height-offset="subMenuTopOffset"
              :left-offset="shrinkedNav ? SUBMENU_SCHRINKED_LEFT_OFFSET : SUBMENU_LEFT_OFFSET"
              :items="submenuItemsByCategory[GroupRoute.REPORTS]"
            />
          </div>
        </div>

        <div class="left-navbar__section">
          <div class="left-navbar__section-title">{{ $t('passengers') }}</div>

          <div class="left-navbar__item" @click="triggerLinkClick">
            <router-link
              class="left-navbar__link"
              :to="getGroupRoute(GroupRoute.PASSENGERS_MESSAGE)"
              :class="{
                'left-navbar__item--active': activeGroupRoute === GroupRoute.PASSENGERS_MESSAGE,
              }"
              :title="$t('passengersMessages')"
            >
              <font-awesome-icon icon="fa-comment-dots" class="left-navbar__icon" />
              <span class="left-navbar__label">{{ $t('passengersMessages') }}</span>
            </router-link>
          </div>

          <div
            ref="passengers-app"
            class="left-navbar__item"
            @mouseover="toggleSubmenu(GroupRoute.PASSENGERS_APP)"
            @mouseleave="toggleSubmenu(null)"
            @click="triggerLinkClick"
          >
            <router-link
              class="left-navbar__link"
              :to="getGroupRoute(GroupRoute.PASSENGERS_APP)"
              :class="{
                'submenu-active': displayedSubmenu === GroupRoute.PASSENGERS_APP,
                'left-navbar__item--active': activeGroupRoute === GroupRoute.PASSENGERS_APP,
              }"
              :title="$t('passengersInfo')"
            >
              <font-awesome-icon icon="fa-users" class="left-navbar__icon" />
              <span class="left-navbar__label">{{ $t('passengersInfo') }}</span>
            </router-link>
            <NavbarSubmenu
              v-if="displayedSubmenu === GroupRoute.PASSENGERS_APP"
              :height-offset="subMenuTopOffset"
              :left-offset="shrinkedNav ? SUBMENU_SCHRINKED_LEFT_OFFSET : SUBMENU_LEFT_OFFSET"
              :items="submenuItemsByCategory[GroupRoute.PASSENGERS_APP]"
            />
          </div>
        </div>

        <div
          v-if="
            store.getters.hasPermission(Permission.VIEW_INTEGRATIONS) ||
            store.getters.hasPermission(Permission.VIEW_GROUP_FEATURES)
          "
          class="left-navbar__section"
        >
          <div class="left-navbar__section-title">{{ $t('admin') }}</div>

          <div
            v-if="store.getters.hasPermission(Permission.VIEW_GROUP_FEATURES)"
            ref="resources"
            class="left-navbar__item"
            @mouseover="toggleSubmenu(GroupRoute.RESOURCES)"
            @mouseleave="toggleSubmenu(null)"
            @click="triggerLinkClick"
          >
            <router-link
              class="left-navbar__link"
              :to="getGroupRoute(GroupRoute.TRANSPORT_PLAN_LIST)"
              :class="{
                'submenu-active': displayedSubmenu === GroupRoute.RESOURCES,
                'left-navbar__item--active': activeGroupRoute === GroupRoute.RESOURCES,
              }"
              :title="$t('resources')"
            >
              <font-awesome-icon icon="fa-briefcase" class="left-navbar__icon" />
              <span class="left-navbar__label">{{ $t('resources') }}</span>
            </router-link>

            <NavbarSubmenu
              v-if="displayedSubmenu === GroupRoute.RESOURCES"
              :height-offset="subMenuTopOffset"
              :left-offset="shrinkedNav ? SUBMENU_SCHRINKED_LEFT_OFFSET : SUBMENU_LEFT_OFFSET"
              :items="submenuItemsByCategory[GroupRoute.RESOURCES]"
            />
          </div>

          <div
            v-if="store.getters.hasPermission(Permission.VIEW_INTEGRATIONS)"
            class="left-navbar__item"
            @click="triggerLinkClick"
          >
            <router-link :to="getGroupRoute(GroupRoute.INTEGRATIONS)" :title="$t('integrations')">
              <font-awesome-icon icon="fa-link" class="left-navbar__icon" />
              <span class="left-navbar__label">{{ $t('integrations') }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="left-navbar__bottom-container">
      <div class="left-navbar__bottom-gradient" />
      <button class="left-navbar__shrink-btn" @click="toggleShrink" @mousedown.prevent>
        <font-awesome-icon :icon="shrinkedNav ? 'fa-angle-right' : 'fa-angle-left'" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$navbar-left-shrink-btn-height: 36px;
$bottom-gradient-height: 24px;
$scroll-bar-width: 5px;

.left-navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  border-right: 1px solid $border;
  background-color: $background;
  font-size: 12px;
  transition: width 300ms ease-out;

  a:hover {
    text-decoration: none;
  }

  &__bottom-container {
    position: relative;
  }

  &__bottom-gradient {
    position: absolute;
    bottom: $navbar-left-shrink-btn-height;
    z-index: 1;
    width: 180px;
    height: $bottom-gradient-height;
    margin-left: $view-standard-padding;
    background: linear-gradient(rgb(0 0 0 / 0%) 0%, $background 80%, $background 100%);
  }

  &__brand {
    position: relative;
    display: flex;
    justify-content: center;
    height: $navbar-left-header-height;
    padding: 16px 0;
    border-bottom: 1px solid $light-border;

    a {
      height: 36px;
    }
  }

  &__counter {
    position: absolute;
    top: -3px;
    left: 10px;
    min-width: 1.6em;
    padding: 1px 3px;
    border-radius: 10px;
    background-color: $danger;
    color: $text-light;
    font-size: 0.6em;
    text-align: center;
  }

  &__icon {
    width: 18px;
    text-align: center;
  }

  &__item {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: calc(100% - 4px);
    height: 36px;
    border-radius: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 150ms ease-out;
    padding-inline: 6px;

    &:hover {
      background-color: $transparent-dark-variant;
      transition: background-color 150ms ease-out;

      a {
        color: $text-dark;
      }
    }

    &:has(.left-navbar__item--active) {
      background-color: $transparent-primary;
      transition: background-color 150ms ease-out;

      a {
        color: $primary-light;
      }
    }

    a {
      color: $text-dark-variant;
    }
  }

  &__label {
    margin-left: 8px;
    font-weight: $font-weight-semi-bold;
  }

  &__link {
    position: relative;
  }

  &__menu {
    overflow-y: scroll;
    max-height: calc(100vh - $navbar-left-header-height - $navbar-left-shrink-btn-height);
    padding: $view-standard-padding 0 16px $view-standard-padding;
    border-color: transparent;
    transition: border-color 150ms ease-out;

    &::-webkit-scrollbar {
      width: $scroll-bar-width;
    }

    &.scrolling {
      border-color: $text-neutral;

      &::-webkit-scrollbar {
        width: 5px;
        margin-left: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        // Hack to be able to apply transition on scrollbar
        border-color: inherit;
        border-right-width: calc(100vw + 100vh);
        border-right-style: inset;
        border-radius: 10px;
      }
    }
  }

  &__section {
    padding-bottom: 12px;
  }

  &__section-title {
    padding-bottom: 6px;
    color: $text-neutral;
    font-weight: 500;
    padding-inline: 6px;
  }

  &__shrinked {
    width: 61px;
    transition: width 300ms ease-out;

    .left-navbar__label {
      display: none;
    }

    .left-navbar__item {
      justify-content: center;
    }

    .left-navbar__section {
      padding-top: 12px;

      &:first-of-type {
        padding-top: 0;
      }
    }

    .left-navbar__section:not(:first-of-type) {
      border-top: 1px solid $light-border;
    }

    .left-navbar__section-title {
      display: none;
    }

    .left-navbar__bottom-gradient {
      width: 38px;
    }

    .left-navbar__top-gradient {
      width: 38px;
    }

    .left-navbar__link {
      width: fit-content;
    }

    .left-navbar__menu {
      padding-bottom: 16px;
    }
  }

  &__shrink-btn {
    width: 100%;
    padding: 5px 0;
    border: none;
    border-top: 1px solid $light-border;
    background-color: transparent;
    color: $text-neutral;
    font-size: 16px;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: $text-dark;
    }
  }

  &__top-gradient {
    position: absolute;
    top: $navbar-left-header-height;
    z-index: 1;
    width: 180px;
    height: $view-standard-padding;
    margin-left: $view-standard-padding;
    background: linear-gradient($background 0%, $background 30%, rgb(0 0 0 / 0%) 100%);
  }

  .duties {
    .icon-steering-wheel {
      display: inline-block;
      vertical-align: sub;
      width: 13px;
      height: 13px;
      background-image: url('@/assets/img/icons/steering-wheel-neutral.svg');
      background-size: cover;
      margin-inline: 2.5px;
    }

    &:hover {
      .icon-steering-wheel {
        background-image: url('@/assets/img/icons/steering-wheel-hover.svg');
      }
    }

    &.exact-active {
      .icon-steering-wheel {
        background-image: url('@/assets/img/icons/steering-wheel-active.svg');
      }
    }
  }
}

.envelope::before {
  content: '\f0e0';
}
</style>

<i18n locale="fr">
{
  "submenus": {
    "devices": "Appareils",
    "drivers": "Conducteurs",
    "gtfsRT": "GTFS-RT",
    "passengerApp": "Application voyageurs",
    "passengersScreen": "Affichage voyageurs",
    "transportPlan": "Plan de transport",
    "users": "Utilisateurs",
    "vehicles": "Véhicules",
    "inbox": "Boîte de réception",
    "sent": "Messages envoyés"
  },
  "passengersMessages": "Messages voyageurs",
  "passengersInfo": "Info voyageurs",
  "duties": "Prises de service",
  "deviations": "Déviations",
  "dashboard": "Tableau de bord",
  "map": "Carte",
  "messages": "Messagerie",
  "urgencies": "Alertes urgentes",
  "resources": "Ressources",
  "report": "Rapports",
  "search": "Recherche",
  "stops": "Arrêts",
  "planning": "Planning",
  "trips": "Courses",
  "integrations": "Intégrations",
  "operations": "Exploitation",
  "passengers": "Voyageurs",
  "admin": "Administration"
}
</i18n>

<i18n locale="en">
{
  "submenus": {
    "devices": "Devices",
    "drivers": "Drivers",
    "gtfsRT": "GTFS-RT",
    "passengerApp": "Passengers app",
    "passengersScreen": "Passengers display",
    "transportPlan": "Transport plan",
    "users": "Users",
    "vehicles": "Vehicles",
    "inbox": "Inbox",
    "sent": "Sent"
  },
  "passengersMessages": "Passengers message",
  "passengersInfo": "Passengers info",
  "dashboard": "Dashboard",
  "duties": "Duties",
  "deviations": "Deviations",
  "map": "Map",
  "messages": "Messages",
  "urgencies": "Urgencies",
  "resources": "Resources",
  "report": "Reporting",
  "search": "Search",
  "stops": "Stops",
  "planning": "Planning",
  "trips": "Trips",
  "integrations": "Integrations",
  "operations": "Operations",
  "passengers": "Passengers",
  "admin": "Administration"
}
</i18n>

<i18n locale="cz">
{
  "report": "Report",
  "trips": "Linky",
  "deviation": "Odchylka",
  "passengersMessages": "Informace o cestujících",
  "map": "Mapa",
  "messages": "Zprávy",
  "search": "Vyhledávání",
  "dashboard": "Přehled"
}
</i18n>

<i18n locale="de">
{
  "report": "Bericht",
  "trips": "Fahrten",
  "deviation": "Abweichung",
  "passengersMessages": "Informationen für Passagiere",
  "map": "Karte",
  "messages": "Nachrichten",
  "search": "Suche",
  "dashboard": "Übersicht"
}
</i18n>

<i18n locale="es">
{
  "report": "Informe",
  "trips": "Servicios",
  "deviation": "Desviación",
  "passengersMessages": "Información del pasajero",
  "map": "Mapa",
  "messages": "Mensajes",
  "search": "Buscar",
  "dashboard": "Resumen"
}
</i18n>

<i18n locale="it">
{
  "report": "Report",
  "trips": "Servizi",
  "deviation": "Deviazione",
  "passengersMessages": "Informazioni sui passeggeri",
  "map": "Mappa",
  "messages": "Messaggi",
  "search": "Cerca",
  "dashboard": "Panoramica"
}
</i18n>

<i18n locale="pl">
{
  "report": "Raport",
  "trips": "Usługi",
  "deviation": "Odchylenie",
  "passengersMessages": "Dane pasażera",
  "map": "Mapa",
  "messages": "Wiadomości",
  "search": "Wyszukaj",
  "dashboard": "Podsumowanie"
}
</i18n>
