<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useDepotsStore, type Depot, type MapDepot } from '@/store-pinia/depots';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';
import ModalArchiveRestore from '@/components/ui/ModalArchiveRestore.vue';
import MapboxMap from '@/components/map/MapboxMap.vue';
import { getDatagrid, ColumnKey } from './depot.conf';
import { LngLatBounds } from 'mapbox-gl';
import { POSITION, useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import cloneDeep from 'clone-deep';

const NEW_LINE_ID = 'new-line';
enum ModalType {
  DELETE_DEPOT = 'delete_depot',
}

const { t } = useI18n();
const depotsStore = useDepotsStore();
const store = useStore();
const toast = useToast();

const datagrid = ref(getDatagrid());
const depotListFormatted = ref<Depot[]>([]);
const inEditionId = ref<string>();
const loading = ref(true);
const renderedDataLength = ref<number>();
const modalShown = ref<string>();
const selectedDepot = ref<Depot>();
const depotInEditionOriginalState = ref<Depot>();
const validationErrors = ref<{ [key: string]: boolean }>({});
const mapInstance = ref<mapboxgl.Map | null>(null);

const group = computed(() => store.getters.group);

const mapboxDepots = computed<MapDepot[]>(() =>
  depotListFormatted.value.map(depot => ({
    id: depot.id,
    latitude: depot.location.latitude,
    longitude: depot.location.longitude,
    radius: depot.radius,
    name: depot.name,
  })),
);

const newDepot = ref<Depot>({
  id: NEW_LINE_ID,
  name: '',
  location: { latitude: undefined, longitude: undefined },
  radius: 50,
});

const lineInEdition = computed(() => inEditionId.value !== undefined);

const buildCellInjectors = computed(() => {
  const bindValueChanged =
    (apiDataRow: Depot) =>
    ({ value, field }: { value: any; field: string }) => {
      updateValue(apiDataRow, value, field);
    };

  const bindIsValidValue =
    (apiDataRow: Depot, key: string) =>
    ({ value, isValid }: { value: any; isValid: boolean }) => {
      checkInvalidValues(apiDataRow, isValid, key);
    };

  return {
    [ColumnKey.NAME]: ({ apiData }: { apiData: Depot }) => ({
      editMode: apiData.id === inEditionId.value,
      placeholder: t('newDepotName'),
      valueChanged: bindValueChanged(apiData),
      isValidValue: bindIsValidValue(apiData, ColumnKey.NAME),
    }),
    [ColumnKey.LATITUDE]: ({ apiData }: { apiData: Depot }) => ({
      editMode: apiData.id === inEditionId.value,
      valueChanged: bindValueChanged(apiData),
      isValidValue: bindIsValidValue(apiData, ColumnKey.LATITUDE),
    }),
    [ColumnKey.LONGITUDE]: ({ apiData }: { apiData: Depot }) => ({
      editMode: apiData.id === inEditionId.value,
      valueChanged: bindValueChanged(apiData),
      isValidValue: bindIsValidValue(apiData, ColumnKey.LONGITUDE),
    }),
    [ColumnKey.RADIUS]: ({ apiData }: { apiData: Depot }) => ({
      editMode: apiData.id === inEditionId.value,
      valueChanged: bindValueChanged(apiData),
      isValidValue: bindIsValidValue(apiData, ColumnKey.RADIUS),
    }),
  };
});

function updateValue(apiDataRow: Depot, value: any, field: string) {
  const keys = field.split('.');
  // handle specific case object location
  if (keys.length === 2 && keys[0] === 'location') {
    const key = keys[1] as keyof Depot['location'];
    apiDataRow.location[key] = value;
  } else {
    // handle others fields
    apiDataRow[field as keyof Depot] = value as never;
  }
}

function checkInvalidValues(apiDataRow: Depot, isValid: boolean, key: string) {
  if (apiDataRow.id === inEditionId.value || apiDataRow.id === NEW_LINE_ID) {
    validationErrors.value[key] = !isValid;
  }
}

function addNewDepot() {
  if (!depotListFormatted.value.find(d => d.id === NEW_LINE_ID)) {
    inEditionId.value = NEW_LINE_ID;
    depotListFormatted.value.unshift({ ...newDepot.value });
  }
}

async function saveEdits(apiDataRow: Depot) {
  const hasErrors = Object.values(validationErrors.value).some(error => error);
  if (hasErrors) {
    const errorToast = toast.error(t('fixErrors'), { position: POSITION.BOTTOM_RIGHT });
    setTimeout(() => toast.dismiss(errorToast), 5000);
  } else {
    loading.value = true;
    const depotData = {
      // casting long/lat since it has been verified by hasErrors
      name: apiDataRow.name,
      location: {
        latitude: apiDataRow.location.latitude as number,
        longitude: apiDataRow.location.longitude as number,
      },
      radius: apiDataRow.radius,
    };

    if (apiDataRow.id === NEW_LINE_ID) {
      await depotsStore.createDepot(group.value._id, depotData);
      await loadData();
      resetNewDepot();
    } else {
      const updatedData = {
        ...apiDataRow,
        location: {
          latitude: apiDataRow.location.latitude,
          longitude: apiDataRow.location.longitude,
        },
      } as Depot;

      await depotsStore.updateDepot(group.value._id, apiDataRow.id, updatedData);
      const index = depotListFormatted.value.findIndex(d => d.id === apiDataRow.id);
      if (index !== -1) {
        depotListFormatted.value[index] = { ...apiDataRow };
      }
    }
    initBoundsBasedOnSettings();
    inEditionId.value = undefined;
    validationErrors.value = {};
    loading.value = false;
  }
}

function toggleEditionMode(apiDataRow: Depot, editionMode: boolean) {
  if (editionMode) {
    if (inEditionId.value === NEW_LINE_ID) {
      depotListFormatted.value = depotListFormatted.value.filter(d => d.id !== NEW_LINE_ID);
    }
    inEditionId.value = apiDataRow.id;
    depotInEditionOriginalState.value = cloneDeep({ ...apiDataRow });
  } else {
    resetLine(apiDataRow.id);
    inEditionId.value = undefined;
    depotInEditionOriginalState.value = undefined;
    validationErrors.value = {};
    depotListFormatted.value = [...depotListFormatted.value];
  }
}

function resetLine(id: string) {
  const index = depotListFormatted.value.findIndex(depot => depot.id === id);
  if (id === NEW_LINE_ID) {
    depotListFormatted.value.splice(index, 1);
    resetNewDepot();
  } else if (depotInEditionOriginalState.value) {
    depotListFormatted.value[index] = { ...depotInEditionOriginalState.value };
  }
  initBoundsBasedOnSettings();
}

function resetNewDepot() {
  newDepot.value = {
    id: NEW_LINE_ID,
    name: '',
    location: { latitude: undefined, longitude: undefined },
    radius: 50,
  };
}

function handleMapClick(event: { lngLat: { lng: number; lat: number } }) {
  if (lineInEdition.value && inEditionId.value) {
    const depot = depotListFormatted.value.find(d => d.id === inEditionId.value);
    if (depot) {
      depot.location.latitude = event.lngLat.lat;
      depot.location.longitude = event.lngLat.lng;
    }
  }
}

function openDeleteModal(depot: Depot) {
  selectedDepot.value = depot;
  modalShown.value = ModalType.DELETE_DEPOT;
}

function closeModal() {
  modalShown.value = undefined;
  selectedDepot.value = undefined;
}

async function confirmDelete() {
  const depot = selectedDepot.value;
  if (depot) {
    await depotsStore.deleteDepot(group.value._id, depot.id);
    depotListFormatted.value = depotListFormatted.value.filter(d => d.id !== depot.id);
    closeModal();
    initBoundsBasedOnSettings();
  }
}

async function loadData() {
  loading.value = true;
  await depotsStore.loadList().then(() => {
    const depots = depotsStore.list as Record<string, Depot>;

    depotListFormatted.value = Object.values(depots).map(depot => ({
      id: depot.id,
      name: depot.name,
      radius: depot.radius,
      location: {
        latitude: depot.location.latitude,
        longitude: depot.location.longitude,
      },
    }));
    loading.value = false;
  });
}

onMounted(() => {
  loadData();
});

function onMapLoad({ map }: { map: mapboxgl.Map }) {
  map.once('idle', () => {
    mapInstance.value = map;
    initBoundsBasedOnSettings();
  });
}

const mapBounds = ref<mapboxgl.LngLatBounds>();

function initBoundsBasedOnSettings() {
  const bounds = new LngLatBounds();
  if (group.value.bounds?.coordinates) {
    // Bounds on base gtfs coordinates
    group.value.bounds.coordinates.forEach((coord: [number, number]) => {
      if (Array.isArray(coord) && coord.length === 2) {
        bounds.extend(coord);
      }
    });
    // add depots value to bounds to handle case where they are out of default bounds
    if (mapboxDepots.value.length > 0) {
      mapboxDepots.value.forEach(depot => {
        if (depot.longitude && depot.latitude) bounds.extend([depot.longitude, depot.latitude]);
      });
    }
  }
  mapBounds.value = bounds;
}
</script>

<template>
  <div class="depot-page">
    <div class="depot-page__button-group">
      <Btn type="primary" :disabled="lineInEdition" @click="addNewDepot">
        <i class="fas fa-plus mr-2" aria-hidden="true"></i>
        {{ $t('addDepot') }}
      </Btn>
      <div v-if="lineInEdition" class="depot-page__info-box">
        <font-awesome-icon icon="fa-location-dot " class="mr-2" />
        {{ $t('definePosition') }}
      </div>
    </div>

    <div class="depot-page__content-wrapper">
      <div class="depot-page__list">
        <DataGridVuetify
          v-model:rendered-data-length="renderedDataLength"
          :title="$t('depot', { count: renderedDataLength })"
          :data="depotListFormatted"
          :datagrid="datagrid"
          height="calc(100vh - 210px)"
          :build-cell-injectors="buildCellInjectors"
          :is-in-edition-mode="lineInEdition"
          :line-id-in-edition="inEditionId ?? undefined"
          :loading="loading"
        >
          <template #actions="propsAction">
            <ActionCell
              :edit-mode="[NEW_LINE_ID, inEditionId].includes(propsAction.object.id)"
              :actions="[NEW_LINE_ID, inEditionId].includes(propsAction.object.id) ? [] : ['edit', 'delete']"
              :object="propsAction.object"
              @edit="toggleEditionMode(propsAction.object, true)"
              @save="saveEdits"
              @switchOffEditionMode="toggleEditionMode(propsAction.object, false)"
              @delete="openDeleteModal(propsAction.object)"
            />
          </template>
        </DataGridVuetify>
      </div>

      <div class="depot-page__map-wrapper">
        <div class="depot-page__map-container">
          <MapboxMap
            v-model:bounds="mapBounds"
            :gtfs-id="group.current_file"
            :stops="[]"
            :trips="[]"
            :depots="mapboxDepots"
            :depot-editing="lineInEdition"
            display-tooltip
            full-screen-option
            @click="handleMapClick"
            @load="onMapLoad"
          />
        </div>
      </div>
    </div>
    <ModalArchiveRestore
      v-if="modalShown === ModalType.DELETE_DEPOT"
      @close="closeModal"
      @submit="confirmDelete"
    />
  </div>
</template>

<style lang="scss">
.depot-page {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .datagrid-vuetify__body,
  .mapboxgl-map {
    border-radius: 12px;
  }

  &__button-group {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__info-box {
    padding: 10px 12px;
    border: 1px solid $light-border;
    border-radius: 5px;
    background: $background-variant;
  }

  &__content-wrapper {
    display: flex;
    flex: 1;
    gap: 20px;
    overflow: hidden;
  }

  &__list {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__map-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__map-container {
    position: relative;
    flex: 1;
  }

  .action-cell__btn {
    display: flex;
    align-items: center;
  }
}
</style>

<i18n locale="fr">
  {
    "fixErrors": "Veuillez saisir des informations valides et remplir tout les champs obligatoires.",
    "addDepot": "Ajouter un dépôt",
    "depot": "Dépôt|Dépôts",
    "definePosition": "Définissez la position au sein du tableau ou directement sur la carte",
    "newDepotName": "Nouveau Dépôt",
  }
</i18n>

<i18n locale="en">
  {
    "fixErrors": "Please enter valid information and fill in all required fields.",
    "depot": "Depot|Depots",
    "addDepot": "Add a depot",
    "definePosition": "Define coordinates in the table or directly on the map",
    "newDepotName": "New depot",
  }
</i18n>
