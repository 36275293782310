<script setup lang="ts">
import Btn from '@/components/ui/Btn.vue';

const emit = defineEmits(['close']);

function requestPermission() {
  // Check if browser support Notification
  if ('Notification' in window) {
    Notification.requestPermission().then(result => {
      if (result) emit('close');
    });
  }
}
</script>

<template>
  <div class="notification-request">
    <div class="notification-request__top-container">
      <div class="notification-request__title">
        <font-awesome-icon icon="fa-exclamation-circle" class="notification-request__icon" />
        {{ $t('title') }}
      </div>
      <div class="notification-request__text">{{ $t('text') }}</div>
    </div>
    <div class="notification-request__btn-container">
      <Btn type="primary" @click="requestPermission">
        <font-awesome-icon icon="fa-bell" class="notification-request__bell" />
        {{ $t('activate') }}
      </Btn>
      <Btn type="secondary" @click="requestPermission">{{ $t('doNotActivate') }}</Btn>
    </div>
  </div>
</template>

<style lang="scss">
.notification-request {
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 500px;
  border-radius: 12px;
  background-color: $canvas;
  color: $text-dark;
  box-shadow: 0 4px 40px rgb(0 0 0 / 25%);

  &__bell {
    width: 15px;
    margin-right: 8px;
  }

  &__btn-container {
    display: flex;
    gap: 12px;
    width: 100%;
    padding: 20px;

    .ui-btn {
      width: 50%;
      font-weight: $font-weight-medium;
    }

    .ui-btn + .ui-btn {
      margin: 0;
    }
  }

  &__icon {
    font-size: 19px;
  }

  &__title {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 10px;
    font-weight: $font-weight-semi-bold;
    font-size: 16px;
  }

  &__text {
    font-weight: $font-weight-medium;
    line-height: 21px;
  }

  &__top-container {
    padding: 20px;
    border-bottom: 1px solid $light-border;
  }
}
</style>

<i18n locale="fr">
  {
    "title": "Notifications d'alertes urgentes",
    "text": "Activez les notifications pour rester informé des alertes urgentes, même depuis d'autres onglets que Pysae.",
    "activate": "Activer les notifications",
    "doNotActivate": "Ne pas activer"
  }
</i18n>

<i18n locale="en">
  {
    "title": "Urgent alert notifications",
    "text": "Turn on notifications to stay informed of urgent alerts, even from tabs other than Pysae.",
    "activate": "Turn on notifications",
    "doNotActivate": "No thanks"
  }
</i18n>
