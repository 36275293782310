import MapboxDraw, {
  type DrawCustomMode,
  type DrawCustomModeThis,
  type MapMouseEvent,
} from '@mapbox/mapbox-gl-draw';
import cloneDeep from 'clone-deep';
import { lineString, point, distance, booleanPointOnLine, nearestPointOnLine } from '@turf/turf';
import type { Feature, Point, Position } from 'geojson';
import type { MapboxDirectionsAPI } from '@/@types/mapbox';

export interface ShapeState {
  canDrag: boolean;
  dragIndex: number | null;
  dragMoving: boolean;
  isRefreshing: boolean;
  inDeviationMode: boolean;
  inShapeEdition: boolean;
  selectedPoints: string[];
  previouslySelectedPoints: string[];
  displayedSelectedPoints: string[];
  startIndexUpdate: number | null;
  inLastPointModification: boolean;
  initialWaypointFeatures: Array<MapboxDraw.DrawPoint>;
  initialWaypointFeaturesWithoutSelection: Array<MapboxDraw.DrawPoint> | null;
  initialCoords: Position[];
  lineFeature: MapboxDraw.DrawLineString;
  inDeviationLineFeature: MapboxDraw.DrawLineString;
  newLineFeature: MapboxDraw.DrawLineString;
  waypointsFeatures: Array<MapboxDraw.DrawPoint>;
  newWaypointsFeatures: Array<MapboxDraw.DrawPoint>;
  limitWaypointsIds: string[];
}

export interface CustomFeature extends GeoJSON.Feature {
  properties: {
    id: string;
    active: string;
    meta: string;
    'meta:type': string;
    coord_path?: string;
    isNewLine?: boolean;
    selectedPoint?: boolean;
    isSelection?: boolean;
    isLimitPoint?: boolean;
    isNew?: boolean;
    isNewIndex?: number;
  };
}

export interface Waypoint {
  snapRoad: boolean;
  coordinates: Position;
}

const deduplicateArray = (arr: Position[]): Position[] =>
  arr.reduce((acc: Position[], val) => {
    const prev = acc[acc.length - 1];
    if (!prev || val[0] !== prev[0] || val[1] !== prev[1]) acc.push(val);
    return acc;
  }, []);

const round6 = (n: number): number => Math.round(n * 1e6) / 1e6;

const isLeftClick = (e: MapMouseEvent): boolean => e.originalEvent && e.originalEvent.button === 0;

const isRightClick = (e: MapMouseEvent): boolean => e.originalEvent && e.originalEvent.button === 2;

const isNewPoint = (e: MapMouseEvent): boolean => !!e.featureTarget?.properties?.isNew;

const isLimitPoint = (e: MapMouseEvent): boolean => !!e.featureTarget?.properties?.isLimitPoint;

const isShape = (e: MapMouseEvent): boolean =>
  !!e.featureTarget?.properties && ['shape', 'inDeviationLine'].includes(e.featureTarget.properties.id);

/**
 * find nearest point index & get precise position
 */
function findNearestPoints(
  waypointsFeatures: Array<MapboxDraw.DrawPoint>,
  longlat: Position,
): { index: number; snapped: Feature<Point> } | null {
  let nearestLength: number | null = null;
  let nearestItem = null;
  waypointsFeatures.forEach((wp, index) => {
    if (index !== 0) {
      const pt = point(longlat);
      const line = lineString([waypointsFeatures[index - 1].coordinates, wp.coordinates]);
      const isPointOnLine = booleanPointOnLine(pt, line, { epsilon: 5e-4 });
      if (isPointOnLine) {
        const snapped = nearestPointOnLine(line, pt, { units: 'kilometers' });
        const l = snapped.properties.dist;
        if (nearestLength === null || l < nearestLength) {
          nearestLength = l;
          nearestItem = { index: index, snapped: snapped };
        }
      }
    }
  });
  return nearestItem;
}

function calcDistanceBetween2Points(longlat1: Position, longlat2: Position): number {
  const from = point(longlat1);
  const to = point(longlat2);
  return distance(from, to);
}

async function mapboxDirections(
  state: ShapeState,
  waypoints: Array<Position>,
): Promise<Array<Array<Position>>> {
  const strCoordinates = waypoints.map(p => p.map(round6).join(',')).join(';');
  const params = Object.entries({
    geometries: 'geojson',
    overview: 'full',
    continue_straight: 'false',
    steps: 'true',
    access_token: import.meta.env.VITE_MAPBOX_API_KEY,
  })
    .map(e => e.join('='))
    .join('&');
  const response = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${strCoordinates}.json?${params}`,
  );
  const result: MapboxDirectionsAPI = await response.json();
  const pointsResults = result.routes[0].geometry.coordinates;
  state.newLineFeature.setCoordinates(pointsResults);
  return [pointsResults];
}

async function updateCoordinates(state: ShapeState, updateIndexes?: Array<number>) {
  let start = 0;

  const promises: Array<Promise<Array<Position[]>>> = [];

  const consecutiveSnapWaypoints = (() => {
    const queuedPoints: Position[] = [];

    const dequeue = () => {
      if (queuedPoints.length > 0) {
        // Dequeue coordinates generation
        if (state.inLastPointModification) {
          // remove 1 coord in case last point to avoid re-write previous path
          queuedPoints.shift();
        }
        promises.push(mapboxDirections(state, queuedPoints.splice(0)));
      }
    };

    return {
      dequeue,

      queue(segment: Array<Position>) {
        if (queuedPoints.length === 0) {
          queuedPoints.push(segment[0]);
        }
        queuedPoints.push(segment[1]);

        if (queuedPoints.length >= 25) {
          dequeue();
        }
      },
    };
  })();

  while (start < state.waypointsFeatures.length - 1) {
    const points = state.waypointsFeatures.slice(start, start + 2);
    /** @type {Array<Point>} */
    const segment = points.map(p => p.getCoordinates());

    if (points[1] && points[1].properties?.snapRoad) {
      if (!updateIndexes || updateIndexes.includes(start + 1)) {
        // Queue coordinates generation
        consecutiveSnapWaypoints.queue(segment);
      } else {
        consecutiveSnapWaypoints.dequeue();

        // Keep previously generated coordinates
        const origCoords = state.lineFeature.getCoordinates();
        // Ts ignore since Position[] are coming from different sources
        // @ts-ignore
        promises.push([
          origCoords.slice(points[0].properties?.coord_index, points[1].properties.coord_index + 1),
        ]);
      }
    } else {
      consecutiveSnapWaypoints.dequeue();
      // Ts ignore since Position[] are coming from different sources
      // @ts-ignore
      promises.push([segment]);
    }

    start += 1;
  }

  consecutiveSnapWaypoints.dequeue();
  const groupedWaypointLines = await Promise.all(promises);
  state.waypointsFeatures[0].setProperty('coord_index', 0);
  const coordinates: Array<Position> = [];
  groupedWaypointLines
    .reduce((acc, group) => acc.concat(group), [])
    .forEach((waypointLine, index) => {
      // Remove a point to connect segments, preferably on the road (not useful in last point case)
      if (coordinates.length > 0 && !state.inLastPointModification) {
        state.waypointsFeatures[index].properties?.snapRoad ? waypointLine.shift() : coordinates.pop();
      }
      coordinates.push(...waypointLine);
      state.waypointsFeatures[index + 1].setProperty('coord_index', coordinates.length - 1);
    });

  state.lineFeature.setCoordinates(coordinates);
}

interface DrawShape extends DrawCustomMode<ShapeState, Position[]>, DrawCustomModeThis {
  addTempWaypoint(state: ShapeState, coordinates: Position, index: number): void;
  addNewWaypoint(state: ShapeState, coordinates: Position): void;
  createWaypoint(waypoint: Waypoint): MapboxDraw.DrawPoint;
  refreshRoute(state: ShapeState, updateIndexes?: Array<number>): void;
  removeWaypoint(state: ShapeState, e: MapMouseEvent): void;
  setSelectionPointOnShape(state: ShapeState, e: MapMouseEvent): void;
  selectPoints(state: ShapeState, e: MapMouseEvent, shapeClickIndex?: number): void;
  startDragging(state: ShapeState, index: number): void;
  stopDragging(state: ShapeState): void;
  deletePreviousPartAndEditShapeLocally(state: ShapeState, coordinates: Position): void;
  editShapeLocally(state: ShapeState, reset?: boolean): void;
}

// @ts-ignore
const drawShape: DrawShape = {
  /**
   * Add a new temporary waypoint Feature in list waypointsFeatures
   */
  addTempWaypoint(state: ShapeState, coordinates: Position, index: number) {
    state.waypointsFeatures.splice(
      index,
      0,
      this.createWaypoint({
        coordinates,
        snapRoad: true,
      }),
    );
    state.initialWaypointFeatures = cloneDeep(state.waypointsFeatures);
  },

  /**
   * Add a new waypoint Feature in list newWaypointsFeatures
   */
  addNewWaypoint(state: ShapeState, coordinates: Position) {
    state.newWaypointsFeatures.push(
      this.createWaypoint({
        coordinates,
        snapRoad: true,
      }),
    );
  },

  createWaypoint(waypoint: Waypoint): MapboxDraw.DrawPoint {
    const feature = this.newFeature({
      type: MapboxDraw.constants.geojsonTypes.FEATURE,
      properties: {
        snapRoad: waypoint.snapRoad,
      },
      geometry: {
        type: MapboxDraw.constants.geojsonTypes.POINT,
        coordinates: waypoint.coordinates,
      },
    }) as MapboxDraw.DrawPoint;
    this.addFeature(feature);
    this.select(feature.id as string);
    this.doRender(feature.id as string);
    return feature;
  },

  async refreshRoute(state: ShapeState, updateIndexes?: Array<number>) {
    state.isRefreshing = true;
    this.updateUIClasses({ mouse: 'wait' });
    if (state.waypointsFeatures.length >= 2) {
      await updateCoordinates(state, updateIndexes);
    } else {
      state.lineFeature.setCoordinates([]);
    }

    // Couldn't find documented API to trigger the actual render asynchronously
    // @ts-ignore
    this._ctx.store.render();
    state.isRefreshing = false;
  },

  /**
   * Delete a new waypoint used for modify shape
   */
  removeWaypoint(state: ShapeState, e: MapMouseEvent) {
    const index1 = state.waypointsFeatures.findIndex(wp => wp.id === e.featureTarget.properties?.id);
    const index = state.newWaypointsFeatures.findIndex(wp => wp.id === e.featureTarget.properties?.id);

    if (index !== -1) {
      state.waypointsFeatures.splice(index1, 1);
      state.newWaypointsFeatures.splice(index, 1);
      this.deleteFeature(e.featureTarget.properties?.id);

      const isResetCase = state.newWaypointsFeatures.length === 0 ? true : false;
      state.inShapeEdition = false;
      this.editShapeLocally(state, isResetCase);
    }
  },

  /**
   * Create a waypointFeature in list to deviate part selection
   */
  setSelectionPointOnShape(state: ShapeState, e: MapMouseEvent) {
    const nearestItem = findNearestPoints(state.waypointsFeatures, [e.lngLat.lng, e.lngLat.lat]);
    if (nearestItem) {
      this.addTempWaypoint(state, nearestItem.snapped.geometry.coordinates, nearestItem.index);
      this.selectPoints(state, e, nearestItem.index);
    }
  },

  /**
   * Select points in list to deviate part selection & handle render of this part
   */
  selectPoints(state: ShapeState, e: MapMouseEvent, shapeClickIndex?: number) {
    const clickedIndex =
      shapeClickIndex || state.waypointsFeatures.findIndex(wp => wp.id === e.featureTarget.properties?.id);

    if (state.selectedPoints.length > 0) {
      const previousSelectedPointState = cloneDeep(state.selectedPoints);
      const previousIndex = state.waypointsFeatures.findIndex(wp => wp.id === state.selectedPoints[0]);
      const lastIndex = state.waypointsFeatures.findIndex(
        wp => wp.id === state.selectedPoints[state.selectedPoints.length - 1],
      );
      let elements = [];
      let pointIndexes = [];
      // Case click between 2 previously selected points : replace the nearest point by new index.
      if (previousIndex !== lastIndex && previousIndex < clickedIndex && clickedIndex < lastIndex) {
        const dist1 = calcDistanceBetween2Points(
          state.waypointsFeatures[previousIndex].coordinates,
          state.waypointsFeatures[clickedIndex].coordinates,
        );
        const dist2 = calcDistanceBetween2Points(
          state.waypointsFeatures[clickedIndex].coordinates,
          state.waypointsFeatures[lastIndex].coordinates,
        );
        pointIndexes = dist1 >= dist2 ? [previousIndex, clickedIndex] : [clickedIndex, lastIndex];
      } else {
        // case click before or after 1st point : extend the selection to the start, or to the end.
        pointIndexes =
          clickedIndex > previousIndex ? [previousIndex, clickedIndex] : [clickedIndex, lastIndex];
      }
      elements = state.waypointsFeatures.slice(pointIndexes[0], pointIndexes[1] + 1);
      state.selectedPoints = elements.map(wp => wp.id as string);

      if (state.selectedPoints.length > 0) {
        const coords = state.waypointsFeatures.map(wp => wp.coordinates);
        const firstCoordIndex = coords.findIndex(
          coord => coord === state.waypointsFeatures[pointIndexes[0]].coordinates,
        );
        const secondCoordIndex = coords.findIndex(
          coord => coord === state.waypointsFeatures[pointIndexes[1]].coordinates,
        );

        state.inDeviationLineFeature.setCoordinates(coords.slice(firstCoordIndex, secondCoordIndex + 1));
        state.displayedSelectedPoints = [
          state.waypointsFeatures[pointIndexes[0]].id as string,
          state.waypointsFeatures[pointIndexes[1]].id as string,
        ];
      }
      // update previous & new elements potentially selected
      [...previousSelectedPointState, ...elements.map(wp => wp.id)].forEach(wpId =>
        this.doRender(wpId as string),
      );
    } else {
      const elementId = state.waypointsFeatures[clickedIndex].id;
      if (typeof elementId === 'string') {
        this.doRender(elementId);
        state.selectedPoints.push(elementId);
        state.displayedSelectedPoints = [elementId];
        state.inDeviationLineFeature.setCoordinates([]);
      }
    }
    state.inDeviationMode = true;
    this.map.fire(MapboxDraw.constants.events.UPDATE, {
      action: 'select_path_to_edit',
    });
  },

  startDragging(state: ShapeState, index: number) {
    this.map.dragPan.disable();
    state.canDrag = true;
    state.dragIndex = index;
  },

  stopDragging(state: ShapeState) {
    this.map.dragPan.enable();
    state.canDrag = false;
    state.dragMoving = false;
    state.dragIndex = null;
  },

  deletePreviousPartAndEditShapeLocally(state: ShapeState, coordinates: Position) {
    this.addNewWaypoint(state, coordinates);

    const indexes: Array<number> = [];
    if (state.selectedPoints.length > 0) {
      // Detect if selected point is first (specific case)
      const isOnePointModification = state.selectedPoints.length === 1;

      // detect if selected point is last (specific case)
      state.inLastPointModification =
        state.waypointsFeatures.findIndex(wp => wp.id === state.selectedPoints[0]) ===
          state.waypointsFeatures.length - 1 && isOnePointModification;

      // Collect selected indexes
      state.selectedPoints.forEach(id => {
        const index = state.waypointsFeatures.findIndex(wp => wp.id === id);
        if (index !== -1) indexes.push(index);
      });

      // Set snapRoad to 1st / last point of the selected portion, delete others points
      state.selectedPoints.forEach(id => {
        const index = state.waypointsFeatures.findIndex(wp => wp.id === id);
        if (index !== -1) {
          if (state.selectedPoints[state.selectedPoints.length - 1] === id || state.selectedPoints[0] === id)
            state.waypointsFeatures[index].setProperty('snapRoad', true);
          else this.deleteFeature(state.waypointsFeatures.splice(index, 1)[0].id as string);
        }
      });

      // need to increment by 1, except in case of a 1 point modification
      const specificCaseIncrementIndex = isOnePointModification ? 0 : 1;
      state.startIndexUpdate = Math.min(...indexes) + specificCaseIncrementIndex;
      state.previouslySelectedPoints = cloneDeep(state.selectedPoints);
      state.selectedPoints = [];

      state.initialWaypointFeaturesWithoutSelection = cloneDeep(state.waypointsFeatures);
    }
    this.editShapeLocally(state);

    state.inShapeEdition = true;
  },

  async editShapeLocally(state: ShapeState, reset: boolean = false) {
    if (state.startIndexUpdate !== null) {
      const maxIndex = state.startIndexUpdate + state.newWaypointsFeatures.length + 1;
      state.waypointsFeatures = reset
        ? cloneDeep(state.initialWaypointFeatures)
        : (cloneDeep(state.initialWaypointFeaturesWithoutSelection) as MapboxDraw.DrawPoint[]);
      if (state.inLastPointModification) {
        state.waypointsFeatures.push(...state.newWaypointsFeatures);
      } else state.waypointsFeatures.splice(state.startIndexUpdate, 0, ...state.newWaypointsFeatures);
      const indexToUpdate = [];
      for (let i = state.startIndexUpdate; i <= maxIndex; i++) {
        indexToUpdate.push(i);
      }
      if (!reset) await this.refreshRoute(state, indexToUpdate);

      this.map.fire(MapboxDraw.constants.events.UPDATE, {
        action: MapboxDraw.constants.updateActions.CHANGE_COORDINATES,
        features: [state.lineFeature.toGeoJSON()],
      });

      if (reset) {
        {
          state.newLineFeature.setCoordinates([]);
          state.selectedPoints = cloneDeep(state.previouslySelectedPoints);
          state.lineFeature.setCoordinates(state.waypointsFeatures.map(wp => wp.coordinates));
          this.doRender('shape');

          this.map.fire(MapboxDraw.constants.events.UPDATE, {
            action: 'select_path_to_edit',
          });
        }
      }

      setTimeout(() => {
        this.doRender('newLineFeature');
        this.doRender('inDeviationLine');
      }, 100);
    }
  },

  // Public API

  onSetup(shapePoints: Position[]) {
    const initialWaypoints = cloneDeep(shapePoints.map(coordinates => ({ coordinates, snapRoad: false })));

    this.clearSelectedFeatures();

    const state: ShapeState = {
      canDrag: false,
      dragIndex: null,
      dragMoving: false,
      isRefreshing: false,

      inDeviationMode: false,
      inShapeEdition: false,
      selectedPoints: [],
      previouslySelectedPoints: [],
      displayedSelectedPoints: [],
      startIndexUpdate: null,
      inLastPointModification: false,

      initialWaypointFeaturesWithoutSelection: null,
      initialCoords: initialWaypoints.map(wp => wp.coordinates),

      lineFeature: this.newFeature({
        type: MapboxDraw.constants.geojsonTypes.FEATURE,
        id: 'shape',
        properties: {},
        geometry: {
          type: MapboxDraw.constants.geojsonTypes.LINE_STRING,
          coordinates: [],
        },
      }) as MapboxDraw.DrawLineString,
      inDeviationLineFeature: this.newFeature({
        type: MapboxDraw.constants.geojsonTypes.FEATURE,
        id: 'inDeviationLine',
        properties: {},
        geometry: {
          type: MapboxDraw.constants.geojsonTypes.LINE_STRING,
          coordinates: [],
        },
      }) as MapboxDraw.DrawLineString,
      newLineFeature: this.newFeature({
        type: MapboxDraw.constants.geojsonTypes.FEATURE,
        id: 'newLineFeature',
        properties: {},
        geometry: {
          type: MapboxDraw.constants.geojsonTypes.LINE_STRING,
          coordinates: [],
        },
      }) as MapboxDraw.DrawLineString,

      initialWaypointFeatures: cloneDeep(initialWaypoints.map(wp => this.createWaypoint(wp))),
      waypointsFeatures: cloneDeep(initialWaypoints.map(wp => this.createWaypoint(wp))),
      newWaypointsFeatures: [],

      limitWaypointsIds: [],
    };
    state.limitWaypointsIds = [
      state.waypointsFeatures[0].id as string,
      state.waypointsFeatures[state.waypointsFeatures.length - 1].id as string,
    ];
    this.addFeature(state.lineFeature);
    this.addFeature(state.inDeviationLineFeature);
    this.addFeature(state.newLineFeature);
    this.select(state.lineFeature.id as string);
    this.refreshRoute(state);

    return state;
  },

  onClick(state: ShapeState, e: MapMouseEvent) {
    this.stopDragging(state);
    if (isShape(e) && isLeftClick(e) && !state.inShapeEdition) {
      return this.setSelectionPointOnShape(state, e);
    }
    if (isLimitPoint(e) && isLeftClick(e) && !state.inShapeEdition) return this.selectPoints(state, e);
    if (isLeftClick(e) && state.inDeviationMode)
      return this.deletePreviousPartAndEditShapeLocally(state, e.lngLat.toArray());
    if (isNewPoint(e) && isRightClick(e)) {
      return this.removeWaypoint(state, e);
    }
  },

  onDrag(state: ShapeState, e: MapMouseEvent) {
    if (!state.canDrag) return this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.NONE });

    state.dragMoving = true;
    // Only can drag (to move) a new waypoint to edit a shape
    if (state.dragIndex !== null)
      state.newWaypointsFeatures[state.dragIndex].setCoordinates([e.lngLat.lng, e.lngLat.lat]);
  },

  onMouseMove(state: ShapeState, e: MapMouseEvent) {
    if (state.isRefreshing) return this.updateUIClasses({ mouse: 'wait' });

    if (isNewPoint(e)) return this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.MOVE });

    if (isShape(e) && !state.inShapeEdition)
      return this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.POINTER });

    if (state.inDeviationMode) return this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });

    this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.DRAG });
  },

  onMouseDown(state: ShapeState, e: MapMouseEvent) {
    if (!isLeftClick(e)) return;
    if (isNewPoint(e)) {
      const index = state.newWaypointsFeatures.findIndex(wp => wp.id === e.featureTarget.properties?.id);
      return this.startDragging(state, index);
    }
  },

  onMouseUp(state: ShapeState, e: MapMouseEvent) {
    if (state.dragMoving) {
      this.editShapeLocally(state);
    }

    this.stopDragging(state);
  },

  toDisplayFeatures(state: ShapeState, geojson: CustomFeature, display: { (feature: CustomFeature): void }) {
    // set points as active (only if in displayedSelectedPoints)
    geojson.properties.selectedPoint = state.displayedSelectedPoints.includes(geojson.properties.id);

    // set lines as active
    if (geojson.geometry.type === MapboxDraw.constants.geojsonTypes.LINE_STRING) {
      geojson.properties.active = MapboxDraw.constants.activeStates.ACTIVE;
    }

    // set of isNew for new waypoints stops
    if (state.newWaypointsFeatures.map(wp => wp.id).includes(geojson.properties.id)) {
      geojson.properties.isNewIndex =
        state.newWaypointsFeatures.findIndex(wp => wp.id === geojson.properties?.id) + 1;
      geojson.properties.isNew = true;
    }

    // set of limits points display
    if (state.limitWaypointsIds.includes(geojson.properties.id)) geojson.properties.isLimitPoint = true;

    // set of "isSelection property" for deviation line display
    if (geojson.properties.id === 'inDeviationLine') {
      if (state.newLineFeature.getCoordinates().length !== 0)
        geojson.properties.active = MapboxDraw.constants.activeStates.INACTIVE;
      geojson.properties.isSelection = true;
    }
    // set of "isNewLine property" for new line display
    if (geojson.properties.id === 'newLineFeature') geojson.properties.isNewLine = true;

    display(geojson);
  },
};

export default drawShape;
