<script setup lang="ts">
import { computed, ref, watch, onMounted, onUnmounted, nextTick, useTemplateRef, type PropType } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useI18n } from 'vue-i18n';
import { trips as apiTrips } from '@/api';
import ModalTripComment from '@/components/common/ModalComment.vue';
import ModalStopInfo from '@/components/common/ModalStopInfo.vue';
import Btn from '@/components/ui/Btn.vue';
import HeaderDatePicker from '@/components/ui/HeaderDatepicker.vue';
import ModalMessageNew from '@/components/ui/ModalMessageNew.vue';
import Calendar from '@/libs/calendar';
import { toCSV } from '@/libs/csv';
import { dateObjToGtfsFormat, getISODate, timestampFormatHHMM } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';
import { Permission } from '@/auth';

// Types
import { type TripUpdates, UpdateType } from '@/store-pinia/trip-updates';
import { type EventFeedItem } from './EventFeed.vue';
import type { Group } from '@/@types/group';
import type { Stop, StopTime, Trip } from '@/@types/gtfs';
import type { DeviceEvent } from '@/@types/device';

import { useTripUpdates } from '@/store-pinia/trip-updates';
import type { TripListItemV4 } from '@/@types/api/tripList';
import type { InactiveDates } from '@/libs/calendar';

import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { type Recipient, RecipientType } from '@/use/use-message';

import EventFeed from './EventFeed.vue';
import ModalConfig from './ModalTripDetailConfig.vue';
import TimelineContainer from './TimelineContainer.vue';
import TripDetailedMap from './TripDetailedMap.vue';
import TripInfo from './TripInfo.vue';
import { FeedEventType, ExportColumns, formatDelay, getRowContent } from './EventFeedShared.js';
import ErrorPage from '@/pages/ErrorPage/index.vue';

const route = useRoute();
const router = useRouter();
const store = useStore();
const tripUpStore = useTripUpdates();
const { t } = useI18n({});

dayjs.extend(utc);

enum ModalType {
  COMMENT = 'comment',
  MESSAGES = 'messages',
  STOP_INFO = 'stop_info',
  CONFIG = 'config',
}

enum TemporalityType {
  UNDERWAY = 'current',
  COMPLETED = 'passed',
  SCHEDULED = 'future',
}

const props = defineProps({
  query: {
    type: Object as PropType<{ date?: string; deviceId?: string }>,
    default: () => ({}),
  },

  tripId: {
    required: true,
    type: String,
  },
});

// TODO to remove when this interface is added to HistoryMap after ts migration
export interface HistoryMapDevice {
  events: DeviceEvent[];
  id: string;
}

const tripInfoComponent = useTemplateRef('tripInfo');

const updateTripInfoInterval = ref<NodeJS.Timer | null>(null);

const applyOnNextDays = ref<boolean>(false);
const calendarDisabledDates = ref<InactiveDates>({
  minDate: null,
  maxDate: null,
});
const downloadLink = ref<string>();
const isLoaded = ref<boolean>(false);
const mapboxDevice = ref<HistoryMapDevice>();
const modalShown = ref<ModalType>();
const stops = ref<{ [stopId: string]: Stop }>();
const stopTimes = ref<StopTime[]>();
const trip = ref<TripListItemV4>();
const tripInGtfs = ref<Trip | null>();
const trips = ref<{ [gtfsId: string]: { [tripId: string]: Trip } } | null>();
const tripsError = ref<{ response: { status: number } } | null>();

const deviceId = computed<string | undefined>(() => {
  if (route.query.deviceId) {
    return route.query.deviceId as string;
  } else if (!trip.value) {
    return undefined;
  } else if (!Array.isArray(trip.value.devices)) {
    return trip.value.devices;
  } else if (0 < trip.value.devices.length) {
    return trip.value.devices[0].id;
  }

  return undefined;
});

const downloadFileName = computed<string>(() => {
  return `trip-detailed_${props.tripId}_${serviceDate.value}.csv`;
});

const eventFeedRows = computed<EventFeedItem[]>(() => {
  return store.state.tripDetailed.eventFeed;
});

const group = computed<Group>(() => {
  return store.getters.group;
});

const gtfsId = computed<string>(() => {
  return store.getters['gtfs/getGtfsAt'](selectedTs.value) || group.value.current_file;
});

/** Return true if the trip is underway and a device is connected to the trip */
const isTripRunning = computed<boolean | undefined>(() => {
  return (
    mapboxDevice.value &&
    mapboxDevice.value.events.length > 0 &&
    trip.value?.temporality === TemporalityType.UNDERWAY
  );
});

const messageRecipients = computed<Recipient[]>(() => {
  const devices = trip.value?.devices || [];
  const recipients: Recipient[] = [];
  devices.forEach(device => {
    recipients.push({
      id: device.id,
      label: device.name,
      type: RecipientType.DEVICE,
    });
  });
  return recipients;
});

const selectedDate = computed<Date>({
  get() {
    if (route.query.date) {
      const date = dayjs(route.query.date as string)
        .hour(23)
        .minute(59)
        .second(59)
        .utc()
        .toDate();
      return date;
    }
    return new Date();
  },

  /** @param {Date} value */
  set(value) {
    router.push({
      name: GroupRoute.TRIP_DETAILED,
      params: {
        groupId: group.value._id,
        tripId: props.tripId,
      },
      query: {
        date: getISODate(value),
      },
    });
  },
});

const selectedTs = computed<number>(() => {
  const offset =
    stopTimes.value?.length && stopTimes.value?.length > 0 ? stopTimes.value[0].departure_time : 0;
  // `selectedDate` is at 23:59:59 of that day
  const defaultTs = selectedDate.value.getTime() / 1000 - 86399 + offset;

  return defaultTs;
});

const showTimeline = computed<boolean | undefined>(() => {
  return tripInGtfs.value && mapboxDevice.value?.events.length && mapboxDevice.value?.events.length > 0
    ? true
    : false;
});

const serviceDate = computed<string>(() => {
  return dateObjToGtfsFormat(selectedDate.value);
});

watch(
  () => isTripRunning.value,
  () => {
    if (isTripRunning.value) {
      startRealTimeMode();
    } else {
      if (updateTripInfoInterval.value) clearInterval(updateTripInfoInterval.value);
    }
  },
);

watch(
  () => gtfsId.value,
  () => {
    getStops();
    refreshPage();
  },
);

watch(
  () => trips.value,
  () => {
    if (trip.value && trips.value && tripInGtfs.value) {
      onTripChange();
    }
  },
);

watch(
  () => selectedDate.value,
  () => {
    refreshPage();
  },
);

// Get the stopTimes from the trip in the gtfs
watch(
  () => tripInGtfs.value,
  () => {
    if (tripInGtfs.value) {
      const gtfsStopTimes = tripInGtfs.value.stop_times;
      stopTimes.value = gtfsStopTimes.sort((a, b) => a.stop_sequence - b.stop_sequence);
    }
  },
  { immediate: true },
);

onMounted(async () => {
  isLoaded.value = false;

  await Promise.all([getTrip(), getStops()]);

  if (trip.value && gtfsId.value) {
    await tripUpStore.initStore(gtfsId.value, trip.value.id, trip.value.updates);
  }

  isLoaded.value = true;

  if (tripInGtfs.value) {
    observeContentHeight();
  }
});

onUnmounted(() => {
  if (updateTripInfoInterval.value) clearInterval(updateTripInfoInterval.value);

  tripUpStore.$reset();
});

/** CSS trick because it's not possible to set feed max size only with classic css... */
function observeContentHeight() {
  nextTick(() => {
    const tripDetailMapElement = document.getElementById('trip-detailed-map');
    const resizeObserver = new ResizeObserver(() => {
      const tripDetailedFeedElement = document.getElementById('trip-detailed-feed');
      // set feed to 0px to avoid bug if header zone increases
      if (tripDetailedFeedElement) tripDetailedFeedElement.style.height = `0px`;
      // Get map height & set it to feed
      const mapHeight = tripDetailMapElement?.offsetHeight;
      if (tripDetailedFeedElement) tripDetailedFeedElement.style.height = `${mapHeight}px`;
    });
    if (tripDetailMapElement) resizeObserver.observe(tripDetailMapElement);
  });
}

/** Close the modal, clear temporary data & refresh page */
function closeModal(withoutRefresh?: boolean) {
  if (!withoutRefresh) {
    refreshPage();
  }
  modalShown.value = undefined;
  applyOnNextDays.value = false;
}

function createDownloadLink() {
  if (downloadLink.value != null) {
    URL.revokeObjectURL(downloadLink.value);
    downloadLink.value = undefined;
  }

  const tableData: Array<Array<string | null>> = [];

  eventFeedRows.value.forEach((row: EventFeedItem) => {
    const newRow = [];
    // Event
    newRow.push(getRowContent(row));
    // Time
    newRow.push(formatHHMM(row.time));
    // Real time
    if (row.type === FeedEventType.ARRIVAL) {
      newRow.push(formatHHMM(row.theoreticalArrivalTime));
    } else if (row.type === FeedEventType.DEPARTURE) {
      newRow.push(formatHHMM(row.theoreticalDepartureTime));
    } else {
      newRow.push('');
    }
    // Delay
    newRow.push(formatDelay(row.delay));

    tableData.push(newRow);
  });

  const columnsTitles = Object.values(ExportColumns).reduce<string[]>((acc, title) => {
    acc.push(t(`eventFeed.${title}`));
    return acc;
  }, []);

  const data = [columnsTitles, ...tableData];

  downloadLink.value = toCSV(data);
}

/** Find the trip in the gtfs - if the trip can't be find in any gtfs, the page does not display */
function findTripInGtfs() {
  tripInGtfs.value =
    trips.value && trips.value[gtfsId.value as keyof { string: { string: TripListItemV4 } }] && trip.value
      ? (trips.value[gtfsId.value as keyof { string: { string: TripListItemV4 } }][
          trip.value.id as keyof { string: TripListItemV4 }
        ] as any)
      : null;
  // For days when the gtfs changes
  if (!tripInGtfs.value && trips.value) {
    const defaultGtfs = Object.values(trips.value).find(
      (gtfs: { [tripId: string]: Trip }) =>
        trip.value?.id && gtfs[trip.value?.id as keyof { string: Trip }] !== undefined,
    );
    if (defaultGtfs) {
      tripInGtfs.value = defaultGtfs[trip.value?.id as keyof { string: Trip }];
    }
  }
}

function formatHHMM(ts: number): string {
  return timestampFormatHHMM(ts, { refDate: selectedDate.value, tz: group.value.tz });
}

/** Get one trip from the trip list */
async function getTrip() {
  trips.value = null;
  tripsError.value = null;

  try {
    const data = await apiTrips.getTripFromTripList(group.value._id, serviceDate.value, props.tripId, true);
    const tripFromTripList =
      data.trips && data.trips.length > 0
        ? data.trips.find((trip: TripListItemV4) => trip.devices[0].id === deviceId.value)
        : data;

    // check security on serviceDate
    if (tripFromTripList && tripFromTripList.service_date === serviceDate.value) {
      trip.value = tripFromTripList;

      // needed to get the service_id of the trip to get the calendar
      const publishedTrips = await store.dispatch('trips/getPublishedTripsMapOn', {
        dateGtfs: serviceDate.value,
      });
      trips.value = publishedTrips;

      findTripInGtfs();
    } else {
      tripInGtfs.value = null;
    }
  } catch (e) {
    // TODO fix typing
    tripsError.value = e as {
      response: { status: number };
    };
  }
}

async function getStops() {
  stops.value = await store.dispatch('gtfs/getStopsMap', {
    gtfsId: gtfsId.value,
  });
}

function getUpdate(type: UpdateType) {
  return (trip.value?.updates?.[type as keyof TripUpdates] as string) || undefined;
}

/** Get the calendar's inactive dates */
async function onTripChange() {
  calendarDisabledDates.value = await Calendar.getInactiveDatesForATrip(
    tripInGtfs.value?.service_id || '',
    gtfsId.value,
  );
}

async function refreshPage() {
  tripUpStore.$reset();
  await getTrip();
  if (tripInGtfs.value) {
    observeContentHeight();
  }
  if (trip.value && gtfsId.value) {
    await tripUpStore.initStore(gtfsId.value, trip.value.id, trip.value.updates);
  }
}

/** Set a comment on the trip */
async function setTripComment(comment: string) {
  const tripUpdates = {
    query: {
      gtfs_id: trip.value?.gtfs[0].id,
      trip_id: props.tripId,
      start_date: serviceDate.value,
    },
    body: { ...trip.value?.updates, comment },
    many: applyOnNextDays.value,
  };
  await store.dispatch('trips/updateTrip', tripUpdates);

  closeModal();
}

function showModal(type: ModalType) {
  modalShown.value = type;
}

/** Get trip info every 5 seconds when trip status is running */
function startRealTimeMode() {
  updateTripInfoInterval.value = setInterval(() => {
    getTrip();
  }, 5000);
}

function redirectToModificationPage() {
  router.push({
    name: GroupRoute.TRIP_MODIFICATION,
    params: { groupId: group.value._id, tripId: props.tripId },
    query: { date: route.query.date },
  });
}
</script>

<template>
  <div class="trip-detailed">
    <!-- Toolbar -->
    <div class="trip-detailed__header">
      <div class="trip-detailed__header-left">
        <HeaderDatePicker
          v-model:value="selectedDate"
          :skip-disabled="true"
          :disabled="calendarDisabledDates"
        />
      </div>

      <div class="trip-detailed__header-right">
        <Btn type="secondary" :title="$t('seeMore')" @click="showModal(ModalType.CONFIG)">
          <font-awesome-icon icon="fa-solid fa-table-columns" />
        </Btn>
        <Btn type="secondary" :title="$t('sendMessage')" @click="showModal(ModalType.MESSAGES)">
          <font-awesome-icon icon="fa-paper-plane" />
        </Btn>
        <a
          class="trip-detailed__export-btn"
          :disabled="!store.getters.hasPermission(Permission.EXPORT_TRIP)"
          :download="downloadFileName"
          :href="downloadLink"
          @click="createDownloadLink()"
        >
          <Btn
            type="secondary"
            :disabled="!store.getters.hasPermission(Permission.EXPORT_TRIP)"
            :title="$t('download')"
          >
            <font-awesome-icon icon="fa-download" />
          </Btn>
        </a>
        <Btn type="secondary" @click="showModal(ModalType.STOP_INFO)">
          <font-awesome-icon icon="fa-location-dot" />
          <span class="btn-text">{{ $t('addStopInfo') }}</span>
        </Btn>
        <Btn type="secondary" @click="showModal(ModalType.COMMENT)">
          <font-awesome-icon icon="fa-comments" />
          <span class="btn-text">{{ $t('addComment') }}</span>
        </Btn>
        <Btn type="primary" @click="redirectToModificationPage">
          <font-awesome-icon icon="fa-plus" />
          <span class="btn-text">{{ $t('modifyTrip') }}</span>
        </Btn>
      </div>
    </div>

    <ErrorPage
      v-if="tripsError"
      :status-code="tripsError.response?.status"
      :text="
        tripsError.response?.status === 403
          ? $t('403ErrorMessage')
          : tripsError.response?.status === 404
            ? $t('noTrip')
            : undefined
      "
    />

    <template v-else>
      <!-- Trip Information -->
      <TripInfo
        v-if="trip && tripInGtfs"
        ref="tripInfo"
        :trip="trip"
        :date="selectedDate"
        :group-id="group._id"
        class="trip-detailed__info"
        @showModal="showModal"
        @redirectToModificationPage="redirectToModificationPage"
      />

      <div v-if="isLoaded && tripInGtfs && stopTimes && stopTimes.length > 0" class="trip-detailed__main">
        <div class="trip-detailed__center-container">
          <!-- Event feed -->
          <div id="trip-detailed-feed" class="trip-detailed__left-side">
            <EventFeed
              ref="eventFeed"
              :trip-id="tripId"
              :device-id="deviceId"
              :gtfs-id="gtfsId"
              :is-trip-running="isTripRunning"
              :stop-times="stopTimes"
              :stops="stops"
              :selected-date="selectedDate"
              :trip-updates="trip?.updates"
            />
          </div>

          <!-- Map -->
          <div id="trip-detailed-map" class="trip-detailed__right-side">
            <TripDetailedMap
              v-if="isLoaded"
              :trip="trip"
              :device="mapboxDevice"
              :is-trip-canceled="tripUpStore.isCanceled"
              :is-trip-running="isTripRunning"
              :show-track-display-switch="showTimeline"
              :stop-times="stopTimes"
              :stops="tripUpStore.mapStops"
            />
          </div>
        </div>
      </div>

      <!-- Timeline -->
      <TimelineContainer
        v-if="isLoaded && tripInGtfs"
        ref="timeline"
        v-model:mapbox-device="mapboxDevice"
        class="trip-detailed__timeline"
        :is-trip-running="isTripRunning"
        :selected-date="selectedDate"
        :show-timeline="showTimeline"
        :stop-times="stopTimes"
        :trip-id="trip?.id || ''"
      />

      <div v-if="isLoaded && !tripInGtfs" class="trip-detailed__no-trip">
        {{ $t('noTrip') }}
      </div>

      <!-- Modals -->
      <ModalConfig
        v-if="modalShown === 'config' && trip"
        :trip="trip"
        :date="selectedDate"
        :group-id="group._id"
        @close="
          closeModal(true);
          tripInfoComponent?.updateListFromLocalStorage();
        "
      />
      <ModalMessageNew
        v-if="modalShown === ModalType.MESSAGES"
        :recipients="messageRecipients"
        @close="closeModal"
      />
      <ModalStopInfo
        v-if="modalShown === ModalType.STOP_INFO"
        :date="serviceDate"
        :group-id="group._id"
        :gtfs-id="trip?.gtfs[0].id || ''"
        :trip-id="trip?.id || ''"
        :trip-updates="trip?.updates"
        @close="closeModal"
      />
      <ModalTripComment
        v-if="modalShown === ModalType.COMMENT"
        :comment="getUpdate(UpdateType.COMMENT)"
        :title-name="trip?.formatted_name || ''"
        @close="closeModal"
        @submit="setTripComment"
      >
        <template #extra-input>
          <v-checkbox id="next-days" v-model="applyOnNextDays" color="success" hide-details>
            <template #label>
              <span>
                {{ $t('nextDays') }}
              </span>
            </template>
          </v-checkbox>
        </template>
      </ModalTripComment>
    </template>
  </div>
</template>

<style lang="scss">
.trip-detailed {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: calc(100vh - $navbar-top);
  padding: $view-standard-padding;
  background-color: $canvas;

  &__center-container {
    display: flex;
    flex-grow: 1;
    gap: 10px;
  }

  &__datepicker {
    display: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-left {
    display: flex;
    align-items: center;
  }

  &__header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__info {
    margin-top: 10px;
  }

  &__map {
    position: relative;
    height: 80%;
    margin-top: 5%;
  }

  &__main {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 10px;
  }

  &__no-trip {
    margin-top: 200px;
    text-align: center;
  }

  &__left-side {
    width: 50%;
    height: 40vh;
  }

  &__right-side {
    position: relative;
    width: 50%;
  }

  &__timeline {
    margin-top: 10px;
  }

  &__export-btn {
    display: inline-block;
    margin-inline: 10px;
  }

  .no-click-event {
    pointer-events: none;

    .datepicker {
      pointer-events: initial;
    }
  }

  .error-page {
    margin-right: -12px;
    margin-bottom: -12px;
    background-size: 50%;

    &__subtitle {
      font-size: 20px;
    }

    &__title {
      font-size: 60px;
    }
  }
}
</style>

<i18n locale="fr">
{
  "addComment": "Ajouter un commentaire",
  "addStopInfo": "Ajouter une info sur un arrêt",
  "modifyTrip": "Modifier la course",
  "nextDays": "Appliquer aux jours suivants",
  "noTrip": "Cette course n'a pas lieu à cette date",
  "seeMore": "Voir plus d'options",
  "sendMessage": "Envoyer un message aux appareils",
  "403ErrorMessage": "Vous n'avez pas accès à cette course."
}
</i18n>

<i18n locale="en">
{
  "addComment": "Add a comment",
  "addStopInfo": "Add a stop info",
  "modifyTrip": "Modify the trip",
  "nextDays": "Apply to the next days",
  "noTrip": "Trip not taking place on this date",
  "seeMore": "See more options",
  "sendMessage": "Send a message",
  "403ErrorMessage": "You do not have access to this trip."
}
</i18n>
