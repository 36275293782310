import { ref } from 'vue';
import { useStore } from 'vuex';
import * as Messages from '@/store/messages';
import i18n from '@/i18n';
import type { ApiMessage } from '@/@types/api/message';

export const useMessage = () => {
  const store = useStore();
  const { t } = i18n.global;

  const isLoading = ref<boolean>(false);
  const messageListType = ref<string>(Messages.BoxDirection.INBOX);
  const shownMessages = ref<Array<FormattedMessage>>([]);
  const modalMessageArchiveShown = ref<boolean>(false);
  const multipleSelectedMessages = ref<Array<FormattedMessage>>();

  const modalMessageDetail = ref({
    message: {},
    shown: false,
  });

  const modalMessageNew = ref<NewMessage>({
    recipients: [],
    message: {},
    shown: false,
  });

  /**
   * Thanks to messages, format all data for the user.
   */
  async function formatMessages(messages: Array<ApiMessage>): Promise<Array<FormattedMessage>> {
    const messagesFormatted: Array<FormattedMessage> = [];
    // Collect all messages elements and store them in formattedMessage.
    const formatMessage = async (message: ApiMessage) => {
      if (!message.sender?.client) return;
      const formattedMessage = {} as FormattedMessage;

      const loadSender = async () => {
        // Message Sender
        try {
          const sender = await store.dispatch('messages/setFormattedSender', message);
          formattedMessage.senderName = sender.senderName;
          formattedMessage.senderId = sender.senderId;
        } catch (error) {
          console.warn('-=- messages/setFormattedSender / error: ', error);
        }
      };

      const loadRecipients = async () => {
        // Message recipients
        try {
          formattedMessage.recipients = await store.dispatch('messages/setFormattedRecipients', message);
        } catch (error) {
          console.warn('-=- messages/setFormattedRecipients / error: ', error);
        }
      };

      // Start async tasks
      const promises = [loadSender(), loadRecipients()];

      formattedMessage.id = message.id;
      formattedMessage.tripId = message.sender?.trip?.trip_id;
      // TODO - Store message to re-write in Pinia/ts, type must match for message params in those methods
      formattedMessage.content = Messages.getFormattedDetails(message);
      formattedMessage.tripName = message.sender?.trip?.formatted_trip_name || message.sender?.trip?.trip_id;
      formattedMessage.sendDate = Messages.getSendDate(message);
      formattedMessage.status = Messages.getFormattedStatus(message);
      formattedMessage.urgent = message.urgent || false;
      formattedMessage.isUrgentText = t(`message.urgentStatus.${formattedMessage.urgent}`);
      formattedMessage.statusText = formattedMessage.status
        ? t(`message.status.${formattedMessage.status}`)
        : '-';
      await Promise.all(promises);
      messagesFormatted.push(formattedMessage);
    };

    await Promise.all(
      messages.map(async message => {
        try {
          await formatMessage(message);
        } catch (e) {
          console.error('message formatting failed', e);
        }
      }),
    );

    return messagesFormatted;
  }

  async function getMessages() {
    isLoading.value = true;
    // TODO - implicit type to be removed when store is migrated
    const messages: Array<ApiMessage> = await store.dispatch('messages/getMessages');
    let sentmessages: Array<ApiMessage> = [];
    if (messageListType.value === Messages.BoxDirection.INBOX)
      sentmessages = messages.filter(message => message.sender.client === 'driver');
    else sentmessages = messages.filter(message => message.sender.client !== 'driver');
    shownMessages.value = await formatMessages(sentmessages);
    isLoading.value = false;
  }

  /**
   * Show modalMessageDetail to view a detailed message.
   */
  function showModalMessageDetail(message: FormattedMessage) {
    modalMessageDetail.value.message = message;
    modalMessageDetail.value.shown = true;
  }

  /** Trigger message update from api on sent message
    /*  Wait in order to let the api process send request
     */
  function handleSent() {
    setTimeout(getMessages, 500);
  }

  /**
   * Show modalMessageNew to create a message.
   */
  function showModalMessageNew(recipients: Array<Recipient> = [], message: MessageContent = {}) {
    modalMessageNew.value.recipients = recipients;
    modalMessageNew.value.message = message;
    modalMessageNew.value.shown = true;
  }

  /**
   * Show modalMessageArchive to archive a message.
   */
  function showModalMessageArchive() {
    modalMessageArchiveShown.value = true;
  }

  /**
   * archive messages
   */
  function multiArchive(messages: Array<FormattedMessage>) {
    multipleSelectedMessages.value = messages;
    showModalMessageArchive();
  }

  function closeModalMessageNew() {
    modalMessageNew.value = {
      recipients: [],
      message: {},
      shown: false,
    };
  }

  function submitModalMessageArchive() {
    if (multipleSelectedMessages.value) {
      const checkedIds = new Set([...multipleSelectedMessages.value.map(({ id }) => id)]);
      modalMessageArchiveShown.value = false;
      checkedIds.forEach(id => store.dispatch('messages/archive', id));
    }
    getMessages();
  }

  return {
    isLoading,
    messageListType,
    shownMessages,
    modalMessageArchiveShown,
    multipleSelectedMessages,
    modalMessageDetail,
    modalMessageNew,
    getMessages,
    showModalMessageDetail,
    handleSent,
    showModalMessageNew,
    showModalMessageArchive,
    multiArchive,
    closeModalMessageNew,
    submitModalMessageArchive,
  };
};

export enum RecipientType {
  DEVICE = 'device_id',
  ROUTE = 'route_id',
  TRIP = 'trip_id',
}

export interface Recipient {
  id: string;
  type: RecipientType;
  label?: string;
}

export interface NewMessage {
  recipients: Array<Recipient>;
  message: MessageContent;
  shown: boolean;
}

export interface MessageContent {
  content?: string;
  urgent?: boolean;
}

export interface FormattedMessage {
  id: string;
  tripId?: string;
  content: string;
  tripName?: string;
  sendDate: number;
  status: string | null; // TODO - Check if can be null, based on old store
  urgent: boolean;
  isUrgentText: string;
  statusText: string;
  archived?: boolean;
  senderName: string;
  senderId: string;
  recipients: Array<FormattedRecipient>;
}

export interface FormattedRecipient {
  senderId: string;
  senderName: string;
  statusTitle: string;
  reader: boolean;
  delivered: number | boolean;
}
