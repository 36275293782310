<template>
  <div
    class="info-tag"
    :style="`color: ${color}; background-color:${background}; border: 1px solid ${color}`"
  >
    <font-awesome-icon v-if="icon.length > 0" class="info-tag__icon" :icon="icon" />
    <span class="info-tag__text">{{ text }}</span>
  </div>
</template>

<script>
/** @enum {string} */
const colors = {
  LIGHT_GREEN: '#00B871',
  FLAT_GREEN: '#E6F7F1',
  LIGHT_ORANGE: '#F99C49',
  FLAT_ORANGE: '#FEEBDB',
  DARK_RED: '#A41414',
  LIGHT_RED: '#FBDDDD',
  DARK_GRAY: '#333333',
  LIGHT_GRAY: '#EBEBEB',
};

/** @enum {TagProperties} */
export const TagTypes = {
  SUCCESS: {
    color: colors.LIGHT_GREEN,
    backgroundColor: colors.FLAT_GREEN,
  },
  WARNING: {
    color: colors.LIGHT_ORANGE,
    backgroundColor: colors.FLAT_ORANGE,
  },
  DANGER: {
    color: colors.DARK_RED,
    backgroundColor: colors.LIGHT_RED,
  },
  NEUTRAL: {
    color: colors.DARK_GRAY,
    backgroundColor: colors.LIGHT_GRAY,
  },
};

export default {
  name: 'Tag',

  props: {
    background: {
      type: String,
      default: '#0A4B4D', // $secondary
    },
    color: {
      type: String,
      default: 'white',
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      required: true,
    },
  },
};

/**
 * @typedef {Object} TagProperties
 * @property {string} color
 * @property {string} backgroundColor
 * @property {string} [icon]
 * @property {string} [text]
 * */
</script>

<style lang="scss">
.info-tag {
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: fit-content;
  height: 20px;
  border-radius: 16px;
  font-size: 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-inline: 8px;

  &__icon {
    vertical-align: middle;
    height: 12px;
    margin-right: 4px;
    line-height: 20px;
  }

  &__text {
    vertical-align: middle;
    line-height: 18px;
  }
}
</style>
