<script setup>
import { onMounted, ref } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';
import { users as usersAPI, config as configAPI } from '@/api';
import Btn from '@/components/ui/Btn.vue';
import { useRouter } from 'vue-router';
import { AuthRoute } from '@/libs/routing';
import { buildPasswordResetUrl } from '@/router';

const router = useRouter();

/** @type {import('vue').Ref<String | null>} */
const captcha = ref();
/** @type {import('vue').Ref<String | null>} */
const email = ref();
/** @type {import('vue').Ref<Boolean>} */
const error = ref(false);
/** @type {import('vue').Ref<Boolean>} */
const recaptchaEnabled = ref(true);
/** @type {import('vue').Ref<HTMLElement | null>} */
const recaptcha = ref();
/** @type {import('vue').Ref<String | null>} */
const resetMessage = ref();

/** @param {String} response */
function saveCaptchaAnswer(response) {
  captcha.value = response;
}

onMounted(async () => {
  const environment = await configAPI.getEnvironment();
  recaptchaEnabled.value = environment.recaptcha;
});

/** Send reset password information to the api */
async function submit() {
  const resetUrl = buildPasswordResetUrl();

  try {
    await usersAPI.requestPasswordResetToken(email.value, resetUrl, captcha.value);
    resetMessage.value = 'emailSent';
    error.value = false;
  } catch (e) {
    error.value = true;

    if (e?.response?.status === 400) {
      resetMessage.value = 'badCaptcha';
    } else {
      resetMessage.value = 'emailNotFound';
    }

    if (recaptcha.value) {
      recaptcha.value.reset();
    }
  }
}
</script>

<template>
  <form @submit.prevent="submit">
    <div class="login-reset__form">
      <div class="login-reset__title">{{ $t('login.updatePassword') }}</div>
      <div v-if="resetMessage" class="login-reset__incorrect" :class="{ error: error }">
        <v-icon v-if="resetMessage !== 'emailSent'" size="small">fa:fas fa-exclamation-circle</v-icon>
        {{ $t(`login.${resetMessage}`) }}
      </div>
      <div>
        <div class="form-group__label" for="email">
          {{ $t('login.email') }}
        </div>
        <input id="email" v-model="email" type="email" class="form-group__input" />
      </div>
      <vue-recaptcha
        v-if="recaptchaEnabled"
        ref="recaptcha"
        sitekey="6LdQ8A8TAAAAAN8cnIzvUGe7zv8WRar7KwOyIsOE"
        @expired="saveCaptchaAnswer(null)"
        @verify="saveCaptchaAnswer"
      />
      <Btn type="primary" :disabled="!email || (recaptchaEnabled && !captcha)">
        {{ $t('login.resetLink') }}
      </Btn>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.login-reset {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__incorrect {
    font-weight: $font-weight-semi-bold;
    font-size: 12px;
    text-align: center;

    &.error {
      color: $danger;
    }
  }

  &__title {
    font-weight: $font-weight-semi-bold;
    font-size: 16px;
    text-align: center;
  }
}
</style>
