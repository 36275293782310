<script setup lang="ts">
import { trips } from '@/api';
import DropdownCheckbox, { type CheckboxOption } from '@/components/ui/DropdownCheckbox.vue';
import { dateObjToGtfsFormat, timestampMidnight } from '@/libs/helpers/dates';

import EventFeedRow from './EventFeedRow.vue';
import EventFeedTripUpdate from './EventFeedTripUpdate.vue';
import { FeedEventType, TRIP_UPDATE_ORDER } from './EventFeedShared.js';
import cloneDeep from 'clone-deep';
import { computed, nextTick, onBeforeMount, onBeforeUnmount, ref, watch, type PropType } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import type { Stop, StopTime } from '@/@types/gtfs';
import { FstType, UpdateType, type DisplayedTripUpdates, type TripUpdates } from '@/store-pinia/trip-updates';
import type { ApiSender } from '@/@types/api/message';
import { clearEmptyValues } from '@/libs/helpers/objects';

enum FeedType {
  STOP_TIMES = 'stop-times',
  MESSAGE = 'message',
}

const store = useStore();
const { t } = useI18n();

const props = defineProps({
  deviceId: {
    required: false,
    type: String,
    default: null,
  },

  gtfsId: {
    required: true,
    type: String,
  },

  selectedDate: {
    required: true,
    type: Date,
  },

  stopTimes: {
    default: () => [],
    type: Array as PropType<Array<StopTime>>,
  },

  stops: {
    default: () => {},
    type: Object as PropType<{ [stopId: string]: Stop }>,
  },

  tripId: {
    required: true,
    type: String,
  },

  tripUpdates: {
    default: () => {},
    type: Object as PropType<TripUpdates>,
  },

  isTripRunning: {
    default: false,
    type: Boolean,
  },
});

const eventList = ref<Array<TripEvent>>([]);
const feedOptions = ref<Array<CheckboxOption>>([]);
const updateEventFeed = ref<NodeJS.Timer | null>(null);

const group = computed<import('@/store').Group>(() => store.getters.group);
const eventFeedRows = computed<Array<EventFeedItem>>(() => {
  let firstStop = null;
  let lastStop = null;

  if (props.stopTimes.length) {
    firstStop = props.stopTimes[0];
    lastStop = props.stopTimes[props.stopTimes.length - 1];
  }
  let eventFeedRows = props.stopTimes.reduce((acc: EventFeedItem[], stopTime) => {
    const eventFeedRow = {} as EventFeedItem;

    // Stop Id
    eventFeedRow.stopId = stopTime.stop_id;

    // Stop name
    const stop = props.stops[stopTime.stop_id];
    if (stop) {
      eventFeedRow.stopName = stop.stop_name;
    }

    // Theoretical time
    eventFeedRow.theoreticalArrivalTime = stopTime.arrival_time + midnight.value;
    eventFeedRow.theoreticalDepartureTime = stopTime.departure_time + midnight.value;

    // Type
    eventFeedRow.type = FeedEventType.ARRIVAL;
    eventFeedRow.time = eventFeedRow.theoreticalArrivalTime;
    eventFeedRow.isTheoreticalOnly = true;

    // Stop sequence
    eventFeedRow.stop_sequence = stopTime.stop_sequence;

    // Has been canceled in a trip modification/trip update
    const canceledStops = props.tripUpdates.skipped_stop_time_seqs;
    if (canceledStops?.includes(eventFeedRow.stop_sequence)) {
      eventFeedRow.hasBeenCanceled = true;
    }

    // Time has been modified in a trip modification/trip update
    // First we take general trip update delay if any
    let modifiedTimeOffset = props.tripUpdates.delay || 0;
    // Then we had specific trip deviation delay if any
    const modifiedTimes = props.tripUpdates.delays;

    const tripUpdateModifiedTimeItem = modifiedTimes
      ? Object.values(modifiedTimes).find(stop => stop.stop_sequence === eventFeedRow.stop_sequence)
      : null;
    if (tripUpdateModifiedTimeItem) {
      modifiedTimeOffset += tripUpdateModifiedTimeItem.delay;
    }
    eventFeedRow.modifiedTimeOffset = modifiedTimeOffset;

    if (isOptionSelected(FeedEventType.ARRIVAL) || lastStop?.stop_id === stopTime.stop_id) {
      // Push arrival first (we dont push it if case "firstStop" since we don't want "arrival" in this case)
      if (firstStop !== stopTime) {
        acc.push(eventFeedRow);
      }
    }

    // Then push a copy for departure
    const eventFeedRowClone = { ...eventFeedRow };
    eventFeedRowClone.time = eventFeedRowClone.theoreticalDepartureTime;
    eventFeedRowClone.type = FeedEventType.DEPARTURE;
    eventFeedRowClone.isTheoreticalOnly = true;
    // push departure (we dont push it if case "lastStop" since we don't want "departure" in this case)
    if (lastStop !== stopTime) {
      acc.push(eventFeedRowClone);
    }

    return acc;
  }, []);

  if (eventList.value.length > 0) {
    const stopTimesEvents = eventList.value.filter(
      event =>
        event.feed_type === FeedType.STOP_TIMES &&
        event.trip_id === props.tripId &&
        event.device_id === props.deviceId,
    );
    const messageEvents = eventList.value.filter(event => event.feed_type === FeedType.MESSAGE);

    // Real stop time
    eventFeedRows = eventFeedRows.map(currentFeedRow => {
      const event = stopTimesEvents.find(
        stEvent =>
          currentFeedRow.stop_sequence === stEvent.stop_sequence && currentFeedRow.type === stEvent.event,
      );
      if (event) {
        currentFeedRow.time = event.ts;
        currentFeedRow.isTheoreticalOnly = false;

        // Delay
        if (
          !currentFeedRow.isTheoreticalOnly &&
          currentFeedRow.theoreticalDepartureTime &&
          (currentFeedRow.type === FeedEventType.DEPARTURE ||
            (currentFeedRow.type === FeedEventType.ARRIVAL && lastStop?.stop_id === event.stop_id))
        ) {
          currentFeedRow.delay = currentFeedRow.time - currentFeedRow.theoreticalDepartureTime;
        }
      }
      return currentFeedRow;
    });

    // Messages
    messageEvents.forEach(message => {
      const type = message?.sender?.client === 'op' ? FeedEventType.MESSAGE_SENT : FeedEventType.INBOX;
      if (isOptionSelected(type)) {
        const messageTime = new Date(message.date).getTime() / 1000;
        const index = eventFeedRows.findIndex(row => row.time > messageTime);
        const newRow = {
          time: messageTime,
          type,
          content: message?.subject,
        };
        // TODO rework as any
        eventFeedRows.splice(index, 0, newRow as any as EventFeedItem);
      }
    });
  }
  store.dispatch('tripDetailed/sendEventFeed', eventFeedRows);
  return eventFeedRows;
});

const formattedTripUpdates = computed<DisplayedTripUpdates>(() => {
  let formattedTripUpdates: DisplayedTripUpdates = cloneDeep(props.tripUpdates);
  formattedTripUpdates.displaced_stops = formattedTripUpdates.temporary_stops?.filter(
    ts => ts.type === FstType.SHIFT,
  );
  formattedTripUpdates.temporary_stops = formattedTripUpdates.temporary_stops?.filter(
    ts => ts.type !== FstType.SHIFT,
  );
  formattedTripUpdates = clearEmptyValues(formattedTripUpdates);
  return formattedTripUpdates;
});

const tripUpdateOrderedKeys = computed<Array<UpdateType>>(() => {
  const orderedList = cloneDeep(TRIP_UPDATE_ORDER);
  TRIP_UPDATE_ORDER.forEach(updateKey => {
    if (!(updateKey in formattedTripUpdates.value) || !formattedTripUpdates.value[updateKey]) {
      const index = orderedList.indexOf(updateKey);
      if (index !== -1) orderedList.splice(index, 1);
    }
  });
  return orderedList;
});

const midnight = computed<number>(() => timestampMidnight(props.selectedDate, group.value.tz));
const stopOnFocus = computed<string | null>(() => {
  if (store.state.tripDetailed.focusedType)
    return `${store.state.tripDetailed.focusedStop}_${store.state.tripDetailed.focusedType}`;
  return null;
});

watch(
  feedOptions.value,
  () => {
    localStorage.setItem('tripDetailedPage/optionsSelection', JSON.stringify(feedOptions.value));
  },
  { deep: true },
);

/** Triggered when a stop on the map is clicked */
watch(
  () => stopOnFocus.value,
  () => {
    if (stopOnFocus.value) {
      // Get element line based on stopId
      const element = document.getElementById(`stop_${stopOnFocus.value}`);
      if (!element) return;

      const headerElement = document.getElementById('eventFeedHeader');
      // Get its offset from top, remove headerElement height to have the correct result
      if (headerElement) {
        const topPos = element.offsetTop - headerElement.offsetHeight;
        if (topPos) {
          nextTick(() => {
            // Set to parent topPosition of child
            document.getElementById('eventFeed')?.scrollTo({ top: topPos, behavior: 'smooth' });
            element.classList.add('active');
            setTimeout(() => {
              element.classList.remove('active');
            }, 1100);
          });
        }
      }
    }
  },
);

watch([() => props.tripId, () => props.selectedDate], () => {
  getEventsList();
});

// Real time update on the feed only when the trip status is running
watch(
  () => props.isTripRunning,
  () => {
    if (props.isTripRunning) {
      startRealTimeMode();
    } else {
      if (updateEventFeed.value) clearInterval(updateEventFeed.value);
    }
  },
);

onBeforeMount(() => {
  getEventsList();
  getFeedOptions();

  const lsValue = localStorage.getItem('tripDetailedPage/optionsSelection');
  if (lsValue) {
    let localStorageOptionsSelection: Array<CheckboxOption> = JSON.parse(lsValue);
    // if selected options are saved in localStorage, apply, otherwise show all options
    if (localStorageOptionsSelection) {
      localStorageOptionsSelection = Object.values(localStorageOptionsSelection).map(option => {
        option.label = t(option.value);
        return option;
      });
      feedOptions.value = localStorageOptionsSelection;
    }
  }

  if (props.isTripRunning) {
    startRealTimeMode();
  }
});

onBeforeUnmount(() => {
  if (updateEventFeed.value) clearInterval(updateEventFeed.value);
});

async function getEventsList() {
  const date = dateObjToGtfsFormat(props.selectedDate);
  eventList.value = await trips.getTripEventFeed(group.value._id, props.gtfsId, props.tripId, date);
}

function getFeedOptions() {
  feedOptions.value = Object.values(FeedEventType).reduce((acc: CheckboxOption[], option) => {
    const feedOption = {
      label: t(option),
      value: option,
      selected: true,
    };
    if (feedOption.value !== FeedEventType.DEPARTURE) {
      acc.push(feedOption);
    }
    return acc;
  }, []);
}

function isOptionSelected(option: string): boolean {
  if (feedOptions.value)
    return feedOptions.value.find(feedOption => feedOption.value === option)?.selected ?? false;
  return false;
}

/** Get event list every 5 seconds when trip status is running */
function startRealTimeMode() {
  updateEventFeed.value = setInterval(() => {
    getEventsList();
  }, 5000);
}

function onRowClick(row: EventFeedItem) {
  store.dispatch('tripDetailed/setFocusedByFeed', row);
}

interface TripEvent {
  date: Date;
  device_id: string;
  event: string;
  feed_type: FeedType;
  gtfs_id: string;
  id: string;
  start_date: string;
  stop_id: string;
  stop_sequence: number;
  trip_id: string;
  ts: number;
  delay: number;
  subject?: string;
  sender?: ApiSender;
}

export interface EventFeedItem {
  stopId: string;
  stopName: string;
  theoreticalArrivalTime: number;
  theoreticalDepartureTime: number;
  stop_sequence: number;
  time: number;
  delay: number;
  type: FeedEventType;
  content: Object;
  isTheoreticalOnly: boolean;
  hasBeenCanceled: boolean;
  modifiedTimeOffset: number;
}
</script>

<template>
  <div class="event-feed">
    <DropdownCheckbox v-model="feedOptions" class="event-feed__dropdown" />
    <div id="eventFeed" class="event-feed__main custom-scrollbar">
      <table class="event-feed__table table">
        <thead id="eventFeedHeader" class="event-feed__header table__head">
          <tr>
            <th class="table__head-cell">{{ $t('eventFeed.time') }}</th>
            <th class="table__head-cell">{{ $t('eventFeed.event') }}</th>
            <th class="table__head-cell">{{ $t('eventFeed.theoreticalTime') }}</th>
            <th class="table__head-cell">{{ $t('eventFeed.delay') }}</th>
          </tr>
        </thead>
        <tbody class="event-feed__table-body">
          <EventFeedTripUpdate
            v-for="key in tripUpdateOrderedKeys"
            :key="key"
            :type="key"
            :trip-update="formattedTripUpdates"
            :stop-times="stopTimes"
            :stops="stops"
          />
          <EventFeedRow
            v-for="(row, index) in eventFeedRows"
            :id="`stop_${row.stopId}_${row.type}`"
            :key="index"
            :row="row"
            :selected-date="selectedDate"
            :is-last-of-feed="index === eventFeedRows.length - 1"
            :is-first-of-feed="index === 0"
            @clickOnRow="onRowClick"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.event-feed {
  height: 100%;
  border: 1px solid $border;
  border-radius: 8px;
  background-color: $canvas;

  &__dropdown {
    position: relative;
    z-index: $trip-detailed-dropdown-index;
    margin: 5px 0 0 5px;
  }

  &__header {
    align-items: center;
    background-color: $canvas;
    color: $text-neutral;
    box-shadow: -4px 0 10px 0 rgb(0 0 0 / 13%);
    font-size: 12px;
  }

  &__main {
    position: relative;
    overflow-y: auto;
    height: 90%;
  }

  .table {
    margin: 0;

    &__head {
      border: none;
    }

    &__head-cell {
      padding: 5px 8px 8px;
    }
  }
}
</style>

<i18n locale="fr">
  {
    "arrival": "Arrivée",
    "comment": "Commentaire",
    "inbox": "Message reçu",
    "message_sent": "Message envoyé",
    "stop_time_update": "Desserte",
    "delay": "Retard",
    "schedule_relationship": "Annulation",
    "stop_info": "Informations sur les arrêts",
  }
  </i18n>

<i18n locale="en">
  {
    "arrival": "Arrival",
    "comment": "Comment",
    "inbox": "Inbox",
    "message_sent": "Message sent",
    "stop_time_update": "Servicing",
    "delay": "Delay",
    "schedule_relationship": "Cancellation",
    "stop_info": "Stop Info",
  }
  </i18n>
