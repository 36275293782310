<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRouter, type RouteRecordName } from 'vue-router';
import { GroupRoute } from '@/libs/routing';

import Btn from '@/components/ui/Btn.vue';

const { t } = useI18n();
const router = useRouter();

const changed = ref<boolean>(false);

const currentRoute = computed<RouteRecordName | null | undefined>(() => {
  return router.currentRoute.value.name;
});

onBeforeRouteLeave(() => {
  if (
    changed.value &&
    router.currentRoute.value.name !== GroupRoute.SETTINGS_TEAMS &&
    router.currentRoute.value.name !== GroupRoute.SETTINGS_DEPOTS &&
    !window.confirm(t('unsavedChanges'))
  ) {
    return false;
  }
});

function onChangeTab(newTab: GroupRoute) {
  if (
    changed.value &&
    router.currentRoute.value.name !== GroupRoute.SETTINGS_TEAMS &&
    router.currentRoute.value.name !== GroupRoute.SETTINGS_DEPOTS &&
    !window.confirm(t('unsavedChanges'))
  )
    return;

  changed.value = false;
  router.replace({ name: newTab });
}

function updateChangeValue(value: boolean) {
  changed.value = value;
}
</script>

<template>
  <div class="settings">
    <div class="settings__menu">
      <Btn
        v-for="btn in [
          GroupRoute.SETTINGS_GENERAL,
          GroupRoute.SETTINGS_DRIVER,
          GroupRoute.SETTINGS_TEAMS,
          GroupRoute.SETTINGS_PASSENGER_COUNT,
          GroupRoute.SETTINGS_PUNCTUALITY_PARAMS,
          GroupRoute.SETTINGS_STOP_ZONES,
          GroupRoute.SETTINGS_DEPOTS,
          GroupRoute.SETTINGS_ROUTE_MANAGEMENT,
          GroupRoute.SETTINGS_TRIP_MANAGEMENT,
          GroupRoute.SETTINGS_DATA_PERIOD,
        ]"
        :key="btn"
        class="settings__buttons"
        :class="{ active: currentRoute === btn }"
        type="link-style"
        @click="onChangeTab(btn)"
        @mousedown.prevent
      >
        {{ $t(`menu.${btn}`) }}
      </Btn>
    </div>

    <div class="settings__content">
      <router-view @change="updateChangeValue" @save="changed = false" />
    </div>
  </div>
</template>

<style lang="scss">
.settings {
  display: flex;
  margin: 10px 10px 10px 0;
  padding: $view-standard-padding;

  &__content {
    flex: 1;
    margin-left: 16px;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    margin: 5px 5px 5px 0;

    :first-child {
      margin-left: 10px;
    }

    > .ui-btn {
      justify-content: left;
      color: $primary-light;
      font-family: $font-poppins;

      &.active {
        background-color: $primary-light;
        color: $text-light;
        text-decoration: none;
      }
    }
  }

  label.required::after {
    content: '*';
    margin: 0 0.5em;
  }

  .error-msg {
    margin-left: 0.5em;
    color: $danger;
  }
}
</style>

<i18n locale="fr">
{
  "menu": {
    "data-period": "Période des données",
    "general": "Général",
    "driver-app": "Application conducteur",
    "teams": "Équipes",
    "passenger-count": "Comptages",
    "punctuality-params": "Rapport de ponctualité",
    "depots": "Gestion des dépôts",
    "stop-zones": "Distances et zones d'arrêt",
    "routes": "Gestion des lignes",
    "trips": "Gestion des courses"
  },
  "unsavedChanges": "Il y a des changements non sauvegardés.\nVoulez-vous vraiment continuer ?"
}
</i18n>

<i18n locale="en">
{
  "menu": {
    "data-period": "Data period",
    "general": "General",
    "driver-app": "Driver app",
    "teams": "Teams",
    "passenger-count": "Passenger count",
    "punctuality-params": "Punctuality reporting",
    "depots": "Bus depots management",
    "stop-zones": "Distance and stop zones",
    "routes": "Route management",
    "trips": "Trip management"
  },
  "unsavedChanges": "There are unsaved changes.\nDo you really want to leave?"
}
</i18n>
