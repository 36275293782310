<script setup lang="ts">
import deepmerge from 'deepmerge';
import { MapboxHelper } from '@/components/map/mapboxHelper';
import {
  LAYER_BEARING,
  LAYER_COLOR,
  DEVICE_DELAY_COLOR,
  LAYER_ICON,
  LAYER_ROUTE_COLOR,
} from './map-const.js';
import { dateGtfsFormatToObj, getISODate } from '@/libs/helpers/dates';
import { AppUrl } from '@/api';

import { GroupRoute } from '@/libs/routing';
import { computed, onBeforeMount, onUnmounted, ref, watch, type PropType } from 'vue';
import type { DeviceAdditionalDatas, DeviceFiltered } from '@/@types/mapbox.js';
import { useStore } from 'vuex';
import type { Device } from '@/@types/device.js';
import type { Route, Trip } from '@/@types/gtfs.js';
import { useI18n } from 'vue-i18n';

const { t, d } = useI18n();

const store = useStore();

const DEVICES_SOURCE_ID = 'devicesSource';

const DEVICES_LABEL_LAYER_ID = 'devicesLabelLayer ';
const DEVICES_HIGHLIGHT_COLOR_LAYER_ID = 'devicesHighlightColorLayer';
const DEVICES_HIGHLIGHT_ROUTE_COLOR_LAYER_ID = 'devicesHighlightRouteColorLayer';
const DEVICES_HIGHLIGHT_ICON_LAYER_ID = 'devicesHighlightIconLayer';
const DEVICES_HIGHLIGHT_BEARING_LAYER_ID = 'devicesHighlightBearingLayer';
const DEVICES_COLOR_LAYER_ID = 'devicesColorLayer';
const DEVICES_ROUTE_COLOR_LAYER_ID = 'devicesRouteColorLayer';
const DEVICES_ICON_LAYER_ID = 'devicesIconLayer';
const DEVICES_BEARING_LAYER_ID = 'devicesBearingLayer';

export interface ExtendedDevice extends Device {
  additionalDatas: DeviceAdditionalDatas | undefined;
}

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
  },
  devices: {
    type: Array<DeviceFiltered>,
    required: true,
  },
  gtfsId: {
    type: String,
    required: true,
  },
  showLabels: {
    type: Boolean,
    default: false,
  },
  ts: {
    type: Number,
    default: null,
  },
  displayTooltip: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['mouseenter', 'click', 'mouseleave']);

const hoveredId = ref<string | null>(null);
const loaded = ref<boolean>(false);

const timer = ref<{ id: NodeJS.Timeout | null; ts: number }>({
  id: null,
  ts: Date.now() / 1000,
});
const groupId = computed<string>(() => store.getters.group._id);
const routes = computed<{ [routeId: string]: Route }>(() =>
  store.getters['gtfs/getCachedGtfsTable'](props.gtfsId, 'routes'),
);
const trips = computed<{ [tripId: string]: Trip }>(() =>
  store.getters['gtfs/getCachedGtfsTable'](props.gtfsId, 'trips'),
);

const currentTs = computed<number>(() => {
  if (props.ts != null) return props.ts;
  return timer.value.ts;
});

const devicesFiltered = computed<{ [deviceId: string]: ExtendedDevice }>(() => {
  if (!loaded.value) return {};
  const result = props.devices.reduce((acc: { [deviceId: string]: ExtendedDevice }, elem) => {
    acc[elem.id] = {
      ...elem.device,
      additionalDatas: elem.additionalDatas,
    };
    return acc;
  }, {});
  return result;
});

const devicesSource = computed<Array<GeoJSON.Feature<GeoJSON.Point>>>(() => {
  return Object.values(devicesFiltered.value).map(device => {
    const deviceTs = props.ts == null ? (device.ts_system ?? device.ts) : device.ts;
    const isDeviceOnline = deviceTs > currentTs.value - store.getters.group.delay_device_online;
    return /** @type {GeoJSON.Feature<GeoJSON.Point>} */ {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: device.latlng ? [device.latlng[1], device.latlng[0]] : [0, 0],
      },
      properties: {
        bearing: device.bearing,
        description: generateDeviceDescription(device),
        color: getDeviceIconColor(device),
        routeColor: isDeviceOnline ? getDeviceRouteColor(device) : '#FFFFFF',
        highlight: highlightDevices.value[device.device_id],
        id: device.device_id,
        label: getLabel(device),
        opacity: isDeviceOnline ? 1 : 0.5,
      },
    };
  });
});
const highlightDevices = computed<{ [stopId: string]: boolean }>(() => {
  return props.devices.reduce((acc: { [stopId: string]: boolean }, elem) => {
    if (elem.highlight) acc[elem.id] = true;
    return acc;
  }, {});
});

watch(
  () => devicesSource.value,
  () => {
    MapboxHelper.updateSource(props.map, DEVICES_SOURCE_ID, devicesSource.value);
  },
  { deep: true },
);

watch(
  () => props.showLabels,
  () => {
    props.map.setFilter(DEVICES_LABEL_LAYER_ID, [
      'any',
      props.showLabels,
      ['==', ['get', 'id'], hoveredId.value],
    ]);
  },
  { immediate: false },
);

onBeforeMount(() => {
  props.map.once('idle', () => {
    loaded.value = true;
  });

  timer.value.id = setInterval(() => {
    timer.value.ts = Date.now() / 1000;
  }, 1000);
  initSourceAndLayer();
});

onUnmounted(() => {
  if (timer.value.id) clearInterval(timer.value.id);

  MapboxHelper.cleanLayersAndSources(
    props.map,
    [
      DEVICES_LABEL_LAYER_ID,
      DEVICES_HIGHLIGHT_COLOR_LAYER_ID,
      DEVICES_HIGHLIGHT_ROUTE_COLOR_LAYER_ID,
      DEVICES_HIGHLIGHT_ICON_LAYER_ID,
      DEVICES_HIGHLIGHT_BEARING_LAYER_ID,
      DEVICES_COLOR_LAYER_ID,
      DEVICES_ROUTE_COLOR_LAYER_ID,
      DEVICES_ICON_LAYER_ID,
      DEVICES_BEARING_LAYER_ID,
    ],
    [DEVICES_SOURCE_ID],
  );
});

function initSourceAndLayer() {
  MapboxHelper.createEmptySource(props.map, DEVICES_SOURCE_ID);

  addDevicesBearingLayer();
  addDevicesRouteColorLayer();
  addDevicesIconLayer();
  addDevicesColorLayer();
  addDevicesHighlightBearingLayer();
  addDevicesHighlightRouteColorLayer();
  addDevicesHighlightIconLayer();
  addDevicesHighlightColorLayer();
  addDevicesLabelLayer();
}

function addDevicesLabelLayer() {
  MapboxHelper.addLayer(props.map, {
    id: DEVICES_LABEL_LAYER_ID,
    type: 'symbol',
    source: DEVICES_SOURCE_ID,
    filter: ['any', props.showLabels, ['==', ['get', 'id'], hoveredId.value]],

    layout: {
      'text-allow-overlap': false,
      'text-anchor': 'top',
      'text-field': ['get', 'label'],
      'text-justify': 'center',
      'text-offset': [0, -2.6],
      'text-font': ['Poppins Medium'],
      'text-max-width': 100,
    },

    paint: {
      'text-halo-color': '#FBFBFB', // $text-light
      'text-color': '#333333', // $text-dark
      'text-halo-width': 3,
    },
  });
}

function addDevicesBearingLayer() {
  MapboxHelper.addLayer(
    props.map,
    deepmerge(LAYER_BEARING, {
      id: DEVICES_BEARING_LAYER_ID,
      source: DEVICES_SOURCE_ID,
      filter: ['!=', ['get', 'highlight'], true],
    }),
  );
}

function addDevicesRouteColorLayer() {
  MapboxHelper.addLayer(
    props.map,
    deepmerge(LAYER_ROUTE_COLOR, {
      id: DEVICES_ROUTE_COLOR_LAYER_ID,
      source: DEVICES_SOURCE_ID,
      filter: ['all', ['!=', ['get', 'highlight'], true], ['!=', ['get', 'routeColor'], null]],
    }),
  );
}

function addDevicesColorLayer() {
  MapboxHelper.addLayer(
    props.map,
    deepmerge(LAYER_COLOR, {
      id: DEVICES_COLOR_LAYER_ID,
      source: DEVICES_SOURCE_ID,
      filter: ['all', ['!=', ['get', 'highlight'], true], ['!=', ['get', 'color'], null]],
    }),
  );
}

function addDevicesHighlightBearingLayer() {
  MapboxHelper.addLayer(
    props.map,
    deepmerge(LAYER_BEARING, {
      id: DEVICES_HIGHLIGHT_BEARING_LAYER_ID,
      source: DEVICES_SOURCE_ID,
      filter: ['==', ['get', 'highlight'], true],
      layout: {
        'icon-size': 0.85,
      },
    }),
  );
}

function addDevicesHighlightColorLayer() {
  MapboxHelper.addLayer(
    props.map,
    deepmerge(
      LAYER_COLOR,
      {
        id: DEVICES_HIGHLIGHT_COLOR_LAYER_ID,
        source: DEVICES_SOURCE_ID,
        filter: ['all', ['==', ['get', 'highlight'], true], ['!=', ['get', 'color'], null]],

        paint: {
          'circle-radius': 8,
          'circle-translate': [15, -15],
        },
      },
      { arrayMerge: (_, newArr) => newArr },
    ),
  );
}

function addDevicesHighlightRouteColorLayer() {
  MapboxHelper.addLayer(
    props.map,
    deepmerge(
      LAYER_ROUTE_COLOR,
      {
        id: DEVICES_HIGHLIGHT_ROUTE_COLOR_LAYER_ID,
        source: DEVICES_SOURCE_ID,
        filter: ['all', ['==', ['get', 'highlight'], true], ['!=', ['get', 'routeColor'], null]],

        paint: {
          'circle-radius': 18,
        },
      },
      { arrayMerge: (_, newArr) => newArr },
    ),
  );
}

function addDevicesHighlightIconLayer() {
  MapboxHelper.addLayer(
    props.map,
    deepmerge(LAYER_ICON, {
      id: DEVICES_HIGHLIGHT_ICON_LAYER_ID,
      source: DEVICES_SOURCE_ID,
      filter: ['==', ['get', 'highlight'], true],

      layout: {
        'icon-size': 0.4,
      },
    }),
  );
  addLayerActions(DEVICES_HIGHLIGHT_ICON_LAYER_ID);
}

function addDevicesIconLayer() {
  MapboxHelper.addLayer(
    props.map,
    deepmerge(LAYER_ICON, {
      id: DEVICES_ICON_LAYER_ID,
      source: DEVICES_SOURCE_ID,
      filter: ['!=', ['get', 'highlight'], true],
    }),
  );
  addLayerActions(DEVICES_ICON_LAYER_ID);
}

function addLayerActions(layerName: string) {
  props.map.on('mouseenter', layerName, e => {
    if (e.features && e.features.length > 0) {
      emit('mouseenter', e);
    }
  });
  props.map.on('mouseleave', layerName, e => {
    onMouseLeave(e);
  });
  props.map.on('mousemove', layerName, e => {
    if (e.features && e.features.length > 0) {
      onMouseMove(e);
    }
  });
  props.map.on('click', layerName, e => {
    if (e.features && e.features.length > 0) {
      emit('click', e);
      if (props.displayTooltip) {
        MapboxHelper.displayTooltip(e, props.map, 'tooltip-map');
      }
    }
  });
}

function getDeviceIconColor(device: Device) {
  if (device.delay != null) {
    return `#${DEVICE_DELAY_COLOR[store.getters['devices/getDelayState'](device.delay)]}`;
  }
  return null;
}

function getDeviceRouteColor(device: Device) {
  if (device.route_id != null) {
    const route = routes.value[device.route_id];
    if (route && route.route_color) {
      return `#${route.route_color}`;
    }
  }
  return '#FFFFFF';
}

function getLabel(device: Device) {
  let value = store.state.devices.mapLabelFormat;
  if (!value) {
    value = '%th';
  }

  // %th : trip headsign
  let th = '-';
  const trip = device.trip_id ? trips.value[device.trip_id] : null;
  if (trip) {
    th = trip.trip_headsign || '-';
  }
  value = value.replace(/%th/g, th);

  // %dn : device name
  value = value.replace(/%dn/g, device.name || '-');

  // %rsn : route short name
  let rsn = '-';
  const route = device.route_id ? routes.value[device.route_id] : null;
  if (route) {
    rsn = route.route_short_name || '-';
  }
  value = value.replace(/%rsn/g, rsn);

  // %tsn : trip short name
  let tsn = '-';
  if (trip) {
    tsn = trip.trip_short_name || '-';
  }
  value = value.replace(/%tsn/g, tsn);

  // %lts : last timestamp
  value = value.replace(/%lts/g, device.ts != null ? d(device.ts * 1000, 'timeShort') : '-');

  // %s : speed
  value = value.replace(/%s/g, device.speed != null ? Math.floor((device.speed * 36) / 10) : '-');

  // %ll : GPS coords
  const [lat, lng] = device.latlng || [0, 0];
  // eslint-disable-next-line no-irregular-whitespace
  value = value.replace(/%ll/g, `${t('lat')} : ${lat}\n${t('lng')} : ${lng}`);

  // %d : delay
  const sign = device.delay && device.delay >= 0 ? '+' : '-';
  const min = device.delay != null ? Math.floor(Math.abs(device.delay) / 60) : 0;
  let delay = t('delayMn', [sign + min]);
  if (min === 0) delay = '';

  value = value.replace(/%d/g, delay);

  return value;
}

function onMouseLeave(event: mapboxgl.MapLayerMouseEvent) {
  emit('mouseleave', event);
  hoveredId.value = null;
  setDeviceLabelFilterUpdate();
}

function onMouseMove(event: mapboxgl.MapLayerMouseEvent) {
  event.originalEvent.cancelBubble = true;

  if (event.features && event.features.length > 0) {
    const deviceId = event.features[0]?.properties?.id;

    if (hoveredId.value !== deviceId) {
      hoveredId.value = deviceId;
    }
    setDeviceLabelFilterUpdate();
  }
}

function setDeviceLabelFilterUpdate() {
  props.map.setFilter(DEVICES_LABEL_LAYER_ID, [
    'any',
    props.showLabels,
    ['==', ['get', 'id'], hoveredId.value],
  ]);
}

/**
 * Generate device tooltip HTML based on devices informations
 */
function generateDeviceDescription(device: ExtendedDevice) {
  const data = device?.additionalDatas;
  if (data) {
    let description = ``;
    // Driver & vehicle
    if (data?.driver || data?.vehicle) {
      description += `<div class="tooltip-map__title-box no-radius">`;
    }
    if (data?.driver) description += `<div>${data.driver}</div>`;
    if (data?.vehicle) description += `<div>${data.vehicle}</div>`;
    if (data?.driver || data?.vehicle) {
      description += `</div>`;
    }
    // Route badge & formattedTripName
    if (data.route) {
      description += `<div class="tooltip-map__trip"><span class="route-badge" style="background-color: #${
        data.route.route_color || 'FFFFFF'
      };color: #${data.route.route_text_color || '000000'}">${
        data.route.route_short_name
      }  </span><span class="tooltip-map__trip__text">${data.formattedTripName}</span></div>`;
    }
    // CTA button
    if (device?.trip_id) {
      const tooltipCtaUrl = generateTripDetailUrl(device);
      description += `<a class="tooltip-map__button" href="${tooltipCtaUrl}">${t(
        'detail',
      )} <i class="fas fa-arrow-right ui-btn__redirect-icon" /></a>`;
    }
    if (description.length > 0) return description;
  }
  return null;
}

/**
 * generate trip detail URL based on device since we can't use vue rooter in generated html
 */
function generateTripDetailUrl(device: ExtendedDevice) {
  const startDateObject = device?.trip?.start_date ? dateGtfsFormatToObj(device.trip.start_date) : null;
  const tripStartDate = startDateObject ? getISODate(startDateObject) : null;
  let tooltipCtaUrl = `${AppUrl}/#/${groupId.value}/${GroupRoute.TRIP_DETAILED}/${device.trip_id}`;
  if (tripStartDate) tooltipCtaUrl += `?date=${tripStartDate}`;
  return tooltipCtaUrl;
}
</script>

<template>
  <div class="mapbox-devices"></div>
</template>

<i18n locale="fr">
{
  "delayMn": "{0} min",
  "lat": "Latitude",
  "lng": "Longitude"
}
</i18n>

<i18n locale="en">
{
  "delayMn": "{0} min",
  "lat": "Latitude",
  "lng": "Longitude"
}
</i18n>

<i18n locale="cz">
{
  "lat": "Šířka",
  "lng": "Délka"
}
</i18n>

<i18n locale="de">
{
  "lat": "Breitengrad",
  "lng": "Längengrad"
}
</i18n>

<i18n locale="es">
{
  "lat": "Latitud",
  "lng": "Longitud"
}
</i18n>

<i18n locale="it">
{
  "lat": "Latitudine",
  "lng": "Longitudine"
}
</i18n>

<i18n locale="pl">
{
  "lat": "Szer. geogr.",
  "lng": "Dł. geogr."
}
</i18n>

<style lang="scss">
.tooltip-map {
  &__trip {
    display: flex;
    flex-direction: row;
    gap: 10px;

    &__text {
      line-height: 26px;
    }

    .route-badge {
      height: 26px;
    }
  }
}
</style>
