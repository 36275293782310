<script setup lang="ts">
import { ref, watch, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { POSITION, useToast } from 'vue-toastification';
import { useStore } from 'vuex';

import { urgencies as ApiUrgencies } from '@/api';
import { Permission } from '@/auth';
import NotificationRequest from '@/components/common/NotificationRequest.vue';
import ToastWithBtn from '@/components/common/ToastWithBtn.vue';
import ModalUrgency from '@/components/common/Urgencies/ModalUrgency.vue';
import NavBarLeft from '@/components/layout/NavBarLeft.vue';
import NavBarTop from '@/components/layout/NavBarTop/index.vue';
import { GroupRoute } from '@/libs/routing';
import { Feature } from '@/features';
import type { Urgency } from '@/@types/api/urgency';

const router = useRouter();
const store = useStore();
const toast = useToast();
const { t } = useI18n();

const props = defineProps({
  groupId: {
    required: true,
    type: String,
  },
});

// #region Urgencies
// Track the first time urgencies are loaded to avoid displaying the toaster
const firstUrgencyLoad = ref<boolean>(true);
const urgenciesModalTimeout = ref<boolean>(false);

const newUrgencies = computed<Array<Urgency>>(() => {
  return store.getters['urgencies/getNewUrgencies'];
});

const acknowledgedUrgencies = computed<Array<Urgency>>(() => {
  return store.getters['urgencies/getAcknowledgedUrgencies'];
});

const acknowledgedUrgenciesIds = computed<Array<String>>(() => {
  return acknowledgedUrgencies.value.map(u => u.urgency_id);
});

const displayUrgenciesModal = computed<boolean>(() => {
  return (
    newUrgencies.value.length > 0 &&
    store.getters.hasPermission(Permission.HANDLE_URGENCIES) &&
    router.currentRoute.value.name !== GroupRoute.URGENCIES &&
    urgenciesModalTimeout.value === true
  );
});

async function acknowledgeUrgencies() {
  urgenciesModalTimeout.value = false;
  const newUrgenciesCopy: Array<Urgency> = [...newUrgencies.value];
  await Promise.all(
    newUrgenciesCopy.map((urgency: Urgency) => {
      if (acknowledgedUrgenciesIds.value.includes(urgency.urgency_id)) return;
      return ApiUrgencies.acknowledge(props.groupId, urgency.urgency_id);
    }),
  );
  if (router.currentRoute.value.name !== GroupRoute.URGENCIES) router.push({ name: GroupRoute.URGENCIES });
}

watch(newUrgencies, newList => {
  if (newList.length > 0) {
    if (router.currentRoute.value.name === GroupRoute.URGENCIES) {
      // If the user is on the urgencies page and there are new urgencies, acknowledge them instantly
      acknowledgeUrgencies();
    } else {
      // Timeout to avoid ephemeral apparition of the modal if some other user is already on the urgencies page
      setTimeout(() => {
        const newUrgenciesAfterTimeout = store.getters['urgencies/getNewUrgencies'];
        if (newUrgenciesAfterTimeout.length > 0) {
          urgenciesModalTimeout.value = true;
        }
      }, 1000);
    }
  }
});

// Watch acknowledgedUrgencies to display toast "X new alerts are beeing treated"
watch(acknowledgedUrgencies, (newAcknowledgedUrgencies, initialAcknowledgedUrgencies) => {
  // Display toast only when a new urgency is added and not on first load
  if (!firstUrgencyLoad.value && newAcknowledgedUrgencies.length > initialAcknowledgedUrgencies.length) {
    const toastId = toast.warning(
      {
        component: ToastWithBtn,
        props: {
          btnText: t('see').toUpperCase(),
          icon: 'fas fa-exclamation-circle',
          text: t('urgenciesInTreatment', { count: newAcknowledgedUrgencies.length }),
          btnType: 'warn',
        },
        listeners: {
          btnClicked: () => {
            if (router.currentRoute.value.name !== GroupRoute.URGENCIES)
              router.push({ name: GroupRoute.URGENCIES });
          },
        },
      },
      {
        position: POSITION.BOTTOM_RIGHT,
      },
    );
    setTimeout(() => toast.dismiss(toastId), 5000);
  }
  firstUrgencyLoad.value = false;
});
// #endregion

const showNotificationRequestWindow = ref<boolean>(false);

onMounted(() => {
  if (store.getters.hasFeature(Feature.URGENCIES)) {
    // Check if browser supports Notification
    if ('Notification' in window) {
      if (Notification.permission === 'default') {
        showNotificationRequestWindow.value = true;
      }
    }
  }
});

function closeNotificationRequestWindow() {
  showNotificationRequestWindow.value = false;
}
</script>

<template>
  <div class="layout">
    <NavBarLeft />
    <div class="layout__right">
      <NavBarTop />
      <div class="layout__bottom">
        <router-view v-if="store.getters.group?._id" />
        <ModalUrgency
          v-if="displayUrgenciesModal"
          :urgencies="newUrgencies"
          @handleAlerts="acknowledgeUrgencies"
          @close="urgenciesModalTimeout = false"
        />
        <Transition name="fade">
          <NotificationRequest v-if="showNotificationRequestWindow" @close="closeNotificationRequestWindow" />
        </Transition>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/**
 * layout
 *   __right
 *   __bottom
 */
.layout {
  display: flex;
  height: 100%;

  &__right {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    height: 100%;
  }

  &__bottom {
    flex: 1;
    overflow: auto;

    & > div:first-child {
      max-height: calc(100vh - $navbar-top);
    }
  }
}
</style>

<i18n locale="fr">
  {
    "urgenciesInTreatment": "{count} nouvelle alerte en cours de traitement | {count} nouvelles alertes en cours de traitement",
  }
</i18n>

<i18n locale="en">
  {
    "urgenciesInTreatment": "{count} new alert are being treated | {count} new alerts are being treated",
  }
</i18n>
