<script setup lang="ts">
import { computed, type PropType } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { GroupRoute } from '@/libs/routing';

import { type FormattedSubmenuItem } from './NavBarLeft.vue';

const route = useRoute();
const store = useStore();

const props = defineProps({
  heightOffset: {
    type: Number,
    default: () => 0,
  },
  items: {
    type: Array as PropType<FormattedSubmenuItem[]>,
    default: () => [],
  },
  leftOffset: {
    type: String,
    default: () => '200px',
  },
});

// The submenu is in position absolute relatively to the whole menu
// Here we calculate the top offset
const localHeightOffset = computed(() => {
  if (props.heightOffset + componentHeight.value >= window.innerHeight) {
    const itemHeight = 35;
    return props.heightOffset - componentHeight.value + itemHeight + 'px';
  }
  return props.heightOffset + 'px';
});

// Get the submenu component own height
const componentHeight = computed(() => {
  const itemHeight = 30;
  const verticalPadding = 10;
  return props.items.length * itemHeight + verticalPadding;
});
</script>

<template>
  <div class="subnav">
    <div class="subnav__wrapper">
      <router-link
        v-for="(item, index) in items"
        :key="index"
        :to="item.route"
        :class="{
          subnav__item: true,
          'subnav__item--active':
            item.route.name === 'reporting'
              ? item.route.query?.metric === route.query.metric
              : item.route.name === route.name,
        }"
        :title="item.translation"
        @click="(e: MouseEvent) => e.stopPropagation()"
      >
        <div class="subnav__item-content">
          <span
            v-if="
              item.route.name === GroupRoute.GTFS_RT ||
              item.route.name === GroupRoute.PASSENGERS_APP ||
              item.route.name === GroupRoute.PASSENGERS_SCREEN
            "
            class="round-dot"
            :class="{
              'round-dot--inactive':
                (!store.state.groupServerSide.pub && item.route.name === GroupRoute.PASSENGERS_APP) ||
                (!store.state.groupServerSide.pub_screen && item.route.name === GroupRoute.PASSENGERS_SCREEN),
            }"
          />
          {{ item.translation }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss">
.subnav {
  position: absolute;
  top: v-bind(localHeightOffset);
  left: v-bind(leftOffset);
  z-index: $navbar-submenu;
  width: 210px;
  background-color: transparent;

  &__item {
    display: block;
    padding: 0 12px;
    color: $text-dark-variant;
    font-weight: $font-weight-semi-bold;
    font-size: 12px;
    line-height: 2.5;

    &:hover {
      background-color: $transparent-dark-variant;
      color: $text-dark;
      text-decoration: none;
      transition: background-color 150ms ease-out;
    }

    &--active {
      background-color: $transparent-primary;
      color: $primary-light;
      text-decoration: none;
      transition: background-color 150ms ease-out;
    }

    .round-dot {
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-right: 8px;
      border-radius: 50%;
      background-color: $primary-light;

      &--inactive {
        background-color: $danger;
      }
    }
  }

  &__item-content {
    display: flex;
    align-items: center;
  }

  &__wrapper {
    margin-left: 20px;
    padding: 5px 0;
    border-radius: 4px;
    background-color: $background;
    box-shadow: 0 4px 40px rgb(0 0 0 / 25%);
  }
}
</style>
