// Port of @pysae-driver/core request function with error handling.

import { version } from '@/version';

export class InvalidResponseError<T = unknown> extends Error {
  constructor(
    message: string,
    public readonly data: T,
    public readonly response: Response,
  ) {
    super(message);
  }
}

export class PysaeApiErrorContent {
  constructor(
    public readonly code: string,
    public readonly payload: object,
  ) {}
}

export class PysaeApiError extends InvalidResponseError {}

export async function request(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  const options = init ?? {};
  if (!options.headers) {
    options.headers = { 'Api-User-Agent': `Op/${version}` };
  } else {
    options.headers = { ...options.headers, ...{ 'Api-User-Agent': `Op/${version}` } };
  }

  const response = await fetch(input, options);

  if (!response.ok) {
    let data: unknown;
    try {
      data = await response.json();
    } catch {
      // do nothing
    }

    let method = 'GET';
    let url = '';
    if (input instanceof Request) {
      method = input.method;
      url = input.url;
    } else if (input instanceof URL) {
      url = input.toString();
    } else {
      url = input;
    }

    const pysaeContentCandidate = data as Partial<PysaeApiErrorContent> | undefined | null;
    if (pysaeContentCandidate && pysaeContentCandidate.code && pysaeContentCandidate.payload) {
      throw new PysaeApiError(
        `Error status for ${method} ${url} (${response.status})`,
        new PysaeApiErrorContent(pysaeContentCandidate.code, pysaeContentCandidate.payload),
        response,
      );
    }

    throw new InvalidResponseError(`Error status for ${method} ${url} (${response.status})`, data, response);
  }

  return response;
}
