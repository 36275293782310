<script setup lang="ts">
import mapboxgl from 'mapbox-gl';
import { computed, onMounted, onUnmounted, type PropType, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { Permission } from '@/auth';
import { AppUrl } from '@/api';
import { createGeoJSONCircle } from '@/libs/helpers/geo';
import { GroupRoute } from '@/libs/routing';
import type { MapDepot } from '@/store-pinia/depots';
import {
  DEPOTS_ICON_LAYER_ID,
  DEPOTS_ICON_TEXT_LAYER_ID,
  DEPOTS_SOURCE_ID,
  DEPOTS_ZONE_LAYER_ID,
  DEPOTS_ZONE_SOURCE_ID,
} from '@/@types/mapbox';

import { INTERPOLATE } from './map-const.js';
import { MapboxHelper } from './mapboxHelper';

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
  },
  depots: {
    type: Array as PropType<Array<MapDepot>>,
    required: true,
  },
  editing: {
    type: Boolean,
    required: false,
    default: false,
  },
  displayTooltip: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const store = useStore();

const emit = defineEmits(['isLoaded', 'mouseleave']);

defineExpose({ resetHasDepotBeenClicked });

// Keep depot tooltip open until click outside or inside
const hasDepotBeenClicked = ref<boolean>(false);

const groupId = computed(() => store.getters.group._id);

const depotSource = computed<Array<GeoJSON.Feature<GeoJSON.Point>>>(() => {
  return props.depots.map(depot => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [depot.longitude || 0, depot.latitude || 0],
    },
    properties: {
      id: depot.id,
      label: depot.name,
      description: generateDepotDescription(depot, false),
      shortDescription: generateDepotDescription(depot, true),
    },
  }));
});

const depotZoneSource = computed<Array<GeoJSON.Feature<GeoJSON.Polygon>>>(() => {
  return props.depots.map(depot => ({
    type: 'Feature',
    geometry: createGeoJSONCircle([depot.longitude || 0, depot.latitude || 0], depot.radius),
    properties: {
      id: depot.id,
    },
  }));
});

watch(
  () => depotSource,
  () => {
    MapboxHelper.updateSource(props.map, DEPOTS_SOURCE_ID, depotSource.value);
  },
  { deep: true, immediate: true },
);

watch(
  () => depotZoneSource,
  () => {
    MapboxHelper.updateSource(props.map, DEPOTS_ZONE_SOURCE_ID, depotZoneSource.value);
  },
  { deep: true, immediate: true },
);

watch(
  () => props.editing,
  editing => {
    if (editing) {
      props.map.getCanvas().style.cursor = 'crosshair';
    } else {
      props.map.getCanvas().style.cursor = '';
    }
  },
);

onMounted(() => {
  initSourceAndLayer();
  initMapActions();
  emit('isLoaded', true);
});

onUnmounted(() => {
  MapboxHelper.cleanLayersAndSources(
    props.map,
    [DEPOTS_ZONE_LAYER_ID, DEPOTS_ICON_LAYER_ID, DEPOTS_ICON_TEXT_LAYER_ID],
    [DEPOTS_SOURCE_ID, DEPOTS_ZONE_SOURCE_ID],
  );
});

function initSourceAndLayer() {
  MapboxHelper.createEmptySource(props.map, DEPOTS_SOURCE_ID);
  MapboxHelper.createEmptySource(props.map, DEPOTS_ZONE_SOURCE_ID);

  MapboxHelper.addLayer(props.map, {
    id: DEPOTS_ZONE_LAYER_ID,
    type: 'fill',
    source: DEPOTS_ZONE_SOURCE_ID,
    paint: {
      'fill-color': '#ff0000',
      'fill-opacity': 0.35,
    },
  });

  MapboxHelper.addLayer(props.map, {
    id: DEPOTS_ICON_LAYER_ID,
    type: 'circle',
    source: DEPOTS_SOURCE_ID,
    paint: {
      'circle-radius': ['interpolate', ['linear'], ['zoom'], ...INTERPOLATE.depots.circleRadius],
      'circle-color': '#f5f5f5', // $background
      'circle-stroke-width': 1,
      'circle-stroke-color': '#333', // $text-dark
    },
  });

  MapboxHelper.addLayer(props.map, {
    id: DEPOTS_ICON_TEXT_LAYER_ID,
    type: 'symbol',
    source: DEPOTS_SOURCE_ID,
    layout: {
      'text-allow-overlap': true,
      'text-anchor': 'center',
      'text-field': 'D',
      'text-size': 8,
      'text-font': ['Poppins Bold'],
    },
  });
}

function initMapActions() {
  props.map.on('mouseleave', DEPOTS_ICON_LAYER_ID, e => {
    onDepotMouseLeave();
  });
  props.map.on('mousemove', DEPOTS_ICON_LAYER_ID, e => {
    onDepotMouseMove(e);
  });
  props.map.on('click', DEPOTS_ICON_LAYER_ID, e => {
    if (e.features && e.features.length > 0) {
      hasDepotBeenClicked.value = true;
      if (props.displayTooltip) {
        MapboxHelper.displayTooltip(e, props.map, 'tooltip-map');
      }
    }
  });
}

function onDepotMouseLeave() {
  emit('mouseleave');
  // We close the tooltip on mouseLeave only if no depot have been clicked
  if (!hasDepotBeenClicked.value) {
    MapboxHelper.removeAllTooltips(props.map);
  }
}

function onDepotMouseMove(event: mapboxgl.MapLayerMouseEvent) {
  if (event?.features && event.features.length > 0 && !hasDepotBeenClicked.value) {
    MapboxHelper.displayTooltip(event, props.map, 'tooltip-map');
  }
}

/**
 * Generate depot tooltip HTML based on depot informations
 */
function generateDepotDescription(depot: MapDepot, short: boolean | null) {
  const tooltipCtaUrl = `${AppUrl}/#/${groupId.value}/${GroupRoute.SETTINGS}/${GroupRoute.SETTINGS_DEPOTS}`;

  // Depot name
  let description = `<div class="tooltip-map__title-box">${t('depot')}${depot.name}</div>`;

  if (!short && store.getters.hasPermission(Permission.VIEW_SETTINGS_BUTTON)) {
    description = `<div class="tooltip-map__title-box no-radius">${t('depot')}${depot.name}</div>`;
    // CTA button
    description += `<a class="tooltip-map__button" href="${tooltipCtaUrl}"> <i class="fas fa-eye"></i>
    ${t('detail')}</a>`;
  }

  return description;
}

function resetHasDepotBeenClicked() {
  hasDepotBeenClicked.value = false;
}
</script>

<template>
  <div class="mapbox-depots"></div>
</template>

<i18n locale="fr">
  {
    "depot": "Dépôt : "
  }
  </i18n>

<i18n locale="en">
  {
    "depot": "Depot: "
  }
  </i18n>
