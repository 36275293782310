import {
  SHAPES_ARROW_LAYER_ID,
  SHAPES_BG_LAYER_ID,
  SHAPES_FG_LAYER_ID,
  STOPS_ZONE_LAYER_ID,
  SHAPES_DASH_ARRAY_LAYER_ID,
  type LayerOptions,
  SHAPES_HIGHLIGHT_BG_LAYER_ID,
  SHAPES_HIGHLIGHT_FG_LAYER_ID,
} from '@/@types/mapbox';

import mapboxgl from 'mapbox-gl';

export const MapboxHelper = {
  /**
   * Check if already exist, then add an empty source with specific name id
   */
  createEmptySource(map: mapboxgl.Map, sourceId: string) {
    if (!map?.getSource(sourceId))
      map?.addSource(sourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });
  },
  /**
   * Check if already exist, then add a layer with specific name id
   */
  addLayer(map: mapboxgl.Map, layerObject: mapboxgl.Layer) {
    if (!map?.getLayer(layerObject.id)) {
      map?.addLayer(layerObject as mapboxgl.AnyLayer);
    }
  },
  /**
   * Update the feature object of a source item
   */
  updateSource(map: mapboxgl.Map, sourceId: string, features: Array<GeoJSON.Feature>) {
    if (!map?.getSource(sourceId)) {
      this.createEmptySource(map, sourceId);
    }
    const source = map.getSource(sourceId) as mapboxgl.GeoJSONSource;
    source.setData({
      type: 'FeatureCollection',
      features,
    });
  },

  /**
   * Remove layers and source from map object
   */
  cleanLayersAndSources(map: mapboxgl.Map, layersIds: Array<string>, sourcesIds: Array<string>) {
    const onError = () => {};
    map.on('error', onError);
    layersIds.forEach(layerName => {
      if (map.getLayer(layerName)) map.removeLayer(layerName);
    });
    sourcesIds.forEach(sourceName => {
      if (map.getSource(sourceName)) map.removeSource(sourceName);
    });
    map.off('error', onError);
  },

  /**
   * Add an image to map Object
   */
  addImage(map: mapboxgl.Map, imagePath: string, imageName: string) {
    map?.loadImage(imagePath, (error, image) => {
      if (error) console.warn(error);
      map?.addImage(imageName, image as HTMLImageElement);
    });
  },
  /**
   * Save layers to localStorage
   */
  saveLayerOptionstoLS(name: string, object: LayerOptions) {
    localStorage.setItem(name, JSON.stringify(object));
  },

  getLayersOptionsFromLS(name: string): LayerOptions | null {
    const savedItem = localStorage.getItem(name);
    if (savedItem) {
      const storage = JSON.parse(savedItem);
      const layerObject = {} as LayerOptions;
      layerObject.vehicles = true;
      if (storage.vehiclesLabels !== null) layerObject.vehiclesLabels = storage.vehiclesLabels;
      if (storage.stops !== null) layerObject.stops = storage.stops;
      if (storage.stations !== null) layerObject.stations = storage.stations;
      if (storage.traffic !== null) layerObject.traffic = storage.traffic;
      if (storage.linesShapes !== null) layerObject.linesShapes = storage.linesShapes;
      if (storage.stopsZones !== null) layerObject.stopsZones = storage.stopsZones;
      if (Object.keys(layerObject).length === 0) return null;
      return layerObject;
    }
    return null;
  },

  /**
   * Display a tooltip based on a click event
   */
  displayTooltip(event: mapboxgl.MapLayerMouseEvent, map: mapboxgl.Map, tooltipClassName: string) {
    // remove potential previous tooltip
    MapboxHelper.removeAllTooltips(map);

    const MOUSE_MOVE = 'mousemove';
    const isHoverTooltip = event.type === MOUSE_MOVE;

    if (event.features) {
      // Check if source has a description, otherwise display nothing
      const description = isHoverTooltip
        ? event.features[0]?.properties?.shortDescription
        : event.features[0]?.properties?.description;
      if (description) {
        // Copy coordinates array.
        const geometry = event.features[0].geometry as GeoJSON.Point;
        const coordinates = geometry.coordinates.slice();

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup({
          className: tooltipClassName,
          closeButton: false,
          anchor: 'top',
        })
          .setLngLat(coordinates as mapboxgl.LngLatLike)
          .setHTML(description)
          .addTo(map);
      }
    }
  },

  /**
   * remove potential existing tooltips
   */
  removeAllTooltips(map: mapboxgl.Map & { _popups?: Array<mapboxgl.Popup> }) {
    if (map) {
      // access private _popups since mapbox does not have any getPopups method...
      if (map._popups && map._popups?.length > 0) map._popups.forEach(popup => popup.remove());
    }
  },

  defaultLayerOrderReset(map: mapboxgl.Map) {
    const hasOldShape = map.getStyle().layers?.find(layer => layer.id === SHAPES_DASH_ARRAY_LAYER_ID);
    if (hasOldShape) map.moveLayer(SHAPES_DASH_ARRAY_LAYER_ID, STOPS_ZONE_LAYER_ID);
    if (map.getStyle().layers?.find(layer => layer.id === SHAPES_BG_LAYER_ID)) {
      map.moveLayer(SHAPES_BG_LAYER_ID, STOPS_ZONE_LAYER_ID);
      map.moveLayer(SHAPES_FG_LAYER_ID, STOPS_ZONE_LAYER_ID);
      map.moveLayer(SHAPES_HIGHLIGHT_BG_LAYER_ID, STOPS_ZONE_LAYER_ID);
      map.moveLayer(SHAPES_HIGHLIGHT_FG_LAYER_ID, STOPS_ZONE_LAYER_ID);
      map.moveLayer(SHAPES_ARROW_LAYER_ID, STOPS_ZONE_LAYER_ID);
    }
  },
};
