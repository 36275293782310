<script setup lang="ts">
import { TripStatusType } from '@/api';

import { StateMergingChildren } from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { STATUSES } from '@/pages/TripsListPage/Trips.conf';
import Tag from '@/components/ui/Tag.vue';
import { computed } from 'vue';

enum Problems {
  TIME = 'time',
  DISTANCE = 'distance',
  KILOMETERS = 'kilometers',
}

const props = defineProps({
  hasChildren: {
    type: Boolean,
    default: false,
  },

  problems: {
    type: Object,
    default: () => ({}),
  },

  status: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['toggleChildren']);

const isStatusProblem = computed<boolean>(
  () => props.status === TripStatusType.PROBLEM && Object.values(props.problems).length > 0,
);

function toggleChildren() {
  emit('toggleChildren');
}

function getColor(status: TripStatusType) {
  return STATUSES[status].color;
}

function getBackgroundColor(status: TripStatusType) {
  return STATUSES[status].backgroundColor;
}

function getIcon(status: TripStatusType) {
  return STATUSES[status].icon;
}
</script>

<template>
  <div class="cell-status">
    <div v-if="hasChildren">
      <button class="cell-status__toggle" @click="toggleChildren">
        <font-awesome-icon
          :icon="status === StateMergingChildren.SHOWN ? 'fa-chevron-down' : 'fa-chevron-right'"
        />
      </button>
    </div>

    <template v-else>
      <v-tooltip location="right" :content-class="!isStatusProblem ? 'cell-status__tooltip-hidden' : ''">
        <template #activator="{ props }">
          <Tag
            ref="tag"
            :text="$t(`tripStatus.${STATUSES[status].localeKey}`)"
            :title="!isStatusProblem ? $t(`tripStatus.${STATUSES[status].localeKey}`) : null"
            :icon="getIcon(status)"
            :color="getColor(status)"
            :background="getBackgroundColor(status)"
            v-bind="props"
          />
        </template>
        <div v-if="isStatusProblem">
          <p v-show="problems[Problems.TIME]">
            <font-awesome-icon class="mr-1" icon="fa-triangle-exclamation" />
            {{ $t('trackingTooltip.time') }}
          </p>
          <p v-show="problems[Problems.DISTANCE]">
            <font-awesome-icon class="mr-1" icon="fa-triangle-exclamation" />
            {{ $t('trackingTooltip.distance') }}
          </p>
          <p v-show="problems[Problems.KILOMETERS]">
            <font-awesome-icon class="mr-1" icon="fa-triangle-exclamation" />
            {{ $t('trackingTooltip.vk') }}
          </p>
        </div>
      </v-tooltip>
    </template>
  </div>
</template>

<style lang="scss">
.cell-status {
  position: relative;
  overflow: unset;
  cursor: default;

  &__toggle {
    border: 0;
    background: transparent;
    color: $text-dark-variant;
    font-size: 1.4em;
    cursor: pointer;
  }

  &__tooltip-hidden {
    display: none !important;
  }
}
</style>

<i18n locale="fr">
{
  "trackingTooltip": {
    "time": "Heure de prise de course",
    "distance": "Distance de prise de course",
    "vk": "Kilomètres enregistrés"
  }
}
</i18n>

<i18n locale="en">
{
  "trackingTooltip": {
    "time": "Time trip started",
    "distance": "Trip start distance",
    "vk": "Tracked kilometers"
  }
}
</i18n>

<i18n locale="cz">
{
  "trackingTooltip": {
    "distance": "Vzdálenost jízdy od startu",
    "vk": "Sledované kilometry",
    "time": "Čas začátku jízdy"
  }
}
</i18n>

<i18n locale="de">
{
  "trackingTooltip": {
    "distance": "Entfernung zum Fahrtbeginn",
    "vk": "Kilometer laut Überwachung",
    "time": "Uhrzeit des Fahrtbeginns"
  }
}
</i18n>

<i18n locale="es">
{
  "trackingTooltip": {
    "distance": "Distancia al inicio del servicio",
    "vk": "Kilómetros registrados",
    "time": "Hora de inicio del servicio"
  }
}
</i18n>

<i18n locale="it">
{
  "trackingTooltip": {
    "distance": "Distanza dall'inizio del servizio",
    "vk": "Chilometri tracciati",
    "time": "Orario di inizio del servizio"
  }
}
</i18n>

<i18n locale="pl">
{
  "trackingTooltip": {
    "distance": "Odległość początku usługi",
    "vk": "Śledzone kilometry",
    "time": "Czas rozpoczęcia usługi"
  }
}
</i18n>
