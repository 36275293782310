<template>
  <div class="type-cell" :title="$t(`title.${type}`)">
    <font-awesome-icon v-if="icon" class="type-cell__icon" :icon="icon" />
    {{ $t(type) }}
  </div>
</template>

<script>
/** @enum {string} */
export const TransportPlanType = {
  AUTO: 'auto',
  MANUAL: 'instant',
  PLANNED: 'planned',
};

export default {
  name: 'TypeCell',

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    icon() {
      switch (this.type) {
        case TransportPlanType.AUTO:
          return 'fa-rotate-right';
        case TransportPlanType.MANUAL:
          return 'fa-circle-arrow-up';
        case TransportPlanType.PLANNED:
          return 'fa-calendar-days';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.type-cell {
  color: $text-dark;
  font-weight: 500;

  &__icon {
    margin-right: 4px;
  }
}
</style>

<i18n locale="fr">
{
  "planned": "Planifié",
  "auto": "Auto",
  "instant": "Manuel",
  "title": {
    "planned": "Publié avec une date programmée",
    "auto": "Publié avec mise à jour automatique",
    "instant": "Publié immédiatement",
  }
}
</i18n>

<i18n locale="en">
{
  "planned": "Planned",
  "auto": "Auto",
  "instant": "Manual",
  "title": {
    "planned": "Published with a scheduled date",
    "auto": "Auto-published",
    "instant": "Published instantly",
  }
}
</i18n>
