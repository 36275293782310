/** @module */

/** @typedef {typeof state} State */
const state = {
  /**
   * Map<UrgencyId, Urgency>
   * @type {{[key: string]: import('@/@types/api/urgency').Urgency}}
   */
  list: {},
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,

  state,

  mutations: {
    resetUrgencies(state) {
      state.list = {};
    },

    /** @param {Array<import('@/@types/api/urgency').Urgency>} urgencies */
    updateUrgencies(state, urgencies) {
      urgencies.forEach(urgency => {
        if (!state.list[urgency.urgency_id]) {
          state.list[urgency.urgency_id] = urgency;
        } else {
          Object.assign(state.list[urgency.urgency_id], urgency);
        }
      });
    },
  },

  getters: {
    /** @returns {import('@/@types/api/urgency').Urgency[]} */
    getNewUrgencies: state => {
      const urgencies = Object.values(state.list).filter(urgency => urgency.acknowledgment === undefined);
      urgencies.sort((a, b) => a.device_time - b.device_time);

      return urgencies;
    },

    /** @returns {import('@/@types/api/urgency').Urgency[]} */
    getAcknowledgedUrgencies: state => {
      const acknowledgedUrgencies = Object.values(state.list).filter(
        urgency => urgency.acknowledgment !== undefined && urgency.resolution === undefined,
      );
      acknowledgedUrgencies.sort((a, b) => a.device_time - b.device_time);

      return acknowledgedUrgencies;
    },
  },
});

/** @enum {string} */
export const UrgencyEventType = {
  CREATE: 'urgency:create',
  ACKNOWLEDGE: 'urgency:acknowledged',
  RESOLVE: 'urgency:resolved',
};
