<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import ModalArchiveRestore from '@/components/ui/ModalArchiveRestore.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import ModalMessageNew, { RecipientType } from '@/components/ui/ModalMessageNew.vue';
import * as Messages from '@/store/messages';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';

import InboxModalDetails from './InboxModalDetails.vue';

import { ColumnKey, getDatagrid } from '@/pages/MessageListPage/Inbox.conf.js';
import { useMessage } from '@/use/use-message';

const { StatusEnum, ReadStatus } = Messages;

const store = useStore();

const {
  isLoading,
  shownMessages,
  modalMessageArchiveShown,
  modalMessageDetail,
  modalMessageNew,
  getMessages,
  showModalMessageDetail,
  handleSent,
  showModalMessageNew,
  multiArchive,
  closeModalMessageNew,
  submitModalMessageArchive,
} = useMessage();

/** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
const datagrid = getDatagrid();

/** @type {import('vue').Ref<boolean>} */
const firstLoad = ref(true);

/** @type {import('vue').Ref<?number>} */
const renderedDataLength = ref();

/** @type {import('vue').Ref<?Array<import('@/use/use-message').FormattedMessage>>} */
const multipleSelectedMessages = ref();

/** @return {Object} */
const buildCellInjectors = computed(() => {
  const bindClickHandler = apiData => () => showModalMessageDetail(apiData);
  return {
    [ColumnKey.CONTENT]: ({ apiData }) => ({
      click: bindClickHandler(apiData),
    }),
  };
});

watch(
  store.state.messages.hotInbox,
  () => {
    if (!firstLoad.value && shownMessages.value.length !== store.state.messages.hotInbox.length) {
      getMessages();
    }
  },
  {
    immediate: false,
    deep: true,
  },
);

function replyFromModal() {
  replyMessage(modalMessageDetail.value.message);
  closeModalMessageDetail();
}

/**
 * @param {import('@/use/use-message').FormattedMessage} message
 */
function replyMessage(message) {
  showModalMessageNew([
    {
      id: message.senderId,
      type: RecipientType.DEVICE,
    },
  ]);
}

/**
 * get button status for multiple selections
 * @param {Array<import('@/use/use-message').FormattedMessage>} messages
 * @param {string} type
 *  @return {string}
 */
function getStatusDisplayMultiple(messages, type) {
  let status = null;
  status = messages.some(message => message.status === StatusEnum.RECEIVED)
    ? ReadStatus.READ
    : ReadStatus.UNREAD;
  if (type === 'text') return `message.markAs.${status}`;
  return status === ReadStatus.READ ? 'fa-envelope' : 'fa-envelope-open';
}

/**
    Mark message(s) as Read / Unread
     * @param {import('@/use/use-message').FormattedMessage} message
     * @param {string} status
     */
async function setStatus(message, status) {
  if (message.status === status) return;
  const date = Date.now() / 1000;
  message.status = status;
  const patch = {
    messageId: message.id,
    message: {
      read: status === StatusEnum.RECEIVED ? false : date,
    },
  };
  // Check delivered date
  if (message.recipients[0].delivered === false || !message.recipients[0]) {
    patch.message.delivered = date;
  }
  await store.dispatch('messages/patch', patch);
}

/**
 * @param {Array<import('@/use/use-message').FormattedMessage>} messages
 * @param {string} status
 */
async function updateStatus(messages, status) {
  const messagesArr = Array.isArray(messages) ? messages : [messages];
  await Promise.all(messagesArr.map(msg => setStatus(msg, status)));
  store.dispatch('messages/setHotInboxMessages');
}

/**
 * toggle multiple status
 * @param {Array<import('@/use/use-message').FormattedMessage>} messages
 */
function toggleCheckedStatus(messages) {
  multipleSelectedMessages.value = messages;
  const currentStatus = multipleSelectedMessages.value.some(message => message.status === StatusEnum.RECEIVED)
    ? ReadStatus.READ
    : ReadStatus.UNREAD;
  const status = currentStatus === ReadStatus.READ ? StatusEnum.READ : StatusEnum.RECEIVED;
  updateStatus(multipleSelectedMessages.value, status);
}

function closeModalMessageDetail() {
  modalMessageDetail.value.shown = null;
  updateStatus(modalMessageDetail.value.message, StatusEnum.READ);
}

/**
 * Mark message as Unread.
 */
function setStatusUnread() {
  modalMessageDetail.value.shown = null;
  updateStatus(modalMessageDetail.value.message, StatusEnum.RECEIVED);
}

onMounted(async () => {
  await getMessages();
  firstLoad.value = false;
});
</script>

<template>
  <div class="inbox-message">
    <div class="inbox-message__header">
      <Btn type="primary" @click="showModalMessageNew()">
        <i class="fas fa-paper-plane" aria-hidden="true"></i>
        <span class="btn-text">{{ $t('message.new') }}</span>
      </Btn>
    </div>
    <DataGridVuetify
      v-model:rendered-data-length="renderedDataLength"
      :title="$t('InboxMessages', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="shownMessages"
      :loading="isLoading"
      :datagrid="datagrid"
    >
      <template #actions="propsAction">
        <ActionCell
          :actions="[
            propsAction.object.status === StatusEnum.READ ? 'readMessage' : 'readNewMessage',
            'reply',
            'archive',
          ]"
          :object="propsAction.object"
          @readMessage="dataLine => updateStatus(dataLine, StatusEnum.RECEIVED)"
          @readNewMessage="dataLine => updateStatus(dataLine, StatusEnum.READ)"
          @reply="dataLine => replyMessage(dataLine)"
          @archive="dataLine => multiArchive([dataLine])"
        />
      </template>
      <template #selectedItemsActions="propsAction">
        <Btn
          type="secondary archiveMessageBtn"
          :smaller="true"
          :title="$t('archive')"
          @click="multiArchive(propsAction.objects)"
        >
          <span class="action-cell__btn">
            <font-awesome-icon icon="fa-box-archive" class="mr-2" />
            {{ $t('archive') }}
          </span>
        </Btn>
        <Btn
          type="secondary checkedStatusBtn"
          :smaller="true"
          @click="toggleCheckedStatus(propsAction.objects)"
        >
          <span class="action-cell__btn">
            <font-awesome-icon :icon="getStatusDisplayMultiple(propsAction.objects, 'icon')" class="mr-2" />
            {{ $t(getStatusDisplayMultiple(propsAction.objects, 'text')) }}
          </span>
        </Btn>
      </template>
    </DataGridVuetify>

    <InboxModalDetails
      v-if="modalMessageDetail.shown"
      :message="modalMessageDetail.message"
      @reply="replyFromModal"
      @close="closeModalMessageDetail"
      @markUnread="setStatusUnread"
    />

    <ModalMessageNew
      v-if="modalMessageNew.shown"
      :recipients="modalMessageNew.recipients"
      @sent="handleSent"
      @close="closeModalMessageNew"
    />

    <ModalArchiveRestore
      v-if="modalMessageArchiveShown"
      :title="$t('archive')"
      :body="$t('message.confirmArchive')"
      @close="modalMessageArchiveShown = false"
      @submit="submitModalMessageArchive"
    />
  </div>
</template>

<style lang="scss" scoped>
.inbox-message {
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  height: 100%;
  padding: $view-standard-padding;

  &__header {
    display: flex;
    justify-content: flex-end;

    .btn-text {
      margin-left: 10px;
    }
  }

  .checkedStatusBtn,
  .archiveMessageBtn {
    height: 22px;

    .action-cell__btn {
      display: flex;
      align-items: center;
    }
  }
}
</style>

<i18n locale="fr">
{
  "InboxMessages": "message reçu | messages reçus"
}
</i18n>

<i18n locale="en">
{
  "InboxMessages": "received message | received messages"
}
</i18n>
