<template>
  <Modal modal-class="modal-remove" @close="$emit('close')">
    <template #title>
      <span>
        {{ title || $t('deleteElement') }}
      </span>
    </template>

    <template #body>
      <div class="modal-remove__body">
        {{ body || $t('confirmDelete') }}
      </div>
    </template>

    <template #cta>
      <Btn
        :type="isRestoreCase || isResetCase ? 'primary' : 'danger'"
        :disabled="isDisabled"
        @click="$emit('submit')"
      >
        <font-awesome-icon
          :icon="isResetCase ? 'fa-rotate-right' : isRestoreCase ? 'fa-boxes-packing' : 'fa-box-archive'"
        />
        <span>{{ $t(isResetCase ? 'reset' : 'submit') }}</span>
      </Btn>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';

export default {
  name: 'ModalArchiveRestore',

  components: {
    Btn,
    Modal,
  },

  props: {
    body: {
      default: '',
      type: String,
    },

    title: {
      default: '',
      type: String,
    },
    isRestoreCase: {
      type: Boolean,
      default: false,
    },
    isResetCase: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close', 'submit'],
};
</script>

<style lang="scss" scoped>
.modal-remove {
  &__body {
    margin-bottom: 10px;
    text-align: center;
  }
}
</style>

<i18n locale="fr">
{
									
																  
"confirmDelete": "Êtes-vous sûr de vouloir supprimer l'élément ?",
"deleteElement": "Supprimer l'élément",
"goBack": "Retour"
}
</i18n>

<i18n locale="en">
{
								  
																	
"confirmDelete": "Are you sure you want to delete the element?",
"deleteElement": "Delete the element",
"goBack": "Back"
}
</i18n>

<i18n locale="cz">
{
									  
															
"confirmDelete": "Opravdu chcete tento prvek smazat?",
"deleteElement": "Smazat prvek"
}
</i18n>

<i18n locale="de">
{
								 
																 
"confirmDelete": "Willst du dieses Element wirklich löschen?",
"deleteElement": "Element löschen"
}
</i18n>

<i18n locale="es">
{
									   
																	   
"confirmDelete": "¿Seguro que quieres eliminar el elemento?",
"deleteElement": "Eliminar el elemento"
}
</i18n>

<i18n locale="it">
{
									  
																	   
"confirmDelete": "Sei sicuro di voler eliminare questo elemento?",
"deleteElement": "Elimina l'elemento"
}
</i18n>

<i18n locale="pl">
{
								  
																 
"confirmDelete": "Na pewno chcesz skasować ten element?",
"deleteElement": "Skasuj element"
}
</i18n>
