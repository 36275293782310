<template>
  <div class="multiple-team-selector">
    <v-select
      id="selected-teams"
      :model-value="selectedTeams"
      :placeholder="$t('noTeam')"
      multiple
      tile
      :disabled="!editMode"
      :items="teamsOptions"
      variant="outlined"
      hide-details
      :class="{ 'multiple-team-selector__access-all-container': allTeamsSelected && !classicUi }"
      menu-icon="fa:fas fa-chevron-down"
      density="comfortable"
    >
      <template #selection="{ item, index }">
        <div v-if="allTeamsSelected && index === 0 && !classicUi" class="multiple-team-selector__access-all">
          <font-awesome-icon icon="fa-crown" />
          {{ $t('accessAll') }}
        </div>
        <div
          v-else-if="allTeamsSelected && index === 0 && classicUi"
          class="multiple-team-selector__all-teams"
        >
          {{ $t('allTeams') }}
        </div>
        <span v-else-if="allTeamsSelected"></span>
        <v-chip v-else :color="item.raw.color" variant="elevated">
          {{ item.title }}
          <font-awesome-icon
            icon="fa-xmark"
            class="chip-icon"
            @click.stop.prevent.capture="removeTeam(item.value)"
          />
        </v-chip>
      </template>

      <template #prepend-item>
        <v-list-item>
          <v-checkbox
            :model-value="allTeamsSelected"
            :label="$t('selectAll')"
            :indeterminate="selectedTeams.length !== teamsOptions.length && selectedTeams.length > 0"
            color="success"
            hide-details
            @click="toggleAll"
          ></v-checkbox>
        </v-list-item>

        <v-divider />
      </template>

      <template #no-data>
        <div>
          {{ $t('noData') }}
        </div>
      </template>

      <template #item="{ props, item }">
        <v-list-item v-bind="props" title="" @click.stop="toggle(item.value)">
          <v-checkbox
            :label="item.title"
            :model-value="allTeamsSelected || selectedTeams.includes(item.value)"
            color="success"
          />
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'TeamSelector',

  props: {
    editMode: {
      default: false,
      type: Boolean,
    },

    classicUi: {
      default: false,
      type: Boolean,
    },

    selectedTeams: {
      default: () => [],
      type: Array,
    },

    showAll: {
      default: false,
      type: Boolean,
    },
  },

  emits: ['valueChanged'],

  computed: {
    /** @returns {boolean} */
    allTeamsSelected() {
      return this.selectedTeams.length === this.teamsOptions.length;
    },

    /**
     * Recreate a list of team objects
     * @returns {Array<TeamOption>}
     */
    teamsOptions() {
      const teams = this.showAll ? this.$store.state.teams : this.$store.getters.activeTeams;
      return teams
        .map(({ team_id, name, color }) => ({
          value: team_id,
          title: name ?? team_id,
          color: color ?? '#333333',
        }))
        .sort();
    },
  },

  methods: {
    select(value) {
      this.$emit('valueChanged', {
        value,
        field: 'teams',
      });
    },

    toggle(team) {
      if (this.selectedTeams.includes(team)) {
        this.select(this.selectedTeams.filter(t => t !== team));
        return;
      }
      this.select([...this.selectedTeams, team]);
    },

    toggleAll() {
      const value = this.selectedTeams.length ? [] : this.teamsOptions.map(t => t.value);
      this.select(value);
    },

    removeTeam(value) {
      this.select(this.selectedTeams.filter(t => t !== value));
    },
  },
};

/** @typedef {Object} TeamOption
 * @param {string} color
 * @param {string} title
 * @param {string} value
 */
</script>

<style lang="scss">
.multiple-team-selector {
  background-color: $white;

  &__all-teams {
    margin-left: 5px;
    font-size: 14px;
  }

  .v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
    padding-inline: 10px;
  }

  &__access-all {
    display: inline-block;
    color: $primary-light;
    font-weight: $font-weight-semi-bold;
    font-size: $datagrid-font-size;
    white-space: nowrap;
    padding-inline: 8px;
  }

  &__access-all-container {
    .v-field {
      border: 2px solid $primary-light !important;
      background-color: $transparent-primary;

      i {
        color: $primary-light !important;
      }
    }
  }

  .v-field__input {
    max-height: 47px;
    padding-top: 12px;

    & > input {
      padding-left: 8px;
    }

    .v-select__selection {
      margin-bottom: 10px;

      &:first-child {
        padding-left: 8px;
      }
    }
  }
}

.v-overlay__content.v-select__content {
  padding-top: 2px;

  .v-label {
    font-size: $datagrid-font-size;
  }
}
</style>

<i18n locale="fr">
{
  "accessAll": "Accès à toutes les données",
  "allTeams": "Toutes les équipes",
  "noTeam": "  Pas d'équipe",
  "selectAll": "Tout sélectionner",
}
</i18n>

<i18n locale="en">
{
  "accessAll": "Access all data",
  "allTeams": "Toutes les équipes",
  "noTeam": "  No team",
  "selectAll": "Select all",
}
</i18n>
