<template>
  <HistoryMap
    v-model:bounds="mapBounds"
    class="trip-detailed-map"
    :center="mapCenter"
    :device="device"
    :is-trip-canceled="isTripCanceled"
    :show-track-display-switch="showTrackDisplaySwitch"
    :stops="stops"
    :trip="mapTrip"
    :ts="ts"
    trip-update-mode
    show-map-layers-dropdown
    @displayWholeVehicleTrack="displayWholeVehicleTrack"
    @click:stop="onMapStopClick"
    @mouseenter:stop="onMapStopEnter"
    @mouseleave:stop="onMapStopLeave"
    @load="onMapLoad"
  />
</template>

<script>
import { LngLatBounds } from 'mapbox-gl';
import HistoryMap from '@/components/map/HistoryMap.vue';
import { HighlightType } from '@/@types/mapbox';

import { FeedEventType } from './EventFeedShared.js';

export default {
  name: 'TripDetailedMap',

  components: { HistoryMap },

  props: {
    bounds: {
      type: LngLatBounds,
      default: null,
    },

    /** @type {Vue.PropOptions<?[number, number]>} */
    // @ts-ignore TS2322: compatibility between `Array` and `[number, number]`
    center: {
      type: Array,
      default: null,
    },

    /** @type {Vue.PropOptions<import('@/components/map/HistoryMap.vue').HistoryMapDevice>} */
    device: {
      type: Object,
      default: null,
    },

    isTripCanceled: {
      default: false,
      type: Boolean,
    },

    isTripRunning: {
      default: false,
      type: Boolean,
    },

    showTrackDisplaySwitch: {
      type: Boolean,
      default: false,
    },

    /** @type {Vue.PropOptions<Object.<string, import('@/store/gtfs').Stop>>} */
    stops: {
      type: Object,
      required: true,
    },

    /** @type {Vue.PropOptions<Array<import('@/store/gtfs').StopTime>>} */
    stopTimes: {
      type: Array,
      required: true,
    },

    /** @type {Vue.PropOptions<import('vue').Prop<import('@/api').TripListItemV4>>} */
    trip: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    displayWholeVehicleTrackIsOn: false,
    /** @type {mapboxgl.Map} */
    map: null,
    /** @type {?mapboxgl.LngLatBounds} */
    mapBounds: null,
  }),

  computed: {
    /** @type {string} */
    stopOnFocus() {
      return this.$store.state.tripDetailed.focusedStop;
    },

    /** @type {number} */
    ts() {
      return this.$store.state.tripDetailed.focusedTimeStamp;
    },

    /** @return {?[number, number]} */
    mapCenter() {
      // Test focus on device instead of stop
      if (this.ts && this.device?.events && !this.displayWholeVehicleTrackIsOn) {
        const upperResult = this.device.events.filter(event => event.ts > this.ts);
        let result = null;
        if (upperResult && upperResult.length > 0) {
          result = upperResult.reduce((a, b) => {
            return Math.abs(b.ts - this.ts) <= Math.abs(a.ts - this.ts) ? b : a;
          });
          if (!result.latlng) return [null, null];
          return [result.latlng[1], result.latlng[0]];
        }
      }

      return null;
    },

    /** @return {import('@/components/map/MapboxMap.vue').MapTrip} */
    mapTrip() {
      if (this.trip) {
        return { id: this.trip.id, highlight: HighlightType.MORE };
      }
      return null;
    },
  },

  watch: {
    stopOnFocus() {
      // We want the map to center only once when the switch is turned on
      if (this.stopOnFocus !== this.stopTimes[this.stopTimes.length - 1].stop_id) {
        this.displayWholeVehicleTrackIsOn = false;
      }
    },
  },

  methods: {
    displayWholeVehicleTrack(displayWholeVehicleTrack) {
      if (displayWholeVehicleTrack) {
        this.displayWholeVehicleTrackIsOn = true;
      }
      // Focus on last stop if trip not running
      if (displayWholeVehicleTrack && !this.isTripRunning) {
        const lastServicedStop = this.$store.state.tripDetailed.eventFeed.findLast(
          stop =>
            stop.isTheoreticalOnly === false &&
            (stop.type === FeedEventType.ARRIVAL || stop.type === FeedEventType.DEPARTURE),
        );
        this.$store.dispatch('tripDetailed/setFocusedByMap', lastServicedStop.stopId);
      }
    },

    onMapLoad({ map }) {
      map.once('idle', () => {
        this.map = map;
      });
    },

    /**
     * Triggered when a stop on the map is clicked.
     * @param {string} id
     */
    onMapStopClick(id) {
      this.$store.dispatch('tripDetailed/setFocusedByMap', id);
    },

    /** @param {mapboxgl.MapLayerMouseEvent} event */
    onMapStopEnter() {
      this.map.getCanvasContainer().style.cursor = 'pointer';
    },

    /** @param {mapboxgl.MapLayerMouseEvent} event */
    onMapStopLeave() {
      this.map.getCanvasContainer().style.cursor = '';
    },
  },
};
</script>

<style lang="scss">
.trip-detailed-map {
  .mapboxgl-map {
    border-radius: 8px;
  }

  .mapboxgl-ctrl-top-right {
    margin-top: 40px;
  }
}
</style>
