<script setup lang="ts">
import mapboxgl, { LngLat, LngLatBounds } from 'mapbox-gl';
import { MapboxHelper } from '@/components/map/mapboxHelper';
import { createGeoJSONCircle } from '@/libs/helpers/geo';
import { AppUrl } from '@/api';
import { LocationType } from '@/store/gtfs.js';

import { INTERPOLATE } from './map-const.js';
import { GroupRoute } from '@/libs/routing';
import { computed, nextTick, onMounted, onUnmounted, ref, watch, type PropType } from 'vue';
import { useStore } from 'vuex';
import type { Stop, StopTime } from '@/@types/gtfs.js';
import i18n from '@/i18n.js';
import { useTripUpdates } from '@/store-pinia/trip-updates.js';
import {
  DEVIATION_STATION_LAYER,
  DEVIATION_STOP_LAYER,
  STATIONS_ICON_LAYER_ID,
  STOPS_ICON_LAYER_ID,
  STOPS_ZONE_LAYER_ID,
  UNSERVED_STATION_LAYER,
  UNSERVED_STOP_LAYER,
  type MapStop,
  type StopsOptions,
} from '@/@types/mapbox.js';

const STATIONS_SOURCE_ID = 'stationsSource';
const STOPS_SOURCE_ID = 'stopsSource';
const STOPS_ZONE_SOURCE_ID = 'stopsZoneSource';

const { t, tc } = i18n.global;
const store = useStore();

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
  },

  gtfsId: {
    type: String,
    required: true,
  },

  options: {
    type: Object as PropType<StopsOptions>,
    required: true,
  },

  stops: {
    type: Array<MapStop>,
    required: true,
  },

  displayTooltip: {
    type: Boolean,
    default: false,
  },

  tripUpdateMode: {
    type: Boolean,
    default: false,
  },

  isOnTripModificationPage: {
    type: Boolean,
    default: false,
  },
});

const tripUpStore = ref(props.tripUpdateMode ? useTripUpdates() : null);

const emit = defineEmits([
  'click',
  'rightClick',
  'mouseenter',
  'mouseleave',
  'update:bounds',
  'isLoaded',
  'tooltipToggleStop',
]);

defineExpose({ resetHasStopBeenClicked });

const loaded = ref<Boolean>(false);
// Keep stop tooltip open until click outside or inside
const hasStopBeenClicked = ref<boolean>(false);

const groupId = computed(() => store.getters.group._id);

const allStopsFiltered = computed<Array<Stop>>(() => {
  if (!loaded.value) return [];

  let stopsGtfs: { [stopId: string]: Stop } = {};
  if (tripUpStore.value) {
    // if mapboxStops is displayed from trip updates, get stops source from a different store
    stopsGtfs = Object.fromEntries(tripUpStore.value.getAllStops);
  } else {
    stopsGtfs = store.getters['gtfs/getCachedGtfsTable'](props.gtfsId, 'stops');
  }

  const result = Object.values(
    mapStops.value.reduce((acc: { [stopId: string]: Stop }, elem) => {
      if (stopsGtfs[elem.id]) {
        acc[elem.id] = stopsGtfs[elem.id];
      }

      return acc;
    }, {}),
  );
  return result;
});

const mapStops = computed<Array<MapStop>>(() =>
  tripUpStore.value ? tripUpStore.value.mapStops : props.stops,
);

const bounds = computed<LngLatBounds | null>(() => {
  const list = allStopsFiltered.value;
  if (list.length === 0) return null;
  const bounds = new LngLatBounds();
  list.forEach(stop => bounds.extend(new LngLat(stop.stop_lon, stop.stop_lat)));

  return bounds;
});

const unservedStops = computed<{ [stop_sequence: string]: { stop_id: string } }>(() => {
  return mapStops.value.reduce(
    (acc: { [stop_sequence: string]: { stop_id: string } }, { id, unserved, stop_sequence }) => {
      if (unserved && stop_sequence !== undefined) {
        acc[stop_sequence] = {
          stop_id: id,
        };
      }
      return acc;
    },
    {},
  );
});

const highlightStops = computed<{ [stopId: string]: boolean }>(() => {
  return mapStops.value.reduce((acc: { [stopId: string]: boolean }, elem) => {
    if (elem.highlight) {
      acc[elem.id] = true;
    }

    return acc;
  }, {});
});

const stationsFiltered = computed<{ [stopId: string]: Stop }>(() => {
  return stopsByType.value[LocationType.STATION];
});

const stationsSource = computed<Array<GeoJSON.Feature<GeoJSON.Point>>>(() => {
  return stopsSourceByType(LocationType.STATION);
});

const stopsByType = computed<{ [locationType: string]: { [stopId: string]: Stop } }>(() => {
  const allStops = allStopsFiltered.value;

  return allStops.reduce(
    (acc: { [locationType: string]: { [stopId: string]: Stop } }, stop) => {
      const type = stop.location_type || LocationType.STOP;
      if (!acc[type]) acc[type] = {};
      acc[type][stop.stop_id] = stop;

      return acc;
    },
    { [LocationType.STATION]: {}, [LocationType.STOP]: {} },
  );
});

const stopsFiltered = computed<{ [stopId: string]: Stop }>(() => {
  return stopsByType.value[LocationType.STOP];
});

const stopsSource = computed<Array<GeoJSON.Feature<GeoJSON.Point>>>(() => {
  return stopsSourceByType(LocationType.STOP);
});

const stopZoneRadiusExceptions = computed<{ [stopId: string]: number }>(() => {
  return store.getters.group.stop_distance_threshold_exceptions || {};
});

const stopZoneRadiusDefault = computed<number>(() => store.getters.group.stop_distance_threshold);

const stopsZoneSource = computed<Array<GeoJSON.Feature<GeoJSON.Polygon>>>(() => {
  const zones = !props.options.stopsZones ? [] : allStopsFiltered.value;

  return zones.map(stop => ({
    type: 'Feature',

    geometry: createGeoJSONCircle(
      [stop.stop_lon, stop.stop_lat],
      stopZoneRadiusExceptions.value[stop.stop_id] || stopZoneRadiusDefault.value,
    ),

    properties: {
      id: stop.stop_id,
    },
  }));
});

watch(
  () => props.tripUpdateMode,
  () => {
    tripUpStore.value = props.tripUpdateMode ? useTripUpdates() : null;
  },
);

watch(
  bounds,
  (newBounds, oldBounds) => {
    // only apply update on bounds if not on TripModificationPage & only if bounds has changed
    // case isOnTripModificationPage : handled in TripModificationPage index
    if (
      !props.isOnTripModificationPage &&
      newBounds &&
      (!oldBounds || newBounds.toString() !== oldBounds.toString())
    ) {
      emit('update:bounds', bounds.value);
    }
  },
  { immediate: true },
);

watch(
  stopsSource,
  () => {
    MapboxHelper.updateSource(props.map, STOPS_SOURCE_ID, stopsSource.value);
  },
  { deep: true },
);

watch(
  stationsSource,
  () => {
    MapboxHelper.updateSource(props.map, STATIONS_SOURCE_ID, stationsSource.value);
  },
  { deep: true },
);

watch(
  stopsZoneSource,
  () => {
    MapboxHelper.updateSource(props.map, STOPS_ZONE_SOURCE_ID, stopsZoneSource.value);
  },
  { deep: true },
);

watch(
  () => tripUpStore.value?.inAddManualStopMode,
  () => {
    if (tripUpStore.value?.inAddManualStopMode) {
      props.map.getCanvasContainer().style.cursor = 'crosshair';
    } else {
      props.map.getCanvasContainer().style.cursor = '';
    }
  },
);

onMounted(() => {
  props.map.once('idle', () => {
    loaded.value = true;
  });
  initSourceAndLayer();
});

onUnmounted(() => {
  MapboxHelper.cleanLayersAndSources(
    props.map,
    [STATIONS_ICON_LAYER_ID, STOPS_ICON_LAYER_ID, STOPS_ZONE_LAYER_ID],
    [STATIONS_SOURCE_ID, STOPS_SOURCE_ID, STOPS_ZONE_SOURCE_ID],
  );
});

function addStationsIconLayer() {
  createStopOrStationLayer(STATIONS_ICON_LAYER_ID, STATIONS_SOURCE_ID);
  addLayerActions(STATIONS_ICON_LAYER_ID);
}

function addStopsIconLayer() {
  createStopOrStationLayer(STOPS_ICON_LAYER_ID, STOPS_SOURCE_ID);
  addLayerActions(STOPS_ICON_LAYER_ID);
}

function addStopsZoneLayer() {
  MapboxHelper.addLayer(props.map, {
    id: STOPS_ZONE_LAYER_ID,
    type: 'fill',
    source: STOPS_ZONE_SOURCE_ID,
    paint: {
      'fill-color': '#ff0000',
      'fill-opacity': 0.35,
    },
  });
}

function addTripUpdateLayers() {
  createUnservedStopOrStationLayer(UNSERVED_STOP_LAYER, STOPS_SOURCE_ID);
  createUnservedStopOrStationLayer(UNSERVED_STATION_LAYER, STATIONS_SOURCE_ID);
  createDeviationStopOrStationLayer(DEVIATION_STOP_LAYER, STOPS_SOURCE_ID);
  createDeviationStopOrStationLayer(DEVIATION_STATION_LAYER, STATIONS_SOURCE_ID);
}

function initSourceAndLayer() {
  MapboxHelper.createEmptySource(props.map, STATIONS_SOURCE_ID);
  MapboxHelper.createEmptySource(props.map, STOPS_SOURCE_ID);
  MapboxHelper.createEmptySource(props.map, STOPS_ZONE_SOURCE_ID);

  addStopsZoneLayer();
  addStopsIconLayer();
  addStationsIconLayer();

  if (props.options.showUnserved) {
    addTripUpdateLayers();
  }

  emit('isLoaded', true);
}

function addLayerActions(layerName: string) {
  props.map.on('mouseenter', layerName, e => {
    if (e.features && e.features.length > 0 && !tripUpStore.value?.inAddManualStopMode) {
      emit('mouseenter', e);
    }
  });
  props.map.on('mouseleave', layerName, e => {
    if (!tripUpStore.value?.inAddManualStopMode) {
      onStopMouseLeave(e);
    }
  });
  props.map.on('mousemove', layerName, e => {
    if (e.features && e.features.length > 0 && !tripUpStore.value?.inAddManualStopMode) {
      onStopMouseMove(e);
    }
  });
  props.map.on('click', layerName, e => {
    if (e.features && e.features.length > 0) {
      emit('click', e);
      if (props.displayTooltip) {
        hasStopBeenClicked.value = true;
        MapboxHelper.displayTooltip(e, props.map, 'tooltip-map');
      }
    }
  });

  props.map.on('mouseup', layerName, e => {
    // Handle right click (only can be found with mousedown/mouseUp & with originalEvent button 2)
    if (e.originalEvent.button === 2 && e.features) {
      emit('rightClick', e);

      if (props.options.stopSelectorData && props.options.stopSelectorData?.length > 0) {
        displayStopSelectorOnClick(e, props.map, props.options.stopSelectorData);
      }
    }
  });

  props.map.on('click', e => {
    if (tripUpStore.value?.inAddManualStopMode) {
      if (e.lngLat.lat && e.lngLat.lng) {
        if (tripUpStore.value?.stopInEdition) {
          tripUpStore.value.stopInEdition.stop_lat = e.lngLat.lat;
          tripUpStore.value.stopInEdition.stop_lon = e.lngLat.lng;
        }
      }
    }
  });
}

function stopsSourceByType(
  type: import('@/store/gtfs.js').LocationType,
): Array<GeoJSON.Feature<GeoJSON.Point>> {
  const isStation = LocationType.STATION === type;

  const markers = isStation
    ? !props.options.stationsMarkers
      ? []
      : Object.values(stationsFiltered.value)
    : !props.options.stopsMarkers
      ? []
      : Object.values(stopsFiltered.value);

  return markers.map(stop => ({
    type: 'Feature',

    geometry: {
      type: 'Point',
      coordinates: [stop.stop_lon, stop.stop_lat],
    },

    properties: {
      highlight: highlightStops.value[stop.stop_id] || true,
      unserved: !!Object.values(unservedStops.value).find(st => st.stop_id === stop.stop_id),
      deviation: !!Object.values(mapStops.value).find(st => st.id === stop.stop_id && st.deviation),
      editionHighlight: !!Object.values(mapStops.value).find(
        st => st.id === stop.stop_id && st.editionHighlight,
      ),
      id: stop.stop_id,
      label: stop.stop_name,
      description: generateStopDescription(stop, false),
      shortDescription: generateStopDescription(stop, true),
    },
  }));
}

function onStopMouseLeave(event: mapboxgl.MapLayerMouseEvent) {
  emit('mouseleave', event);
  // We close the tooltip on mouseLeave only if no depot have been clicked
  if (!hasStopBeenClicked.value) {
    MapboxHelper.removeAllTooltips(props.map);
  }
}

function onStopMouseMove(event: mapboxgl.MapLayerMouseEvent) {
  if (event?.features && event.features.length > 0 && !hasStopBeenClicked.value) {
    MapboxHelper.displayTooltip(event, props.map, 'tooltip-map');
  }
}

/**
 * Generate stop tooltip HTML based on stop informations
 */
function generateStopDescription(stop: Stop, short: boolean | null) {
  const tooltipCtaUrl = `${AppUrl}/#/${groupId.value}/${GroupRoute.STOP_DETAILED}/${stop.stop_id}`;

  // Stop name
  let description = `<div class="tooltip-map__title-box">${stop.stop_name}</div>`;
  if (!short) {
    description = `<div class="tooltip-map__title-box no-radius">${stop.stop_name}</div>`;
  }

  // Parent station if exists
  if (stop.parent_station && stationsFiltered.value[stop.parent_station]) {
    const withRadius = !short ? 'no-radius' : '';
    const relatedStationName = stationsFiltered.value[stop.parent_station]?.stop_name;
    description = `<div class="tooltip-map__title-box ${withRadius}"><div class="tooltip-map__title-text">${stop.stop_name}</div><div class="tooltip-map__sub-text">${relatedStationName}</div></div>`;
  }

  // We don't want CTA button when displayed stop is a Temporary created stop (from trip-update)
  // To detect them, we check their stop_id, they have a generated one like 'temporary_guid'
  const isTemporaryStop = stop.stop_id.startsWith('temporary_');
  if (!short && !isTemporaryStop) {
    // CTA button
    description += `<a class="tooltip-map__button" href="${tooltipCtaUrl}"> <i class="fas fa-eye"></i>${t(
      'detail',
    )}</a>`;
  }

  return description;
}

/**
 * Open tooltip for stop with multiple services (for loops on trip modification)
 */
function displayStopSelectorOnClick(
  event: mapboxgl.MapLayerMouseEvent,
  map: mapboxgl.Map,
  tripStopTimes: Array<StopTime>,
) {
  // remove potential previous tooltip
  MapboxHelper.removeAllTooltips(map);
  if (event?.features) {
    const stopId = event.features[0]?.properties?.id;
    const duplicatedStop = tripStopTimes.filter(s => s.stop_id === stopId);
    const isStopIdDuplicate = duplicatedStop.length > 1;
    if (stopId && isStopIdDuplicate) {
      hasStopBeenClicked.value = true;
      const geometry = event.features[0].geometry as GeoJSON.Point;
      const coordinates = geometry.coordinates.slice() as mapboxgl.LngLatLike;
      // Generate tooltip content
      let popupContent = ``;
      duplicatedStop.forEach((stop, index) => {
        let currentStop = '';
        if (unservedStops.value[stop.stop_sequence]) {
          currentStop = `<div id="${stop.stop_id + index}" class="multistop-tooltip">
          <i class="fas fa-redo"></i>
        <span class="multistop-tooltip__text">${
          t('restoreService') + tc('serviceCount', { count: index + 1 })
        }</span>
        </div>`;
        } else {
          currentStop = `<div id="${stop.stop_id + index}" class="multistop-tooltip">
        <i class="fas fa-times"></i>
        <span class="multistop-tooltip__text">${
          t('cancelService') + tc('serviceCount', { count: index + 1 })
        }</span>
        </div>`;
        }
        popupContent += currentStop;
      });

      new mapboxgl.Popup({
        className: 'stop-sequence-selector',
        closeButton: false,
        anchor: 'top',
      })
        .setLngLat(coordinates)
        .setHTML(popupContent)
        .addTo(map);

      // Add event listener on each stop
      nextTick(() => {
        duplicatedStop.forEach((stop, index) => {
          const stopSequence = stop.stop_sequence;
          document.getElementById(stop.stop_id + index)?.addEventListener('click', () => {
            emit('tooltipToggleStop', { event, stopSequence });
            MapboxHelper.removeAllTooltips(map);
            resetHasStopBeenClicked();
          });
        });
      });
    }
  }
}

function createStopOrStationLayer(layerId: string, sourceId: string) {
  let layerOptions = props.options.stopsBigMarkers ? INTERPOLATE.stopsBigMarkers : INTERPOLATE.stops;
  if (sourceId === STATIONS_SOURCE_ID) {
    layerOptions = INTERPOLATE.stations;
  }
  MapboxHelper.addLayer(props.map, {
    id: layerId,
    type: 'circle',
    source: sourceId,
    filter: ['any', ['!=', 'deviation', true], ['==', 'editionHighlight', true]],
    paint: {
      'circle-radius': ['interpolate', ['linear'], ['zoom'], ...layerOptions.circleRadius],
      'circle-color': [
        'case',
        ['to-boolean', ['get', 'unserved']],
        layerOptions.circleColor[0],
        ['to-boolean', ['get', 'deviation']],
        layerOptions.circleColor[1],
        layerOptions.circleColor[2],
      ],
      'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], ...layerOptions.circleStrokeWidth],
      'circle-opacity': props.tripUpdateMode
        ? [
            'case',
            ['to-boolean', ['get', 'deviation']],
            0,
            ['to-boolean', ['get', 'editionHighlight']],
            1,
            0.5,
          ]
        : 1,
      'circle-stroke-opacity': props.tripUpdateMode
        ? [
            'case',
            ['to-boolean', ['get', 'deviation']],
            0,
            ['to-boolean', ['get', 'editionHighlight']],
            1,
            0.5,
          ]
        : 1,
      'circle-stroke-color': layerOptions.circleStrokeColor,
    },
  });
}

function createUnservedStopOrStationLayer(layerId: string, sourceId: string) {
  MapboxHelper.addLayer(props.map, {
    id: layerId,
    type: 'symbol',
    source: sourceId,
    filter: ['==', 'unserved', true],
    layout: {
      'icon-image': 'crossIcon',
      'icon-size': 0.8,
      'icon-allow-overlap': true,
    },
  });
}
function createDeviationStopOrStationLayer(layerId: string, sourceId: string) {
  MapboxHelper.addLayer(props.map, {
    id: layerId,
    type: 'symbol',
    source: sourceId,
    filter: ['==', 'deviation', true],
    layout: {
      'icon-image': 'deviationIcon',
      'icon-size': ['interpolate', ['exponential', 1.5], ['zoom'], 10, 0.25, 15, 0.5],
      'icon-allow-overlap': true,
    },
    paint: {
      'icon-opacity': ['case', ['to-boolean', ['get', 'editionHighlight']], 1, 0.5],
    },
  });
}

function resetHasStopBeenClicked() {
  hasStopBeenClicked.value = false;
}
</script>

<template>
  <div class="mapbox-stops"></div>
</template>
