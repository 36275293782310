<script setup lang="ts">
import { computed, onMounted, ref, watch, type PropType } from 'vue';

import Btn from '@/components/ui/Btn.vue';
import DrawShapeMode from '@/libs/mapbox/draw-shape-mode';
import { getDrawTheme } from '@/libs/mapbox/draw-theme.js';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import ModalReset from '@/components/ui/ModalArchiveRestore.vue';

import { DrawStep, useTripUpdates, type StopAwayFromShape } from '@/store-pinia/trip-updates';
import cloneDeep from 'clone-deep';
import { lineString, point, nearestPointOnLine } from '@turf/turf';
import { useStore } from 'vuex';
import type { Shape, Trip } from '@/@types/gtfs';
import type { TripListItemV4 } from '@/@types/api/tripList';
import { FstType } from '../../store-pinia/trip-updates';

const enum ModificationMode {
  EDIT_STOP = 0,
  EDIT_SHAPE = 1,
}

const tripUpStore = useTripUpdates();
const store = useStore();

const mapboxDraw = ref<MapboxDraw>();
const prevent = (e: { preventDefault: () => any }) => e.preventDefault();

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
  },
  trip: {
    type: Object as PropType<TripListItemV4>,
    required: true,
  },
  routeColor: {
    type: String,
    default: '#ff0',
  },
});

const isReady = ref<boolean>(false);
const displayModalReset = ref<boolean>(false);
const modificationMode = ref<ModificationMode>(ModificationMode.EDIT_STOP);
const baseShape = ref<Array<[number, number]> | null>(null);

setTimeout(() => {
  isReady.value = true;
}, 600);

const coordsInEdition = ref<Array<[number, number]>>();
const coordsSaved = ref<Array<[number, number]>>([]);

const stopZoneRadiusExceptions = computed<{ [stopId: string]: number }>(
  () => store.getters.group.stop_distance_threshold_exceptions,
);
const stopZoneRadiusDefault = computed<number>(() => store.getters.group.stop_distance_threshold || 50);

const emit = defineEmits(['showBlockedModal']);

watch(modificationMode, () => {
  if (modificationMode.value === ModificationMode.EDIT_SHAPE)
    tripUpStore.allowDeviationFeature ? switchShapeEditionMode() : showBlockedModal();
  else {
    tripUpStore.inShapeEdition = false;
    resetNonsavedPart();
    stopShapeEdition();
  }
});

watch(
  [() => baseShape.value, () => modificationMode.value],
  async () => {
    if (modificationMode.value === ModificationMode.EDIT_SHAPE) {
      // Stop edition
      if (baseShape.value === null) {
        props.map.off('contextmenu', prevent);
        if (mapboxDraw.value) {
          props.map.removeControl(mapboxDraw.value as any);
        }
        return;
      }
      props.map.on('contextmenu', prevent);

      // Init draw control and callbacks on first use
      if (!mapboxDraw.value) {
        mapboxDraw.value = new MapboxDraw({
          displayControlsDefault: false,
          boxSelect: true,
          styles: getDrawTheme(props.routeColor),
          modes: {
            simple_select: MapboxDraw.modes.simple_select,
            draw_shape: DrawShapeMode,
          },
        });

        // Shape changed
        props.map.on('draw.update', e => {
          if (e.action === 'change_coordinates') {
            tripUpStore.currentStep = DrawStep.DRAW_SHAPE_DONE;
            coordsInEdition.value = e.features[0].geometry.coordinates;
          }
          if (e.action === 'select_path_to_edit') {
            tripUpStore.currentStep = DrawStep.DRAW_SHAPE;
          }
        });
      }

      props.map.addControl(mapboxDraw.value as any, 'top-left');

      if (!mapboxDraw.value || !baseShape.value) return;
      mapboxDraw.value.changeMode('draw_shape', baseShape.value);
    }
  },
  { immediate: true },
);

function resetShapeToGtfsState() {
  displayModalReset.value = false;
  updateShapeAndSetFirstStep(tripUpStore.initialTripShape);
  coordsSaved.value = cloneDeep(tripUpStore.initialTripShape);
  tripUpStore.editedShape = undefined;
}

function resetStopsToGtfsState() {
  displayModalReset.value = false;
  tripUpStore.$resetStopsToInitialGtfsState();
}

function resetNonsavedPart() {
  const shapeToLoad = coordsSaved.value.length > 0 ? coordsSaved.value : baseShape.value;
  if (shapeToLoad) updateShapeAndSetFirstStep(shapeToLoad);
}

function confirmDeviationPart() {
  if (coordsInEdition.value) coordsSaved.value = cloneDeep(coordsInEdition.value);
  {
    updateShapeAndSetFirstStep(coordsInEdition.value);
    tripUpStore.editedShape = coordsSaved.value;
  }
}

function updateShapeAndSetFirstStep(shape: [number, number][] | undefined) {
  if (mapboxDraw.value && shape) {
    mapboxDraw.value.changeMode('draw_shape', shape);
    tripUpStore.currentStep = DrawStep.SELECT_PATH;
    coordsInEdition.value = undefined;
  }
}

watch(
  [
    () => baseShape.value,
    () => coordsInEdition.value,
    () => coordsSaved.value,
    () => tripUpStore.feedStopTimes,
  ],
  () => {
    // Case 1 : coordsInEdition if exist, case 2:  coordsSaved, else default coords from props
    const shapeToTest =
      coordsInEdition.value && coordsInEdition.value?.length > 0
        ? coordsInEdition.value
        : coordsSaved.value.length > 0
          ? coordsSaved.value
          : baseShape.value;

    const stopIdsAwayFromShape = [] as StopAwayFromShape[];
    if (shapeToTest) {
      // Check every stops & detect if they are too far away from shape
      // If stop is in state neutralized or canceled, we don't check distance from shape
      tripUpStore.getAllStops.forEach(stop => {
        const isStopCanceledOrNeutralized = tripUpStore.feedStopTimes.find(
          fst => fst.stop_id === stop.stop_id && [FstType.CANCELED, FstType.NEUTRALIZED].includes(fst.type),
        );
        if (!isStopCanceledOrNeutralized) {
          const distanceToCheck = stopZoneRadiusExceptions.value[stop.stop_id] || stopZoneRadiusDefault.value;
          const result = findNearestDistanceBetweenPointAndShape(shapeToTest, [stop.stop_lon, stop.stop_lat]);
          if (result && result > distanceToCheck) {
            stopIdsAwayFromShape.push({
              stopId: stop.stop_id,
              distanceThreshold: distanceToCheck,
              currentDistance: Math.ceil(result),
            });
          }
        }
      });
    }
    tripUpStore.stopsAwayFromShape = stopIdsAwayFromShape;
  },
  { immediate: true, deep: true },
);

onMounted(() => {
  setShapeInEdition();
});

function switchShapeEditionMode() {
  tripUpStore.inShapeEdition = true;
  setShapeInEdition();
}

function showBlockedModal() {
  emit('showBlockedModal');
  modificationMode.value = ModificationMode.EDIT_STOP;
}

function setShapeInEdition() {
  const shapesGtfs: { [shapeId: string]: Shape } = store.getters['gtfs/getCachedGtfsTable'](
    props.trip.gtfs[0].id,
    'shapes',
  );
  const tripsGtfs: { [tripId: string]: Trip } = store.getters['gtfs/getCachedGtfsTable'](
    props.trip.gtfs[0].id,
    'trips',
  );
  const trip = tripsGtfs[props.trip.id];

  if (trip) {
    const shape = shapesGtfs[trip.shape_id];
    if (shape) {
      const coords = tripUpStore.editedShape || shape.geometry.coordinates;

      tripUpStore.initialTripShape = shape.geometry.coordinates as Array<[number, number]>;

      baseShape.value = coords as Array<[number, number]>;
    }
  }
}

function stopShapeEdition() {
  tripUpStore.inShapeEdition = false;
  props.map.removeControl(mapboxDraw.value as any);
}

/**
 * Find the nearest distance between a point and a portion of a shape
 */
function findNearestDistanceBetweenPointAndShape(
  coords: Array<[number, number]>,
  longlat: [number, number],
): null | number {
  let nearestLength: null | number = null;
  coords.forEach((wp, index) => {
    if (index !== 0) {
      const line = lineString([coords[index - 1], wp]);
      const snapped = nearestPointOnLine(line, point(longlat), { units: 'kilometers' });
      const l = snapped.properties.dist;
      if (nearestLength === null || l < nearestLength) {
        nearestLength = l;
      }
    }
  });
  // convert  to meters
  return nearestLength ? nearestLength * 1000 : null;
}
</script>

<template>
  <div class="mapbox-edition">
    <Transition name="fade">
      <v-card v-if="isReady && !tripUpStore.stopInEdition" class="mapbox-edition__card">
        <!-- Switch part -->
        <v-card-item class="mapbox-edition__card__switch">
          <v-btn-toggle
            v-model="modificationMode"
            class="mapbox-edition__toggle-selector"
            variant="outlined"
            color="success"
            rounded="lg"
            mandatory
            divided
          >
            <v-btn>
              <v-icon>fa:fas fa-map-marker-alt</v-icon>
              <span class="mapbox-edition__text-btn">{{ $t('modifyStops') }}</span>
            </v-btn>
            <v-btn>
              <font-awesome-icon icon="fa-solid fa-arrows-split-up-and-left" class="mr-1" />
              <span class="mapbox-edition__text-btn">{{ $t('modifyPath') }}</span>
            </v-btn>
          </v-btn-toggle>
        </v-card-item>

        <!-- Part modifyStop information  -->
        <Transition name="fadeEnterOnly">
          <div v-if="modificationMode === ModificationMode.EDIT_STOP" class="mapbox-edition__card__step">
            <div class="mapbox-edition__card__step-content">
              <img alt="step-stop" src="@/assets/img/stop-edition.svg?url" />
              <span class="mapbox-edition__card__step-text">
                {{ $t('stopEditionTip') }}
              </span>
            </div>

            <Btn type="secondary" :disabled="false" @click="displayModalReset = true">
              <font-awesome-icon icon="fa-rotate-right" />
              <span class="mapbox-edition__text-btn">{{ $t('reset') }}</span>
            </Btn>
          </div>
        </Transition>

        <!-- Part modifyShape information  -->
        <Transition name="fadeEnterOnly">
          <template v-if="modificationMode === ModificationMode.EDIT_SHAPE">
            <div class="mapbox-edition__card__step">
              <div class="mapbox-edition__card__step-content">
                <v-tooltip activator="parent" location="top">
                  <p class="mapbox-edition__tooltip">
                    <b>{{ $t('shapeEditionTip.boldPart1') }}</b>
                    {{ $t('shapeEditionTip.tip1') }}
                    <br />
                    <b>{{ $t('shapeEditionTip.boldPart2') }}</b>
                    {{ $t('shapeEditionTip.tip2') }}
                    <br />
                    {{ $t('shapeEditionTip.tip3') }}
                  </p>
                </v-tooltip>
                <!-- Image for steps 1/2/3 of shape edition -->
                <img
                  v-if="tripUpStore.currentStep === DrawStep.SELECT_PATH"
                  alt="step1"
                  src="@/assets/img/shape-edition-step1.svg?url"
                />
                <img
                  v-else-if="tripUpStore.currentStep === DrawStep.DRAW_SHAPE"
                  alt="step2"
                  src="@/assets/img/shape-edition-step2.svg?url"
                />
                <img
                  v-else-if="tripUpStore.currentStep === DrawStep.DRAW_SHAPE_DONE"
                  alt="step3"
                  src="@/assets/img/shape-edition-step3.svg?url"
                />

                <span class="mapbox-edition__card__step-text">
                  {{
                    $t(
                      tripUpStore.currentStep === DrawStep.SELECT_PATH
                        ? 'shapeEditionSteps.step1'
                        : tripUpStore.currentStep === DrawStep.DRAW_SHAPE
                          ? 'shapeEditionSteps.step2'
                          : 'shapeEditionSteps.step3',
                    )
                  }}
                </span>
                <v-icon icon="fa:fas fa-question-circle" class="mt-auto mb-auto mr-3" size="18" />
              </div>

              <Btn
                v-if="tripUpStore.currentStep === DrawStep.SELECT_PATH"
                type="secondary"
                @click="displayModalReset = true"
              >
                <font-awesome-icon icon="fa-rotate-right" />
                <span class="mapbox-edition__text-btn">{{ $t('reset') }}</span>
              </Btn>
              <template v-else>
                <Btn type="secondary" @click="resetNonsavedPart()">
                  <span>{{ $t('cancel') }}</span>
                </Btn>
                <Btn
                  class="ml-0"
                  type="primary"
                  :disabled="tripUpStore.currentStep !== DrawStep.DRAW_SHAPE_DONE"
                  @click="confirmDeviationPart()"
                >
                  <font-awesome-icon icon="fa-check" />
                  <span class="mapbox-edition__text-btn">{{ $t('confirm') }}</span>
                </Btn>
              </template>
            </div>
          </template>
        </Transition>
      </v-card>
    </Transition>
    <ModalReset
      v-if="displayModalReset"
      :body="$t(modificationMode === ModificationMode.EDIT_STOP ? 'resetStopsBody' : 'resetShapeBody')"
      :title="$t(modificationMode === ModificationMode.EDIT_STOP ? 'resetStops' : 'resetShape')"
      is-reset-case
      @close="displayModalReset = false"
      @submit="
        modificationMode === ModificationMode.EDIT_STOP ? resetStopsToGtfsState() : resetShapeToGtfsState()
      "
    />
  </div>
</template>

<style lang="scss">
.mapbox-edition {
  img {
    width: 32px;
    height: 32px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 4px;
  }

  &__toggle-selector {
    width: 100%;
    height: 36px !important;

    .v-btn:not(:last-child) {
      border-inline-end-color: $primary-light;
    }

    .v-btn {
      width: 50%;
      color: $text-dark-variant;
      cursor: pointer;
    }

    .v-btn--active {
      border-width: 1px;
      border-color: $primary-light;
    }
  }

  &__tooltip {
    font-size: 14px;
  }

  &__text-btn {
    @media (max-width: 1499px) {
      display: none;
    }
  }

  &__card {
    position: absolute;
    right: 48px;
    bottom: 24px;
    left: 48px;
    z-index: 10;
    display: flex;
    border-color: $light-border;
    border-radius: 12px;
    background-color: $transparent-canvas;
    box-shadow: 0 4px 24px 0 rgb(37 37 37 / 20%);
    backdrop-filter: blur(4px);

    &__switch {
      width: 100px;
      padding: 12px;
      border-right: 1px solid $light-border;

      @media (min-width: 1500px) {
        width: 200px;
      }

      .v-btn {
        height: 36px;

        @media (max-width: 1499px) {
          min-width: 32px;
        }
      }
    }

    &__step {
      display: flex;
      flex-grow: 2;
      gap: 12px;
      padding: 12px;
      color: $text-dark-variant;
      font-weight: 500;
      font-size: 12px;

      @media (max-width: 1499px) {
        svg {
          margin-right: 0 !important;
        }
      }

      &-content {
        display: flex;
        flex-grow: 2;
        gap: 12px;
        justify-content: space-between;
        border-radius: 6px;
        background-color: $background-variant;
      }

      &-text {
        @include multi-line-ellipsis($font-size: 14px, $line-height: 1, $lines-to-show: 2);

        flex-grow: 2;
        margin-top: auto;
        margin-bottom: auto;
        font-weight: 600;
      }
    }

    button {
      font-size: 14px;
    }
  }
}
</style>

<i18n locale="fr">
{
  "modifyPath": "Tracé",
  "modifyStops": "Arrêts",
  "shapeEditionSteps": {
    "step1": "Sélectionner une portion à modifier",
    "step2": "Définir un tracé alternatif",
    "step3": "Ajuster ou valider le tracé alternatif",
  },
  "stopEditionTip": "Annuler des arrêts via le clic droit.",
  "shapeEditionTip": {
    "boldPart1": "Sélection de portion : ",
    "tip1":"cliquer sur un ou deux points du tracé pour en sélectionner une partie.",
    "boldPart2": "Tracé alternatif : ",
    "tip2": "ensuite, cliquer à différents endroits de la carte pour définir des coordonnées alternatives.",
    "tip3": "Si besoin, supprimer les via le clic droit ou déplacer les en les faisant glisser.",
  },
  "resetStops": "Réinitialiser les arrêts ?",
  "resetStopsBody": "Toutes les modifications opérées sur les arrêts seront perdues.",
  "resetShape": "Réinitialiser le tracé ?",
  "resetShapeBody": "Toutes les modifications opérées sur le tracé seront perdues.",
}
</i18n>

<i18n locale="en">
{
  "shapeEdition": "Shape edition",
  "modifyPath": "Path",
  "modifyStops": "Stops",
  "shapeEditionSteps": {
    "step1": "Select a portion to modify",
    "step2": "Define an alternative shape",
    "step3": "Adjust or validate the alternative shape",
  },
  "stopEditionTip": "Cancel a stop with right click.",
  "shapeEditionTip": {
    "boldPart1": "Portion selection: ",
    "tip1":"click on one or two points of the shape to select a portion of it.",
    "boldPart2": "Alternative shape: ",
    "tip2": "then, click on several places of the map to define alternative coordinates.",
    "tip3": "If needed, delete them with right click or displace them by dragging.",
  },
  "resetStops": "Reset stops ?",
  "resetStopsBody": "All stops modifications will be lost.",
  "resetShape": "Reset shape ?",
  "resetShapeBody": "All shape modifications will be lost.",
}
</i18n>
