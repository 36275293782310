<script setup lang="ts">
import { computed, type PropType } from 'vue';
import { useStore } from 'vuex';
import { timestampFormatHHMM, timestampFormatHHMMSS } from '@/libs/helpers/dates';
import type { Group } from '@/@types/group';

import { type EventFeedItem } from './EventFeed.vue';
import { FeedEventType, formatDelay } from './EventFeedShared.js';

const props = defineProps({
  isLastOfFeed: {
    default: false,
    type: Boolean,
  },

  isFirstOfFeed: {
    default: false,
    type: Boolean,
  },

  row: {
    required: true,
    type: Object as PropType<EventFeedItem>,
  },

  selectedDate: {
    required: true,
    type: Date,
  },
});

const store = useStore();

const emit = defineEmits(['clickOnRow']);

const group = computed<Group>(() => {
  return store.getters.group;
});

const delayClass = computed<String>(() => {
  return store.getters['devices/getDelayState'](props.row.delay);
});

const eventIcon = computed<String>(() => {
  switch (props.row.type) {
    case FeedEventType.ARRIVAL:
      return 'fa-bus';
    case FeedEventType.DEPARTURE:
      return 'fa-bus';
    case FeedEventType.INBOX:
      return 'fa-envelope';
    case FeedEventType.MESSAGE_SENT:
      return 'fa-paper-plane';
    default:
      return 'fa-bus';
  }
});

function formatHHMM(ts: number): String {
  return timestampFormatHHMM(ts, { refDate: props.selectedDate, tz: group.value.tz });
}

function formatHHMMSS(ts: number): String {
  return timestampFormatHHMMSS(ts, { refDate: props.selectedDate, tz: group.value.tz });
}

function onRowClick() {
  // Action on row click only if event is arrival or departure
  if (props.row.type === FeedEventType.ARRIVAL || props.row.type === FeedEventType.DEPARTURE) {
    emit('clickOnRow', props.row);
  }
}
</script>

<template>
  <tr
    class="event-feed-row"
    :class="{ 'first-line': isFirstOfFeed, 'last-line': isLastOfFeed }"
    @click="onRowClick"
  >
    <!-- Time -->
    <td class="table__cell time-cell">
      <div v-if="row.time && !row.isTheoreticalOnly" class="event-feed-row__cell-content">
        {{ formatHHMMSS(row.time) }}
      </div>
    </td>

    <!-- Event -->
    <td class="table__cell event-feed-row__body-cell">
      <!-- Icon -->
      <div class="icon">
        <div class="event-feed-row__icon">
          <font-awesome-icon :icon="eventIcon" />
        </div>
      </div>
      <div v-if="row.type === FeedEventType.ARRIVAL" class="event-feed-row__cell-content">
        {{ $t('eventFeed.arrivalAt') }}
        <span class="bold">{{ row.stopName }}</span>
      </div>
      <div v-else-if="row.type === FeedEventType.DEPARTURE" class="event-feed-row__cell-content">
        {{ $t('eventFeed.departureFrom') }}
        <span class="bold">{{ row.stopName }}</span>
      </div>
      <div v-else-if="row.type === FeedEventType.INBOX" class="event-feed-row__cell-content">
        {{ $t('eventFeed.inbox') }}
        <span class="bold">{{ row.content }}</span>
      </div>
      <div v-else-if="row.type === FeedEventType.MESSAGE_SENT" class="event-feed-row__cell-content">
        {{ $t('eventFeed.messageSent') }}
        <span class="bold">{{ row.content }}</span>
      </div>
    </td>

    <!-- Theoretical time -->
    <td class="table__cell">
      <div v-if="row.type === FeedEventType.ARRIVAL" class="event-feed-row__cell-content">
        <div class="event-feed-row__time" :class="{ 'event-feed-row__canceled': row.hasBeenCanceled }">
          {{ formatHHMM(row.theoreticalArrivalTime) }}
        </div>
        <div v-if="row.modifiedTimeOffset" class="event-feed-row--tooLate">
          {{ formatDelay(row.modifiedTimeOffset) }}
        </div>
      </div>
      <div v-else-if="row.type === FeedEventType.DEPARTURE" class="event-feed-row__cell-content">
        <div class="event-feed-row__time" :class="{ 'event-feed-row__canceled': row.hasBeenCanceled }">
          {{ formatHHMM(row.theoreticalDepartureTime) }}
        </div>
        <div v-if="row.modifiedTimeOffset" class="event-feed-row--tooLate">
          {{ formatDelay(row.modifiedTimeOffset) }}
        </div>
      </div>
    </td>

    <!-- Delay -->
    <td class="table__cell">
      <span class="event-feed-row__cell-content" :class="'event-feed-row--' + delayClass">
        {{ formatDelay(row.delay) }}
      </span>
    </td>
  </tr>
</template>

<style lang="scss" scoped>
.event-feed-row {
  @keyframes active-animation {
    from {
      background-color: $border;
    }

    to {
      background-color: transparent;
    }
  }

  position: relative;

  &.active {
    animation-name: active-animation;
    animation-duration: 1s;
    animation-timing-function: linear;
  }

  &:hover {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $background;
    cursor: pointer;
  }

  &::after {
    content: ' ';
    position: absolute;
    left: 97px;
    display: block;
    height: 100%;
    border-left: 1px solid $border-variant;
  }

  &.first-line {
    &::after {
      top: 50%;
      height: 50%;
    }
  }

  &.last-line {
    &::after {
      top: 0;
      height: 50%;
    }
  }

  &__icon {
    position: absolute;
    z-index: $feed-icon;
    display: inline-block;
    width: 29px;
    padding: 3px;
    border: 1px solid $text-neutral;
    border-radius: 50px;
    background-color: $canvas;
    color: $text-neutral;
    text-align: center;
  }

  &__body-cell {
    display: flex;
  }

  &__canceled {
    text-decoration: line-through;
  }

  &__cell-content {
    display: flex;
    gap: 5px;
    align-items: center;
    padding-top: 3px;
  }

  &__time {
    width: 40px;
  }

  &--onTime {
    color: $primary-light;
  }

  &--tooEarly {
    color: $danger;
  }

  &--tooLate {
    color: $warn;
  }

  .time-cell {
    width: 75px;
  }

  .bold {
    font-weight: $font-weight-semi-bold;
  }

  .icon {
    position: relative;
    width: 20px;
    margin-right: 20px;
  }

  .table__cell {
    border-top: none;
  }
}
</style>
