<script setup lang="ts">
import { onUnmounted } from 'vue';

import type { Urgency } from '@/@types/api/urgency';
import Modal from '@/components/layout/Modal.vue';
import MapboxMap from '@/components/map/MapboxMap.vue';
import MapboxUrgencyLayer from '@/components/map/MapboxUrgencyLayer.vue';
import Btn from '@/components/ui/Btn.vue';
import Pagination from '@/components/ui/Pagination.vue';

import { useCurrentUrgencies, type FormattedUrgency } from './current-urgencies';

const props = defineProps<{
  urgencies: Urgency[];
}>();

const {
  activeUrgency,
  activeUrgencyIndex,
  formattedUrgency,
  map,
  mapCenter,
  mapLoaded,
  positionSource,
  onMapLoad,
} = useCurrentUrgencies(() => props.urgencies);

const emit = defineEmits(['handleAlerts', 'close']);

onUnmounted(() => emit('close'));
</script>

<template>
  <Modal
    v-if="formattedUrgency"
    modal-class="modal-urgency"
    :width="800"
    :hide-footer="true"
    :has-close-btn="false"
    :forbid-closing="true"
    :persistent="true"
  >
    <template #title>
      <div class="modal-urgency__header">
        <div class="modal-urgency__title">{{ $tc('urgencyAlerts', { count: urgencies.length }) }}</div>
        <div class="modal-urgency__nav">
          <Pagination
            :items="urgencies"
            :short-format="true"
            :initial-page="activeUrgencyIndex + 1"
            :page-size="1"
            @changePage="
              page => {
                activeUrgencyIndex = page.start;
              }
            "
          />
        </div>
      </div>
    </template>

    <template #body>
      <div class="modal-urgency__body">
        <div class="modal-urgency__infos">
          <div v-for="key in Object.keys(formattedUrgency)" :key="key" class="modal-urgency__info">
            <span class="modal-urgency__label">{{ $t(`label.${key}`) }}</span>
            <span class="modal-urgency__data">{{ formattedUrgency[key as keyof FormattedUrgency] }}</span>
          </div>
        </div>
        <div class="modal-urgency__map">
          <MapboxMap
            :gtfs-id="activeUrgency?.trip?.gtfs_id ?? ''"
            :stops="[]"
            :trips="[]"
            :center="mapCenter"
            @load="onMapLoad"
          >
            <MapboxUrgencyLayer
              v-if="mapLoaded && positionSource"
              :map="map"
              :position-source="positionSource"
            />
          </MapboxMap>
        </div>
      </div>
      <div class="modal-urgency__cta">
        <Btn type="secondary" @click="emit('handleAlerts')">
          <span>{{ $t('handleAlerts') }}</span>
          <font-awesome-icon icon="fa-external-link" />
        </Btn>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss">
.modal-urgency {
  .v-overlay__content {
    padding: 0;
    background-color: $white;
  }

  .modal__header {
    justify-content: space-between;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    background-color: $text-dark;
  }

  .modal__title {
    color: $white;
    font-size: 20px;
    text-transform: uppercase;
  }

  .modal__body {
    overflow-y: auto;
  }

  &__body {
    display: flex;
    height: 260px;
    border-bottom: 1px solid $background-variant;
  }

  &__header {
    position: relative;
  }

  &__nav {
    position: absolute;
    top: 5px;
    right: 0;

    .pagination-component {
      margin: 0;
      font-size: 12px;

      .v-icon {
        font-size: 12px;
      }
    }
  }

  &__infos {
    width: 50%;
    padding: 30px;
    font-size: 12px;
  }

  &__info {
    margin-bottom: 7px;
  }

  &__label {
    margin-right: 10px;
    font-weight: 600;
  }

  &__data {
    font-weight: 500;
  }

  &__map {
    position: relative;
    width: 50%;
  }

  &__cta {
    padding: 25px 0 15px;

    .ui-btn {
      display: block;
      width: 300px;
      margin: auto;
      border-color: $text-neutral;
      background-color: $background;
      font-weight: 500;
      line-height: 1;
    }
  }
}
</style>

<i18n locale="fr">
{
  "urgencyAlerts": "{count} alerte urgente | {count} alertes urgentes",
  "handleAlerts": "Traiter les alertes",
  "label": {
    "driver": "Conducteur :",
    "vehicle": "Véhicule :",
    "device": "Appareil :",
    "trip": "Course :",
    "datetime": "Date d'envoi :",
    "position": "Position :",
    "address": "Dernière adresse"
  }
}
</i18n>

<i18n locale="en">
{
  "urgencyAlerts": "Urgency alerts",
  "handleAlerts": "Handle alerts",
  "label": {
    "driver": "Driver :",
    "vehicle": "Vehicle :",
    "device": "Device :",
    "trip": "Trip :",
    "datetime": "Sent on :",
    "position": "Position :",
    "address": "Last address"
  }
}
</i18n>
