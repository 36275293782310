<template>
  <div
    class="trip-info-item"
    :class="[TripDetailParams.TRIP_TEAM, TripDetailParams.DEVICE_TEAM].includes(infoType) ? 'item-team' : ''"
  >
    <div v-if="info === null || info === undefined">-</div>
    <Status
      v-else-if="infoType === TripDetailParams.TRIP_STATUS"
      :status="info.status"
      :problems="info.problems"
    />
    <RouteBadge
      v-else-if="infoType === TripDetailParams.ROUTE"
      :route="info"
      :value="info.route_short_name"
      :is-experimental="info.isDeactivated"
    />
    <Progression
      v-else-if="[TripDetailParams.PROGRESSION, TripDetailParams.SERVICE].includes(infoType)"
      :value="info.value"
      :service="info.service"
    />
    <Date v-else-if="infoType === TripDetailParams.DATE" :date="info.date" :options="info.options" />
    <Gtfs v-else-if="infoType === TripDetailParams.TRANSPORT_PLAN" :gtfs="info" />
    <Team
      v-else-if="[TripDetailParams.TRIP_TEAM, TripDetailParams.DEVICE_TEAM].includes(infoType)"
      :color="info.color"
      :team-id="info.teamId"
      archived
    />
    <template v-else-if="infoType === TripDetailParams.DEVICE">
      <div v-if="Array.isArray(info.device) && info.device.length > 1">
        <Device
          v-for="(device, index) in info.device"
          :key="index"
          :date="info.date"
          :devices="[device]"
          :group-id="info.groupId"
        />
      </div>
      <Device
        v-else
        :date="info.date"
        :devices="Array.isArray(info.device) ? info.device : [info.device]"
        :group-id="info.groupId"
      />
    </template>
    <Delay v-else-if="infoType === TripDetailParams.DELAY" :delay="info" />
    <router-link
      v-else-if="[TripDetailParams.FIRST_STOP, TripDetailParams.LAST_STOP].includes(infoType)"
      :to="{
        query: info.query,
        name: GroupRoute.STOP_DETAILLED,
        params: { groupId: info.groupId, stopId: info.stopId },
      }"
    >
      {{ info.name }}
    </router-link>
    <DropList
      v-else-if="[TripDetailParams.DRIVERS, TripDetailParams.VEHICLES].includes(infoType)"
      :values="info"
    />
    <TripUpdates
      v-else-if="infoType === TripDetailParams.TRIP_UPDATES"
      :updates="info"
      :trip-id="tripId"
      @redirectToModificationPage="$emit('redirectToModificationPage')"
    />
    <div v-else-if="infoType === TripDetailParams.DEVICE_STATUS">
      {{ info ? $t(`deviceStatus.online`) : $t(`deviceStatus.offline`) }}
    </div>
    <StopInfo
      v-else-if="infoType === TripDetailParams.STOP_INFO"
      :stop-info="info"
      :trip-id="tripId"
      @showModal="$emit('showModal', ModalType.STOP_INFO)"
    />
    <div v-else :title="info">{{ info }}</div>
  </div>
</template>

<script>
import Progression from '@/components/Table/cells/CellProgress.vue';
import Date from '@/components/Table/cells/DateCell.vue';
import RouteBadge from '@/components/common/RouteBadge.vue';
import Team from '@/components/ui/SingleTeamSelector.vue';
import { GroupRoute } from '@/libs/routing';
import Delay from '@/pages/TripsListPage/cells/CellDelay.vue';
import Device from '@/pages/TripsListPage/cells/CellDevice.vue';
import Gtfs from '@/pages/TripsListPage/cells/CellGtfs.vue';
import Status from '@/pages/TripsListPage/cells/CellTripStatus.vue';
import StopInfo from '@/pages/TripsListPage/cells/CellStopInfo.vue';
import TripUpdates from '@/pages/TripsListPage/cells/CellTripModification.vue';
import { ModalType } from '@/store/trips';

import { TripDetailParams } from './TripInfoShared.js';
import DropList from '@/components/common/DropList.vue';

export default {
  name: 'TripInfoItem',

  components: {
    Date,
    Delay,
    Device,
    Gtfs,
    Progression,
    RouteBadge,
    Status,
    StopInfo,
    Team,
    DropList,
    TripUpdates,
  },

  props: {
    info: {
      type: [String, Array, Object, Number, Boolean],
      default: null,
    },

    /** @type {TripDetailParams} */
    infoType: {
      type: String,
      required: true,
    },
    tripId: {
      type: String,
      required: true,
    },
  },

  emits: ['redirectToModificationPage', 'showModal'],

  data: () => ({
    GroupRoute,
    ModalType,
    TripDetailParams,
  }),
};
</script>

<style lang="scss">
.trip-info-item {
  display: inline-block;
  min-width: 250px;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 20px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  .droplist {
    &__line {
      min-width: 160px;
      margin: unset;
    }

    &__first-item {
      max-width: 150px;
      margin: unset;
    }
  }

  div:not(.team-selector) {
    overflow: hidden;
  }

  .team-selector .team-badge {
    display: initial;
  }
}
</style>

<i18n locale="fr">
{
  "deviceStatus": {
    "offline": "Hors ligne",
    "online": "En ligne",
  }
}
</i18n>

<i18n locale="en">
{
  "deviceStatus": {
    "offline": "Offline",
    "online": "Online",
  }
}
</i18n>
